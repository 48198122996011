body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* letter-spacing: 0.3em; */
}

.sotcox-main-menu.no-bullet ul li a:before {
  display: none;
}

/* 
.sotcox-menu-wrapper .sotcox-main-menu ul li.vertical-divider{position: relative;}
.sotcox-menu-wrapper .sotcox-main-menu ul li.vertical-divider:after {
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #ddd;
} */

.form-control.main {
  background: #fff;
  padding: 15px 20px;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
  font-size: 14px;
}

.form-control.main:focus {
  border: 1px solid #2e7eed;
}

.form-control::-webkit-input-placeholder {
  color: #808080;
  font-size: 14px;
}

.navbar-custom {
  /* background-color: #ededed; */
}

.navbar-custom.navbar-light .navbar-nav .nav-link.active:not(.dropdown-toggle),
.navbar-light .navbar-nav .show > .nav-link:not(.dropdown-toggle) {
  color: #505050;
  /* text-decoration: underline; */
  background-color: #fff;
}

.navbar-custom .nav-item .btn-outline-sm {
  /* margin-top: 0.25rem; */
  /* margin-bottom: 1.375rem; */
  margin-left: 0.5rem;
  border: 0.125rem solid #212121;
  color: #212121;
}

.navbar-custom .nav-item .btn-outline-sm:hover {
  background-color: #fff;
  color: #174fa2;
}

/* @media (min-width: 992px) { */

.navbar-custom.top-nav-collapse {
  /* padding: 0.5rem 1.5rem 0.5rem 2rem; */
  background-color: #ededed;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
}

.navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
.navbar-custom.top-nav-collapse .nav-item .nav-link.active {
  color: #fff;
}

.navbar-custom.top-nav-collapse .dropdown-menu {
  border-top: 0.25rem solid rgba(0, 0, 0, 0);
  box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.02);
}

/* .navbar {
  border-bottom: 8px solid #f3f3f3;
} */

.navbar.navbar-inverse {
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 4px solid #5dbbe8;
}

.navbar .navbar-nav .nav-link:not(.dropdown-toggle) {
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
}

.navbar .navbar-nav .side-icons .nav-link:not(.dropdown-toggle) {
  font-size: 16px;
}

.navbar-inverse .navbar-nav .nav-drop-down a.nav-link {
  color: #fff;
}

.navbar-inverse .navbar-nav .nav-link:not(.dropdown-toggle) {
  color: #fff;
  background: transparent;
  border: none;
}

.navbar-inverse .navbar-nav .nav-link:not(.dropdown-toggle):hover {
  color: #212121;
  text-decoration: underline;
  background: #ffffff;
}

.btn-primary {
  color: #fff !important;
  background-color: #438eff !important;
  border-color: #438eff !important;
}

.btn-primary:hover {
  color: #ffffff !important;
  background-color: #3979d9 !important;
  border-color: #3672cc !important;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 159, 255, 0.5) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #438eff !important;
  border-color: #438eff !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #3672cc !important;
  border-color: #326bbf !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 159, 255, 0.5) !important;
}

.ant-dropdown-button.fix-btn .ant-btn-icon-only:before {
  content: "";
  background-color: #3672cc !important;
}

/* 
.btn-outline-primary {
  color: #7cc;
  background-color: transparent;
  background-image: none;
  border-color: #7cc
}

.btn-outline-primary:hover {
  color: #222;
  background-color: #8ad3d3;
  border-color: #7cc
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem rgba(119, 204, 204, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #7cc;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #8ad3d3;
  border-color: #7cc
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(119, 204, 204, 0.5)
} */

/* 
.nav-drop-down .dropdown-menu {
  min-width: 250px;
} */

.left {
  overflow: hidden;
}

.left img {
  margin-left: -40px;
}

@media (max-width: 768px) {
  .left img {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.right {
  overflow: hidden;
}

.right img {
  margin-left: 40px;
}

@media (max-width: 768px) {
  .right img {
    margin-left: 0;
  }
}

.hide-overflow,
.service {
  overflow: hidden;
}

.nav-up {
  top: -70px;
}

/* .btn {
  text-transform: uppercase;
} */

.btn-download {
  padding: 20px 35px;
  font-size: 14px;
  background: #fff;
  color: #2e7eed;
}

.btn-download span {
  margin-left: 5px;
  font-size: 20px;
}

.btn-main {
  padding: 25px 45px;
  border-radius: 3px;
  background: #2e7eed;
  color: #fff;
  outline: none;
}

.btn-main:hover {
  color: #fff;
}

.btn-main:focus {
  color: #fff;
  box-shadow: none;
}

.btn-main-inverted {
  padding: 10px 45px;
  border-radius: 8px;
  background: #ffffff;
  color: #2e7eed;
  outline: none;
  border: 1px solid #fff;
}

.btn-main-inverted:hover {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
}

.btn-main-inverted:focus {
  color: #fff;
  box-shadow: none;
}

.btn-main-md {
  padding: 10px 38px;
  border-radius: 3px;
  background: #2e7eed;
  color: #fff;
  outline: none;
}

.btn-main-md:hover {
  color: #fff;
}

.btn-main-md:focus {
  color: #fff;
  box-shadow: none;
}

.btn-main-sm {
  padding: 15px 35px;
  border-radius: 3px;
  background: #2e7eed;
  color: #fff;
  outline: none;
  font-size: 14px;
}

.btn-main-sm:hover {
  color: #fff;
}

.btn-main-sm:focus {
  color: #fff;
  box-shadow: none;
}

.btn-white {
  background: white;
  color: #2e7eed;
}

.btn-rounded-icon {
  border-radius: 100px;
  color: #fff;
  border: 1px solid #fff;
  padding: 13px 50px;
}

.main-nav {
  background: #fff;
  z-index: 1;
}

.main-nav .navbar-brand {
  padding: 0;
}

.main-nav .navbar-nav .nav-item {
  position: relative;
  font-family: "Arimo", sans-serif;
}

.main-nav .navbar-nav .nav-item .nav-link {
  position: relative;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 45px;
}

.main-nav .navbar-nav .nav-item.active .nav-link {
  color: #2e7eed;
}

.main-nav .navbar-nav .nav-item.active .nav-link:before {
  content: "";
  background: #2e7eed;
  width: 60%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 20%;
}

@media (max-width: 992px) {
  .main-nav .navbar-nav .nav-item.active .nav-link:before {
    display: none;
  }
}

.main-nav .dropdown-slide {
  position: static;
}

.main-nav .dropdown-slide .open > a,
.main-nav .dropdown-slide .open > a:focus,
.main-nav .dropdown-slide .open > a:hover {
  background: transparent;
}

.main-nav .dropdown-slide.full-width .dropdown-menu {
  left: 0 !important;
  right: 0 !important;
}

.main-nav .dropdown-slide:hover .dropdown-menu {
  display: none;
  opacity: 1;
  display: block;
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  opacity: 1;
  visibility: visible;
  color: #777;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

@media (max-width: 992px) {
  .main-nav .dropdown-slide:hover .dropdown-menu {
    -webkit-transform: none;
    transform: none;
    left: auto;
    position: relative;
    text-align: center;
  }
}

.main-nav .dropdown-slide .dropdown-menu {
  margin-top: 0;
  border-radius: 0;
  opacity: 1;
  visibility: visible;
  position: absolute;
  padding: 5px 15px;
  border: 1px solid #ebebeb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: 0.3s all;
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  transition: visibility 0.2s, opacity 0.2s,
    -webkit-transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  transition: visibility 0.2s, opacity 0.2s,
    transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  transition: visibility 0.2s, opacity 0.2s,
    transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99),
    -webkit-transform 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
}

.main-nav .dropdown-slide .dropdown-menu .dropdown-item {
  font-size: 13px;
  padding: 4px 10px;
  transition: 0.3s ease;
}

.main-nav .dropdown-slide .dropdown-menu .dropdown-item:hover {
  -webkit-transform: translate3d(5px, 0, 0);
  transform: translate3d(5px, 0, 0);
  background: #fff;
  color: #2e7eed;
}

@media (max-width: 480px) {
  .main-nav .dropdown-slide .dropdown-menu {
    -webkit-transform: none;
    transform: none;
  }
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: none;
}

.footer-logo {
  width: 50%;
}

.footer-main {
  padding: 92px 0;
  background: #1a1b1f;
}

@media (max-width: 768px) {
  .footer-main {
    padding: 50px 0;
  }
}

.footer-main .block img {
  margin-bottom: 20px;
}

.footer-main .block img #small-Apps {
  fill: red;
}

.footer-main .block ul.social-icon li a {
  text-decoration: none;
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background: #3f3f43;
  color: #fff;
  text-align: center;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .footer-main .block {
    margin-bottom: 40px;
  }
}

.footer-main .block-2 h6 {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 25px;
}

.footer-main .block-2 ul {
  padding: 0;
}

.footer-main .block-2 ul li {
  margin-bottom: 10px;
  list-style: none;
}

.footer-main .block-2 ul li a {
  font-size: 14px;
  color: #6f6f71;
  transition: 0.2s ease;
}

.footer-main .block-2 ul li a:hover {
  color: #fff;
}

.footer-classic {
  background: #fafafa;
  text-align: center;
  padding: 110px 0;
}

.footer-classic ul.social-icons {
  margin-bottom: 30px;
}

@media (max-width: 480px) {
  .footer-classic ul.social-icons li {
    margin-bottom: 10px;
  }
}

.footer-classic ul.social-icons li a {
  padding: 0 20px;
  display: block;
}

.footer-classic ul.social-icons li a i {
  font-size: 25px;
  color: #000;
}

.footer-classic ul.footer-links li a {
  padding: 0 10px;
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
}

.call-to-action-app {
  text-align: center;
}

.call-to-action-app h2,
.call-to-action-app p,
.call-to-action-app a {
  color: #fff !important;
}

.call-to-action-app p {
  margin-bottom: 60px;
}

.call-to-action-app ul li {
  margin-left: 15px;
}

@media (max-width: 480px) {
  .call-to-action-app ul li {
    margin-left: 0;
    margin-bottom: 10px;
  }
}

.call-to-action-app ul li:first-child {
  margin-left: 0;
}

.call-to-action-app ul li a i {
  font-size: 20px;
  margin-right: 5px;
}

.cta-hire {
  background: #fafafa;
}

.cta-hire p {
  width: 65%;
  margin: 0 auto;
}

.cta-hire h2,
.cta-hire p {
  margin-bottom: 20px;
}

.cta-community {
  margin: 50px 0;
  padding: 40px 100px;
  display: flex;
}

@media (max-width: 768px) {
  .cta-community {
    flex-wrap: wrap;
    text-align: center;
  }
}

@media (max-width: 400px) {
  .cta-community {
    padding: 20px;
  }
}

.cta-community .content,
.cta-community .action-button {
  justify-content: center;
}

.cta-community .action-button {
  align-self: center;
}

@media (max-width: 768px) {
  .cta-community .action-button {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}

.jd-modal .modal-content {
  padding: 25px;
  text-align: left;
  background: #fafafa;
}

.jd-modal .modal-content .modal-header .modal-title {
  color: #000;
}

.jd-modal .modal-content .modal-body .block-2 {
  display: flex;
  margin-bottom: 70px;
}

.jd-modal .modal-content .modal-body .block-2 .title {
  width: 30%;
}

.jd-modal .modal-content .modal-body .block-2 .title p {
  color: #000;
}

.jd-modal .modal-content .modal-body .block-2 .details {
  width: 70%;
}

.jd-modal .modal-content .modal-body .block-2 .details ul {
  padding-left: 0;
  margin: 0;
}

.jd-modal .modal-content .modal-body .block-2 .details ul li {
  list-style: none;
  margin-bottom: 5px;
}

.jd-modal .modal-content .modal-body .block-2 .details ul li span {
  padding-right: 5px;
  color: #000;
}

.jd-modal .modal-content .modal-body .form-title {
  margin-bottom: 30px;
}

.banner {
  padding: 180px 0 100px;
}

.banner .block h1 {
  margin-bottom: 13px;
}

.banner .block p {
  font-size: 20px;
  margin-bottom: 30px;
}

.banner .block .video {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .banner .block .video {
    width: 100%;
  }
}

.banner .block ul.clients-logo {
  margin-top: 30px;
}

.banner .block ul.clients-logo li {
  margin-left: 30px;
}

@media (max-width: 480px) {
  .banner .block ul.clients-logo li {
    margin-bottom: 20px;
  }
}

.banner .block ul.clients-logo li:first-child {
  margin-left: 0;
}

.slider {
  padding: 180px 0 300px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.slider .block {
  position: relative;
}

.slider .block h1,
.slider .block h3 {
  color: #fff;
}

.slider .block .download {
  margin-top: 20px;
}

.slider .block .image-content {
  text-align: center;
}

.slider .block .image-content img {
  margin-top: 100px;
  margin-bottom: -200px;
}

.slider:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 290px solid #fff;
  border-left: 2000px solid transparent;
  width: 0;
}

.services .service-block {
  background: #fff;
  padding: 30px 40px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.services .service-block:last-child {
  margin-bottom: 0;
}

@media (max-width: 480px) {
  .services .service-block:last-child {
    margin-bottom: 30px;
  }
}

.services .service-block h3 {
  line-height: 30px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
}

.services .service-block i {
  font-size: 30px;
  color: #2e7eed;
  margin-bottom: 15px;
  display: inline-block;
}

.services .service-block p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
}

.services .app-preview {
  display: flex;
  justify-content: center !important;
}

.services .app-preview img {
  height: 500px;
  width: auto;
}

@media (max-width: 992px) {
  .services .col-lg-4.m-auto {
    display: none;
  }
}

@media (max-width: 768px) {
  .service .service-thumb {
    width: 80%;
    margin: 0 auto;
  }
}

.service .service-box {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .service .service-box {
    width: 80%;
    margin: 0 auto;
  }
}

.service .service-box .service-item {
  text-align: center;
  padding: 10px;
  margin: 20px 0;
}

.service .service-box .service-item i {
  font-size: 20px;
  color: #2e7eed;
  display: inline-block;
  margin-bottom: 10px;
}

.service .service-box .service-item p {
  font-size: 14px;
}

.feature .feature-content h2,
.feature .feature-content p {
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .feature .feature-content h2,
  .feature .feature-content p {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .feature .testimonial {
    text-align: center;
  }
}

.feature .testimonial p {
  font-family: "Lora", serif;
  margin-bottom: 10px;
  font-style: italic;
  color: #242424;
}

.feature .testimonial ul.meta li {
  font-size: 12px;
  margin-right: 10px;
}

.feature .testimonial ul.meta li img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

@media (max-width: 480px) {
  .app-features .app-feature {
    margin-bottom: 30px;
  }
}

.app-features .app-explore {
  display: flex;
  justify-content: center !important;
  margin-bottom: 40px;
}

.banner-full .image {
  display: flex;
  justify-content: center;
}

.banner-full .image img {
  height: 625px;
}

@media (max-width: 768px) {
  .banner-full .image {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .banner-full .block {
    text-align: center;
  }
}

.banner-full .block .logo {
  margin-bottom: 40px;
}

.banner-full .block h1 {
  margin-bottom: 40px;
}

.banner-full .block p {
  font-size: 20px;
  margin-bottom: 50px;
}

.banner-full .block .app {
  margin-bottom: 20px;
}

.video-promo {
  padding: 150px 0;
}

.video-promo .content-block {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.video-promo .content-block h2 {
  font-size: 30px;
  color: #fff;
}

.video-promo .content-block p {
  margin-bottom: 30px;
}

.video-promo .content-block a i.video {
  height: 125px;
  width: 125px;
  background: #2e7eed;
  display: inline-block;
  font-size: 40px;
  color: #fff;
  text-align: center;
  line-height: 125px;
  border-radius: 100%;
}

.video-promo .content-block a:focus {
  outline: 0;
}

.testimonial .testimonial-slider .item {
  padding-bottom: 10px;
}

.testimonial .testimonial-slider .item .block {
  padding: 40px;
  text-align: center;
  margin: 10px;
  border-radius: 5px;
}

.testimonial .testimonial-slider .item .block .image {
  margin-top: 30px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .testimonial .testimonial-slider .item .block .image {
    flex-grow: 1;
  }
}

.testimonial .testimonial-slider .item .block .image img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.testimonial .testimonial-slider .item .block p {
  font-family: "Lora", serif;
  font-style: italic;
  color: #888888;
}

.testimonial .testimonial-slider .item .block cite {
  font-style: normal;
  font-size: 14px;
  color: #161616;
}

.testimonial .testimonial-slider .owl-dots .owl-dot:hover span {
  background: #2e7eed;
}

.testimonial .testimonial-slider .owl-dots .owl-dot.active span {
  background: #2e7eed;
}

/* homepage 3 */
.gradient-banner {
  height: auto;
  padding: 0;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.gradient-banner.rounded {
  height: auto;
}

.gradient-banner.rounded:before {
  border-radius: 50%;
}

.pull-top {
  margin-top: -100px;
}

.shapes-container {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.shapes-container .shape {
  position: absolute;
}

.shapes-container .shape::before {
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  position: absolute;
  border-radius: 50px;
}

.shapes-container .shape:nth-child(1) {
  top: 2%;
  left: 11%;
  width: 400px;
  height: 70px;
}

.shapes-container .shape:nth-child(2) {
  top: 14%;
  left: 18%;
  width: 200px;
  height: 15px;
}

.shapes-container .shape:nth-child(3) {
  top: 80%;
  left: 4%;
  width: 300px;
  height: 60px;
}

.shapes-container .shape:nth-child(4) {
  top: 85%;
  left: 15%;
  width: 100px;
  height: 10px;
}

.shapes-container .shape:nth-child(5) {
  top: 5%;
  left: 50%;
  width: 300px;
  height: 25px;
}

.shapes-container .shape:nth-child(6) {
  top: 4%;
  left: 52%;
  width: 200px;
  height: 5px;
}

.shapes-container .shape:nth-child(7) {
  top: 80%;
  left: 70%;
  width: 200px;
  height: 5px;
}

.shapes-container .shape:nth-child(8) {
  top: 55%;
  left: 95%;
  width: 200px;
  height: 5px;
}

.shapes-container .shape:nth-child(9) {
  top: 50%;
  left: 90%;
  width: 300px;
  height: 50px;
}

.shapes-container .shape:nth-child(10) {
  top: 30%;
  left: 60%;
  width: 500px;
  height: 55px;
}

.shapes-container .shape:nth-child(11) {
  top: 60%;
  left: 60%;
  width: 200px;
  height: 5px;
}

.shapes-container .shape:nth-child(12) {
  top: 35%;
  left: 75%;
  width: 200px;
  height: 5px;
}

.shapes-container .shape:nth-child(13) {
  top: 90%;
  left: 40%;
  width: 300px;
  height: 45px;
}

.shapes-container .shape:nth-child(14) {
  top: 54%;
  left: 75%;
  width: 200px;
  height: 5px;
}

.shapes-container .shape:nth-child(15) {
  top: 50%;
  left: 90%;
  width: 200px;
  height: 5px;
}

.shapes-container .shape:nth-child(16) {
  top: 50%;
  left: 81%;
  width: 100px;
  height: 5px;
}

.founder {
  margin-bottom: 30px;
}

.founder img {
  border-radius: 5px;
  margin-bottom: 25px;
}

.founder h2 {
  font-size: 30px;
  line-height: 30px;
}

.founder cite {
  font-size: 14px;
  font-style: normal;
}

.founder p {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 20px;
}

.team-sm {
  margin-bottom: 30px;
}

.team-sm .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.team-sm .image img {
  border-radius: 5px;
}

.team-sm .image .social-links {
  position: absolute;
  background: #2e7eed;
  left: 0;
  right: 0;
  text-align: center;
  width: calc(100% - 80px);
  margin: 0 40px;
  border-radius: 4px;
  opacity: 0;
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  transition: 0.3s;
  bottom: 20px;
}

.team-sm .image .social-links ul {
  margin-bottom: 0;
}

.team-sm .image .social-links ul li a {
  display: block;
  padding: 15px;
}

.team-sm .image .social-links ul li a i {
  font-size: 20px;
  color: #fff;
}

.team-sm .image:hover .social-links {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.team-sm h3 {
  margin-bottom: 0;
}

.team-sm cite {
  font-size: 14px;
  font-style: normal;
}

.team-sm p {
  margin-top: 15px;
}

.featured-article {
  padding: 0 0 50px 0;
}

.featured-article article.featured {
  display: flex;
}

@media (max-width: 768px) {
  .featured-article article.featured {
    flex-wrap: wrap;
  }
}

.featured-article article.featured .image {
  flex-basis: 100%;
  padding: 20px;
}

.featured-article article.featured .image img {
  width: 100%;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .featured-article article.featured .image {
    margin-bottom: 20px;
  }
}

.featured-article article.featured .content {
  margin-left: 30px;
  flex-basis: 100%;
  align-self: center;
}

@media (max-width: 768px) {
  .featured-article article.featured .content {
    text-align: center;
  }
}

.featured-article article.featured .content h2 {
  margin-bottom: 20px;
}

.featured-article article.featured .content h2 a {
  font-size: 30px;
  color: #000;
}

.featured-article article.featured .content h2 a:hover {
  color: #2e7eed;
}

.featured-article article.featured .content p {
  margin-bottom: 25px;
}

.post-sm {
  margin: 10px 0;
}

.post-sm .post-thumb {
  margin-bottom: 15px;
  overflow: hidden;
}

.post-sm .post-thumb img {
  transition: 0.3s ease;
}

.post-sm .post-title {
  margin-bottom: 15px;
}

.post-sm .post-title h3 a {
  color: #000;
  font-size: 20px;
}

.post-sm .post-title h3 a:hover {
  color: #2e7eed;
}

.post-sm:hover .post-thumb img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.blog-single .single-post {
  padding-bottom: 70px;
}

.blog-single .single-post .post-body .feature-image {
  margin-bottom: 30px;
}

.blog-single .single-post .post-body .feature-image img {
  width: 100%;
}

.blog-single .single-post .post-body p {
  margin-bottom: 20px;
}

.blog-single .single-post .post-body .quote {
  padding: 30px 0;
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .blog-single .single-post .post-body .quote {
    width: 80%;
  }
}

.blog-single .single-post .post-body .quote blockquote {
  color: #000;
  padding: 10px 0 10px 30px;
  text-align: left;
  font-size: 30px;
  line-height: 40px;
  border-left: 6px solid #666666;
}

.blog-single .single-post .post-body .post-image {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.blog-single .about-author h2 {
  padding-bottom: 15px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 30px;
  font-size: 30px;
}

@media (max-width: 480px) {
  .blog-single .about-author h2 {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .blog-single .about-author .media {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .blog-single .about-author .media .image {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.blog-single .about-author .media .image img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.blog-single .about-author .media .media-body {
  margin-left: 40px;
}

@media (max-width: 480px) {
  .blog-single .about-author .media .media-body {
    flex-grow: 1;
    width: 100%;
    text-align: center;
    margin-left: 0;
    margin-top: 20px;
  }
}

.blog-single .about-author .media .media-body p {
  margin-bottom: 15px;
}

.related-articles .title {
  margin-bottom: 20px;
}

.related-articles .title h2 {
  font-size: 30px;
}

.pagination-nav {
  display: flex;
  justify-content: center;
}

.pagination-nav ul.pagination {
  padding-top: 30px;
}

.pagination-nav ul.pagination li {
  margin-right: 10px;
}

.pagination-nav ul.pagination li a {
  border-radius: 3px;
  padding: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-color: transparent;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  color: #808080;
  transition: 0.3s ease-in;
}

.pagination-nav ul.pagination li a:hover {
  background-color: #2e7eed;
  color: #fff;
  border-color: transparent;
}

.pagination-nav ul.pagination .active a {
  background-color: #2e7eed;
  color: #fff;
  border-color: transparent;
}

@media (max-width: 480px) {
  .about .content {
    text-align: center;
  }
}

.about .content h2 {
  margin-bottom: 20px;
  text-transform: capitalize;
}

.about .about-slider .item {
  padding: 20px;
}

.about .about-slider .owl-dots .owl-dot:hover span {
  background: #2e7eed;
}

.about .about-slider .owl-dots .owl-dot.active span {
  background: #2e7eed;
}

.create-stories .block img {
  width: 100%;
  margin-bottom: 20px;
}

.create-stories .block h3 {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .create-stories .block {
    margin-bottom: 30px;
  }
}

.quotes .quote-slider h2 {
  font-size: 50px;
}

.quotes .quote-slider cite {
  margin-left: 150px;
  font-style: normal;
}

.clients {
  padding: 50px 0;
}

.clients h3 {
  margin-bottom: 30px;
}

.clients .client-slider .owl-stage {
  display: flex;
  align-items: center;
}

.investors .block {
  margin-bottom: 30px;
}

.investors .block .image {
  margin-bottom: 20px;
}

.investors .block .image img {
  width: 100%;
  border-radius: 8px;
}

.investors .block h3 {
  margin-bottom: 0;
  line-height: 1;
}

.investors .block p {
  font-size: 14px;
}

.hover-zoom {
  overflow: hidden;
  border-radius: 8px;
}

.hover-zoom img {
  transition: 0.3s ease;
}

.hover-zoom:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.error-page {
  position: fixed;
  height: 100%;
  width: 100%;
}

.error-page .center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-page .center .block h1 {
  font-size: 200px;
  font-weight: 400;
  line-height: 266px;
  font-family: "Lora", serif;
}

.error-page .center .block p {
  margin-bottom: 50px;
}

.career-featured .block {
  display: flex;
}

@media (max-width: 768px) {
  .career-featured .block {
    flex-wrap: wrap;
  }
}

.career-featured .block .content {
  flex-basis: 100%;
  align-self: center;
}

@media (max-width: 768px) {
  .career-featured .block .content {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
}

.career-featured .block .content h2 {
  margin-bottom: 30px;
}

.career-featured .block .video {
  justify-content: center;
  align-self: center;
  flex-basis: 100%;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .career-featured .block .video {
    flex-grow: 1;
    width: 100%;
  }
}

.company-fun-facts h2 {
  margin-bottom: 60px;
}

.company-fun-facts .fun-fact {
  margin-bottom: 20px;
  text-align: center;
}

.company-fun-facts .fun-fact i {
  font-size: 25px;
  display: inline-block;
  margin-bottom: 10px;
  line-height: 60px;
  height: 60px;
  width: 60px;
  border: 1px solid #000;
  border-radius: 100%;
}

.gallery .image {
  cursor: pointer;
}

.job-list .block {
  padding: 50px 80px;
  background: #fff;
}

.job-list .block h2 {
  margin-bottom: 60px;
  font-size: 30px;
}

.job-list .block .job {
  padding: 50px 10px;
  display: flex;
}

.job-list .block .job:not(:last-child) {
  border-bottom: 1px solid #cccccc;
}

@media (max-width: 480px) {
  .job-list .block .job {
    flex-wrap: wrap;
  }
}

.job-list .block .job .content {
  flex-basis: 100%;
}

@media (max-width: 480px) {
  .job-list .block .job .content {
    width: 100%;
    flex-grow: 1;
    text-align: center;
    margin-bottom: 30px;
  }
}

.job-list .block .job .content h3 {
  margin-bottom: 0;
}

.job-list .block .job .apply-button {
  flex-basis: 100%;
  align-self: center;
  text-align: right;
}

@media (max-width: 480px) {
  .job-list .block .job .apply-button {
    width: 100%;
    flex-grow: 1;
    text-align: center;
  }
}

.faq .block {
  padding: 50px;
}

@media (max-width: 480px) {
  .faq .block {
    padding: 30px;
  }
}

.faq .block .faq-item {
  margin-bottom: 40px;
}

.faq .block .faq-item .faq-item-title {
  margin-bottom: 30px;
}

.faq .block .faq-item .faq-item-title h2 {
  font-size: 30px;
  border-bottom: 1px solid #cccccc;
}

.faq .block .faq-item .faq-item-title:last-child {
  margin-bottom: 0;
}

.faq .block .faq-item .item .item-link {
  position: relative;
  padding: 10px 0 10px 18px;
}

.faq .block .faq-item .item .item-link a {
  font-size: 20px;
  color: #000;
}

.faq .block .faq-item .item .item-link a span {
  margin-right: 5px;
}

.faq .block .faq-item .item .item-link:before {
  font-family: "themify";
  content: "\e64b";
  position: absolute;
  left: 0;
  font-weight: 600;
}

.faq .block .faq-item .item .accordion-block {
  background: #fafafa;
  margin-left: 18px;
}

.faq .block .faq-item .item .accordion-block p {
  padding: 20px;
}

.privacy .privacy-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
  background: #fafafa;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.privacy .privacy-nav ul {
  padding-left: 0;
  margin-bottom: 0;
}

.privacy .privacy-nav ul li {
  list-style: none;
}

.privacy .privacy-nav ul li a {
  font-size: 16px;
  color: #757575;
  padding: 10px 0;
  font-weight: bold;
  display: block;
}

@media (max-width: 768px) {
  .privacy .privacy-nav ul li a {
    font-size: 16px;
    padding: 5px 0;
  }
}

.privacy .privacy-nav ul li a.active {
  color: #000;
}

@media (max-width: 768px) {
  .privacy .privacy-nav {
    margin-bottom: 30px;
  }
}

.privacy .block {
  background: #fff;
  padding: 40px 50px;
}

.privacy .block .policy-item {
  padding-bottom: 40px;
}

.privacy .block .policy-item .title {
  margin-bottom: 20px;
}

.privacy .block .policy-item .title h3 {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 15px;
}

.privacy .block .policy-item .policy-details p {
  margin-bottom: 40px;
}

.user-login {
  height: 100%;
  width: 100%;
}

.user-login .block {
  display: flex;
}

@media (max-width: 768px) {
  .user-login .block {
    flex-wrap: wrap;
  }
}

.user-login .block .image {
  flex-basis: 100%;
  margin-right: 150px;
}

@media (max-width: 768px) {
  .user-login .block .image {
    flex-grow: 1;
    text-align: center;
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.user-login .block .image img {
  border-radius: 8px;
}

.user-login .block .content {
  flex-basis: 100%;
  align-self: center;
  padding: 50px;
  border: 1px solid #cccccc;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .user-login .block .content {
    flex-grow: 1;
  }
}

.user-login .block .content .logo {
  margin-bottom: 80px;
}

@media (max-width: 992px) {
  .user-login .block .content .logo {
    margin-bottom: 40px;
  }
}

.user-login .block .content .title-text {
  margin-bottom: 35px;
}

.user-login .block .content .title-text h3 {
  padding-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}

.user-login .block .content .new-acount {
  margin-top: 20px;
}

.user-login .block .content .new-acount p,
.user-login .block .content .new-acount a {
  font-size: 14px;
}

.user-login .block .content .new-acount p a {
  color: #000;
}

.coming-soon {
  color: #000;
  padding: 120px 0;
  height: 100vh;
}

@media (max-width: 992px) {
  .coming-soon {
    padding: 80px 0;
  }
}

.coming-soon .block h3 {
  color: #808080;
}

.coming-soon .block .count-down {
  margin-top: 70px;
}

@media (max-width: 768px) {
  .coming-soon .block .count-down {
    margin-top: 40px;
  }
}

.coming-soon .block .count-down .syotimer-cell {
  min-width: 200px;
  padding: 45px 0;
  margin-right: 30px;
  margin-bottom: 20px;
  background: #fff;
  display: inline-block;
}

.coming-soon .block .count-down .syotimer-cell .syotimer-cell__value {
  font-size: 65px;
  line-height: 80px;
  text-align: center;
  position: relative;
  font-weight: bold;
}

.coming-soon .block .count-down .syotimer-cell .syotimer-cell__unit {
  font-size: 20px;
  color: #6c6c6c;
  text-transform: uppercase;
  font-weight: normal;
}

.address .block .address-block {
  text-align: center;
}

.address .block .address-block .icon {
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}

.address .block .address-block .icon i {
  display: block;
  height: 100px;
  width: 100px;
  background: #fafafa;
  border-radius: 100%;
  font-size: 45px;
  text-align: center;
  line-height: 100px;
}

.google-map {
  position: relative;
}

.google-map #map_canvas {
  height: 400px;
  width: 100%;
}

/*************************/
/*     02. Preloader     */
/*************************/
.spinner-wrapper {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(71, 71, 71, 0.77);
}

.spinner {
  position: absolute;
  top: 50%;
  /* centers the loading animation vertically one the screen */
  left: 50%;
  /* centers the loading animation horizontally one the screen */
  width: 3.75rem;
  height: 1.25rem;
  margin: -0.625rem 0 0 -1.875rem;
  /* is width and height divided by two */
  text-align: center;
}

.spinner > div {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #fff;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.btn-outline-sm {
  display: inline-block;
  padding: 0.875rem 1.5rem 0.875rem 1.5rem;
  /* border: 0.125rem solid #5f4dee; */
  border-radius: 2rem;
  background-color: transparent;
  /* color: #5f4dee; */
  font: 700 0.875rem/0 "Arimo", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

/* .btn-outline-sm:hover {
	background-color: #5f4dee;
	color: #fff;
	text-decoration: none;
} */

/* } */

.searchBar .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 10px 10px 10px 25px;
  /* box-shadow: 1px 2px 9px #dbdbdb; */
  box-shadow: none;
  border-color: #d9d9d9 !important;
  /* border-color: #45a2f0!important; */
  /* border-width: 4px; */
}

.searchBar .ant-input-group .ant-input-group-addon:last-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background: transparent;
}

.searchBar
  .ant-input-group
  .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0 50px 50px 0;
  height: 47px;
  padding: 6px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #1890ff;
  border-color: #d9d9d9;
  box-shadow: none;
  /* box-shadow: 1px 2px 9px #dbdbdb; */
}

.searchBar
  .ant-input-group
  .ant-input-group-addon:last-child
  .ant-input-search-button
  span {
  transition: all 0.1s ease-in-out;
  font-size: 20px;
}

.searchBar
  .ant-input-group
  .ant-input-group-addon:last-child
  .ant-input-search-button:hover {
  color: #0461b8;
}

.searchBar:hover
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.searchBar:hover
  .ant-input-group
  .ant-input-group-addon:last-child
  .ant-input-search-button
  .searchBar:focus
  .ant-input-affix-wrapper,
.searchBar .ant-input-affix-wrapper:focus,
.searchBar:focus
  .ant-input-group
  .ant-input-group-addon:last-child
  .ant-input-search-button,
.searchBar
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.searchBar {
  border-color: #d9d9d9 !important;
  border-right-width: 1px;
}

.ant-input-affix-wrapper:focus
/* .searchBar .ant-btn-primary {
  color: #ffffff;
  border-color: #ffffff;
  background: #174fa2;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  font-weight: 600;
} */

/* .searchBar .ant-btn-primary:hover,
.searchBar .ant-btn-primary:focus {
  color: #fff;
  border-color: #fff;
  background: #174fa2;
  text-decoration: underline;
} */

/* .searchBar .ant-btn:hover,
.searchBar .ant-btn:focus {
  color: #174fa2;
  border-color: #fff;
  background: #fff;
  border-left-color: #174fa2;
} */

.searchBar .anticon.anticon-close-circle {
  font-size: 20px;
}

.searchBar .anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  color: #a2a2a2;
  font-size: 16px;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.searchBar .anticon.ant-input-clear-icon:hover,
.ant-input-clear-icon:hover {
  color: rgba(88, 88, 88, 0.25);
}

.searchBar.noButton .ant-input-group-addon {
  display: none;
}

.searchBar.noButton .ant-input-prefix {
  margin-right: 8px;
  /* margin-top: 3px; */
}

.searchBar.noButton .ant-input-affix-wrapper {
  border-radius: 50rem !important;
}

.DigitRoll {
  border: none !important;
}

.center-component {
  padding: 40px 0 0;
  transition: all 0.5s ease-in-out;
}

.shrink-section {
  height: auto;
  padding-bottom: 8px;
  border-bottom: 1px solid #e7e7e7;
}

.shrink-form {
  height: auto;
  padding: 0 0.7rem;
}

.options .card {
  border: 0;
  margin: 10px 2rem;
}

.options .card .img-fluid {
  width: 12.5rem;
  margin: 0 auto;
}

.artifact-card-header {
  background: rgb(35 103 201);
  background: linear-gradient(
    270deg,
    rgb(24, 90, 191) 0%,
    rgb(14, 70, 158) 35%,
    rgb(37, 106, 206) 100%
  );
  border-radius: 5px;
}

.artifact-card-table-body:hover {
  background-color: #f6f6f6;
}

/* .artifact-card-table-body{
  background-color: #fff;
  border: none;
  box-shadow: none;
} */

.artifact-card-table-body .card-body {
  padding: 0.5rem 1rem;
}

.info-icon {
  color: #00de79;
  cursor: pointer;
}

.info-icon:hover {
  color: rgba(0, 222, 121, 0.42);
}

.widget {
  margin-top: 15px;
  /* background: #f1f1f1; */
  /* padding: 20px; */
}

.tools-title {
  font-size: 0.95rem;
  font-weight: 100;
}

.tool-group-text {
  font-size: 0.75rem;
}

.search-page-head {
  padding-top: 20px;
}

.searchBarHome.searchBar
  .ant-input-group
  .ant-input-affix-wrapper:not(:last-child) {
  /* border: 4px solid #23395d !important */
  border: 4px solid #000000 !important;
}

.searchBarHome.searchBar .ant-input-group {
  border: 1px solid #ffffff !important;
  border-radius: 8px;
}

/* .card-copy-container {
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  border-radius: 5px;
} */

.card-link {
  /* font-size: 10px; */
  /* font-weight: 400; */
  padding: 2px 8px;
  /* height: 100%; */
  background: transparent;
  /* border: 1px solid #e1e1e1; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 5px;
}

.card-link img {
  width: 20px;
}

.card-copy-container .copy-icon {
  font-size: 10px;
  margin-left: 2px;
  padding: 5px 10px;
  background-color: #f1f1f1;
  border-left: 1px solid #ddd;
  cursor: pointer;
  /* box-shadow: -3px 0px 10px #b2b2b2; */
  color: #212121;
  height: 100%;
}

.card-copy-container .copy-icon:hover {
  background-color: #f6f6f6;
}

.fixed-height-roller,
.fixed-height-roller .DigitRoll__Cell {
  height: 20px !important;
}

.DigitRoll__Out {
  display: none !important;
}

.DigitRoll {
  /* font-size: 25px; */
  /* color: white; */
  border: none;
  overflow: hidden;
}

.DigitRoll__Cell {
  width: 1.5rem !important;
  /* background: salmon; */
  /* margin: 0 3px; */
  /* border: 1px solid brown; */
}

.DigitRoll__Divider {
  /* font-size: 25px; */
  /* color: black; */
  /* padding: 0 6px; */
}

.customCard {
  text-align: center;
  padding: 0 40px;
}

.mid-section {
  padding: 30px 0;
  background: #f5f5f7;
}

.cardIcon {
  margin-bottom: 32px;
}

.cardIcon img {
  width: 100px;
}

.cardTitle {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 16px;
}

.cardText {
  font-size: 16px;
  font-weight: 400;
  color: #6d6d9c;
}

.coming-soon {
  color: #858585;
  font-size: 15px;
}

.btn-login-header {
  border: 0.125rem solid #ffffff !important;
  color: #ffffff !important;
  background-color: #23395d !important;
}

.btn-login-header:hover {
  border: 0.125rem solid #ffffff !important;
  color: #23395d !important;
  background-color: #ffffff !important;
}

.versionMenuList {
  /* height: 250px;
  overflow: hidden;
  overflow-y: scroll; */
  max-height: 170px;
  overflow: hidden;
  overflow-y: auto;
}

.truncate {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 90px;
  text-overflow: ellipsis;
  /* transition: all 1s ease-in-out; */
}

.whiteSpaceNormal {
  white-space: normal;
}

.showMore {
  position: absolute;
  font-size: 12px;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: #1890ff;
}

.showLess {
  bottom: 0;
  transform: unset;
  top: unset;
}

.truncate .showMore {
  /* display: none; */
}

.truncate:hover .showMore {
  display: block;
}

.navbar-collapse.collapse.show {
  background: #fff;
}

.search-result-anchor {
  color: #7c7b7b;
}

.scroll-style::-webkit-scrollbar-track {
  /* -webkit-box-shadow: none;
  box-shadow: none;
  border: none; */
  background-color: transparent;
}

.scroll-style::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  /* -webkit-box-shadow: none;
  box-shadow: none;
  border: none; */
}

.scroll-style::-webkit-scrollbar-thumb {
  /* background-color: #5f6b75; */
  background-color: rgba(95, 107, 117, 0.9);
  /* border: 2px solid #555555; */
}

.no-icon-result .anticon {
  display: none;
}

.no-icon-result .ant-result-icon {
  margin: 0;
}

.no-icon-result {
  padding-left: 1.3rem;
}

.no-icon-result .ant-result-title {
  text-align: left;
}

.alert-warning {
  padding: 0;
  background: none;
  border: none;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.technology-icon {
  width: 55px;
  /* height: 45px; */
  /* cursor: pointer; */
  background-color: #fff;
  border-radius: 8px;
  display: inline-block;
  /* cursor: pointer; */
}

.technology-icon.withNames {
  width: 75px;
  /* width: 350px;
  height: 190px;
  background-color: rgb(216 229 253);
  border: 12px solid #212121;
  border-radius: 25px !important; */
}

.withNames img {
  width: 20px;
}

.numbers-tools-container {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.technology-icon:hover {
  background-color: #eff0f6;
}

.ant-btn-primary {
  /* border-color: #1d76bc !important;
  background: #1d76bc !important; */
  color: #fff !important;
  background: #3979d9 !important;
  border-color: #3672cc !important;
}

.ant-btn-primary:disabled {
  border-color: #1d76bc;
  opacity: 0.8;
}

.ant-btn-primary.ant-btn-dangerous {
  background: #dc4446 !important;
  border-color: transparent !important;
}

.ant-btn-primary.ant-btn-dangerous:disabled {
  background: #dc4446 !important;
  border-color: transparent !important;
  opacity: 0.8;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.filter .ant-collapse-content-box {
  padding: 0;
}

.searchPagePagination .ant-pagination-disabled.ant-pagination-prev a,
.searchPagePagination .ant-pagination-disabled.ant-pagination-next a {
  opacity: 0.2;
}

.searchPagePagination .ant-pagination-item-container,
.searchPagePagination .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.searchPagePagination li {
  order: 2;
  /* border-bottom: none !important; */
}

/* .searchPagePagination li.ant-pagination-total-text{
  order: 3;
} */

.searchPagePagination li.ant-pagination-options {
  order: 1;
  margin-right: 16px;
}

.ant-pagination.searchPagePagination
  .ant-pagination-disabled
  .ant-pagination-item-link,
.searchPagePagination.ant-pagination
  .ant-pagination-disabled:hover
  .ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.25);
}

/* .box :nth-child(2) {
  order: 3;
}

.box :nth-child(3) {
  order: 1;
}

.box :nth-child(4) {
  order: 3;
}

.box :nth-child(5) {
  order: 1;
} */

.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #1d76bc;
  color: #1d76bc;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #1d76bc;
  color: #1d76bc;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #1d76bc;
  color: #1d76bc;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #1d76bc;
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.dot-flashing-tools {
  position: relative;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #70a8d3;
  color: #70a8d3;
  animation: dotFlashingTools 4s infinite linear alternate;
  animation-delay: 5s;
  margin-left: 6px;
  /* margin: 0 auto; */
  /* width: 60%; */
}

.dot-flashing-tools::before,
.dot-flashing-tools::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing-tools::before {
  left: -5px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #1d76bc;
  color: #1d76bc;
  animation: dotFlashing 2.5s infinite alternate;
  animation-delay: 0.5s;
}

.dot-flashing-tools::after {
  left: 5px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #1d76bc;
  color: #1d76bc;
  animation: dotFlashingTools 2.5s infinite alternate;
  animation-delay: 1.5s;
}

@keyframes dotFlashingTools {
  0% {
    background-color: #70a8d3;
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.top-title {
  font-size: 0.8rem;
  color: #ffffff;
}

.search-title {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.scroll--simple::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroll--simple::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.scroll--simple::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.scroll--simple::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.scroll--simple::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.nomargin-skeleton .ant-skeleton-title {
  margin-bottom: 0;
}

.detailsSearchPagePagination {
  text-align: center;
}

.detailsSearchPagePagination .ant-pagination-total-text {
  display: block;
}

.detailsSearchPagePagination .ant-pagination-disabled.ant-pagination-prev a,
.detailsSearchPagePagination .ant-pagination-disabled.ant-pagination-next a {
  opacity: 0.2;
}

.detailsSearchPagePagination .ant-pagination-item-container,
.detailsSearchPagePagination .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.detailsSearchPagePagination li {
  order: 2;
}

/* .detailsSearchPagePagination li.ant-pagination-total-text{
  order: 3;
} */

.detailsSearchPagePagination li.ant-pagination-options {
  order: 1;
  margin-right: 16px;
  margin-left: 0;
}

.link-primary {
  color: #0052cb !important;
  text-decoration: none;
}

/* .link-primary:focus, .link-primary:hover{
  color: #6498e7  !important;
} */
/* .tableFixHead          { overflow: auto; height: 100px; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; } */

.scroll--simple .ant-list-item {
  padding: 8px 5px 8px 10px;
}

/* thead[class*="ant-table-thead"] th {
  background-color: #23395d !important;
} */

/* @media (max-width: 768px) {
  .search-title {
    line-height: 1;
    display: inline-block !important;
  }

  .center-component {
    height: auto;
    padding: 0;
    margin-bottom: 70px;
  }

  .card-link {
    padding: 4px;
  }
} */

.line-height-default {
  line-height: 1.2;
}

.tvCards .technology-icon {
  width: 300px !important;
  /* height: 170px !important; */
  /* border: 8px solid #45a2f0; */
  /* border: 8px solid #cfe2ff; */
  border: 8px solid #7ccaff;
  border-radius: 8px;
  /* background: #edf9ff; */
  /* background: #45a2f0; */
  /* color: #084298; */
  color: #202020;
  /* background: #cfe2ff; */
  background: #7ccaff;
  margin: 0 35px 25px 0;
  /* background: linear-gradient(180deg, #449feb 0%, rgba(224, 245, 255, 1) 100%); */
}

.tvCards .ant-collapse-expand-icon {
  line-height: 1;
}

thead th {
  font-weight: 700;
  /* color: #fff; */
}

.ecoTable table {
  table-layout: auto !important;
}

.ecoTable a:hover {
  text-decoration: underline;
}

a.link-primary:hover {
  text-decoration: underline;
}

.ant-back-top {
  right: 20px;
  bottom: 50px;
}

.ant-back-top-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.ant-message {
  z-index: 1050;
}

.ant-tooltip-inner {
  font-size: 11px;
}

.tvCards .ant-collapse .ant-collapse-header,
.tvCards .ant-collapse-content-box {
  padding-left: 0;
}

.tvCards .technology-icon {
  position: relative;
}

/* .tvCards .technology-icon:before{
  content: '';
  border: 1px solid #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  z-index: 0;
} */

.tvCards .white-border {
  /* border: 1px solid #fff; */
  /* border-radius: 4px; */
  /* color: #ffffff; */
}

.tvCards .ant-collapse-arrow {
  color: rgba(255, 255, 255, 0.5) !important;
}

/* .ant-table-sticky-holder{
  background: #2b508d;
} */

/* .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover, .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #2b508d;
}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
  color: #fff;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis{
 color:  #ddd
}
.ant-pagination-item-active a{
  color: #23395d !important;
} */
/* .singleLine{
  border-left: 2px solid #fff;
  padding: 0px 10px;
  background: rgba(255,255,255,0.2);
} */
.ant-popover-message-title {
  padding-left: 0;
}

.ant-popover-buttons {
  display: none;
}

.underlineAfter {
  position: relative;
  display: inline-block;
  z-index: 11;
}

/* .underlineAfter:after{
  content: '';
  height: 4px;
  width: 103%;
  background: #9d6e01;
  position: absolute;
  bottom: 8px;
  left: 0;
  z-index: -6;
  margin: 0 auto;
  right: 0;
} */

.google_font {
  font-size: 48px;
  line-height: 48px;
  font-weight: 400;
  letter-spacing: 0px;
}

.sub_count_font {
  font-size: 36px;
  line-height: 38px;
  font-weight: 300;
  letter-spacing: 0px;
}

.bounce {
  animation: bounce 2s ease infinite;
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }

  80% {
    transform: translateY(-15%);
  }

  90% {
    transform: translateY(0%);
  }

  95% {
    transform: translateY(-7%);
  }

  97% {
    transform: translateY(0%);
  }

  99% {
    transform: translateY(-3%);
  }

  100% {
    transform: translateY(0);
  }
}

.dashboard-intel-numbers:hover p {
  text-decoration: underline;
}

.scannedApp-radio span {
  word-break: break-all;
}

.group-container-scannedApplications .ant-radio-group {
  width: 100%;
}

.scannedApp-radio span.appTitle {
  word-break: break-all;
  inline-size: 90px;
  overflow-wrap: break-word;
}

.scannerSearchBar > div.ant-space-item:first-child {
  width: 100%;
}

.scanSearchBarContainer {
  display: block;
  border: 1px solid #fff;
  border-radius: 8px;
}

.scanSearchBar {
  border: 4px solid #000;
}

.fileTab .ant-tabs-tab {
  color: #fff;
}

.fileTab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
  /* background: #fff; */
}

.fileTab .ant-tabs-tab:hover {
  color: #ddd;
}

.fileTab .ant-tabs-ink-bar {
  background: #ffffff;
}

.ocrloader {
  width: 100%;
  height: 100%;
  position: relative;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden; */
}

.ocrloader span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 20px;
  background-color: rgba(0, 99, 247, 0.54);
  z-index: 1;
  transform: translateY(135%);
  animation: move 5s cubic-bezier(0.15, 0.44, 0.76, 0.64);
  animation-iteration-count: infinite;
}

.ocrloader > div {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48%;
  backface-visibility: hidden;
}

.ocrloader i {
  display: block;
  height: 1px;
  background: #000;
  margin: 0 auto 2px;
  margin: 0 auto 2.2px;
  backface-visibility: hidden;
}

.ocrloader i:nth-child(2) {
  width: 75%;
}

.ocrloader i:nth-child(3) {
  width: 81%;
}

.ocrloader i:nth-child(4) {
  width: 87%;
}

.ocrloader i:nth-child(6) {
  width: 71%;
}

.ocrloader i:nth-child(7) {
  width: 81%;
}

.ocrloader i:nth-child(8) {
  width: 65%;
}

.ocrloader i:nth-child(9) {
  width: 83%;
}

.ocrloader i:nth-child(10) {
  width: 75%;
}

.ocrloader i:nth-child(12) {
  width: 86%;
}

.ocrloader i:nth-child(14) {
  width: 65%;
}

.ocrloader i:nth-child(16) {
  width: 75%;
}

.ocrloader i:nth-child(18) {
  width: 83%;
}

.ocrloader:before,
.ocrloader:after,
.ocrloader em:after,
.ocrloader em:before {
  border-color: #000;
  content: "";
  position: absolute;
  width: 19px;
  height: 16px;
  border-style: solid;
  border-width: 0px;
}

.ocrloader:before {
  left: 0;
  top: 0;
  border-left-width: 1px;
  border-top-width: 1px;
}

.ocrloader:after {
  right: 0;
  top: 0;
  border-right-width: 1px;
  border-top-width: 1px;
}

.ocrloader em:before {
  left: 0;
  bottom: 0;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.ocrloader em:after {
  right: 0;
  bottom: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

@keyframes move {
  0%,
  100% {
    transform: translateY(135%);
  }

  50% {
    transform: translateY(0%);
  }

  75% {
    transform: translateY(572%);
  }
}

.custom-table {
  font-size: 14px;
}

.custom-table thead tr th {
  font-weight: 600;
}

.table-striped-rows .ant-table {
  /* background: transparent ; */
  background: #ededed !important;
  border-radius: 0 !important;
}

.table-striped-rows .extendedPagePagination {
  margin: 16px 38px !important;
}

.table-striped-rows-new .ant-table-pagination {
  margin: 16px 38px !important;
}

.table-striped-rows thead,
.table-striped-rows thead th,
.table-striped-rows thead th td {
  background: transparent !important;
  transition: none !important;
  border-color: #d0d0d0 !important;
}

.table-striped-rows tbody,
.table-striped-rows tbody tr {
  background: transparent !important;
}

.table-striped-rows tbody tr td {
  background: transparent !important;
  border-color: #d0d0d0 !important;
}

.table-striped-rows .ant-table-header.ant-table-sticky-holder {
  background: transparent !important;
}

.riskRatingTree .ant-tree-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}

.riskRatingTree .ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 0.8rem;
}

.scanDetailsTable
  .ant-table-expanded-row.ant-table-expanded-row-level-1
  > .ant-table-cell {
  border-top: 0px solid #ddd;
  border-bottom: 0px solid #ddd;
  background: #ffffff;
}

/* .switch-sbom {
  position: absolute;
  top: 10px;
  right: 20px;
} */

/*Scanner form start*/

.sotcox-osvs-form {
  position: relative;
}

@media (max-width: 1199px) {
  .sotcox-osvs-form {
    margin-top: 40px;
  }
}

.sotcox-osvs-form.sotcox-input-field input {
  padding: 20px 30px;
}

.sotcox-osvs-form .form-actions {
  /* padding: 10px 42px; */
  position: absolute;
  right: 10px;
  top: 10px;
}

.sotcox-osvs-form .form-actions .sotcox-btn {
  padding: 10px 42px;
}

.sotcox-osvs-form .form-actions .sotcox-btn.loading-btn {
  padding: 5px 42px;
}

.sotcox-osvs-form .form-actions .sotcox-btn:disabled {
  transition: none;
  opacity: 0.65;
  pointer-events: none;
}

.sbom-text {
  min-width: 120px;
  display: inline-block;
}

@media (max-width: 767px) {
  /* .sotcox-osvs-form .sotcox-btn {
    position: static;
    top: 0;
    right: 0;
    margin-top: 20px;
    background: #09C3FF;
    padding: 15px 42px;
  } */

  .sotcox-osvs-form .form-actions {
    position: static;
    top: 0;
    right: 0;
    margin-top: 20px;
  }

  .sotcox-osvs-form .form-actions .sotcox-btn {
    background: #09c3ff;
    padding: 15px 42px;
  }

  .sotcox-osvs-form .form-actions .sotcox-btn.loading-btn {
    padding: 5px 42px;
  }
}

/*Scanner form end*/

.module-tab .ant-tabs-nav {
  width: 20rem;
}

.module-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  padding: 4px 8px;
  border-left: none;
  border-right: none;
  /* margin-top: 5px; */
}

.module-tab
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active {
  background: #ffffff;
}

.module-tab
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn
  > span {
  color: #266ff2;
  text-shadow: none;
}

.module-tab
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab
  .ant-tabs-tab-btn
  > span {
  word-wrap: break-word !important;
  word-break: break-word !important;
  display: block;
  white-space: break-spaces;
  text-align: left;
}

.module-tab .ant-tabs-content-holder {
  background: #ffffff;
}

.module-tab .ant-tabs-content-holder .ant-tabs-tabpane {
  padding-right: 24px;
  padding-top: 24px;
}

.nav-tabs .nav-link {
  color: #212121;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #266ff2 !important;
}

.report-tabs button.nav-link {
  text-align: left;
  color: #ffffff;
}

.report-tabs button.nav-link.active {
  color: #adb5bd !important;
  background-color: rgb(37, 43, 54) !important;
}

.text-and-nodes circle {
  text-align: center;
  /* fill: #E2137E;
  r: 10;
  stroke: #333;
  stroke-width: 2px; */
}

.inner-tspan {
  color: rgb(0, 0, 0);
  font-size: 0.8em;
  font-weight: normal;
  fill: rgb(0, 0, 0);
}

.linked-text {
  color: rgb(0, 0, 0);
  font-size: 0.8em;
  font-weight: normal;
  fill: rgb(0, 0, 0);
}

.filtered {
  filter: url(#inset-shadow);
}

.legend-linkGraph {
  line-height: 1;
  font-size: 12px;
}

.sidebar {
  width: 190px;
  z-index: 1000;
  /* background: #1c2932; */
  background: #1f242e;
  /* border-right: 1px solid #1c2932; */
  border-right: 1px solid #1f242e;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  padding: 0 0 95px;
  transition: all 0.1s ease-out;
}

.menu-title {
  letter-spacing: 0.05em;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #838fb9;
  font-weight: 600;
  /* line-height: 3.64; */
  line-height: 1.64;
  /* padding: 0 1.5rem; */
}

.sidebar-nav,
.sidebar-nav li {
  width: 100%;
}

/* .sidebar-nav li {
  margin-bottom: 8px;
} */

.sidebar-nav li a.nav-link {
  display: flex;
  align-items: center;
  padding: 0.6rem;
  color: #a2aac4;
  font-size: 0.9375rem;
  font-weight: var(--tb-nav-link-font-weight);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  text-decoration: none !important;
}

.sidebar-nav li a.nav-link.active {
  color: #f15c28;
  /* background-color: #4f4f4f; */
}

.sidebar-nav li a.nav-link:hover {
  color: #f15c28;
  /* background-color: #4f4f4f; */
}

.bg-header-dark {
  background: #1f242e;
}

.settings-box {
  /* background-color: rgba(216, 225, 241, 0.91); */
  border-radius: 10px;
}

.v-top {
  vertical-align: top;
  line-height: 1;
}

.white-spinner .ant-progress-text {
  color: white !important;
}

.white-spinner .ant-progress-inner {
  background-color: rgb(225, 225, 225) !important;
}

.bg-custom-dark {
  background-color: #28282b;
  /* background: linear-gradient(0deg, rgba(10,11,34,1) 0%, rgba(43,45,83,1) 100%); */
}

.table-button {
  border-color: #dc682a !important;
  background: #dc682a !important;
  color: #ffffff !important;
}

.table-button:hover {
  border-color: #ffffff !important;
  background: #ffffff !important;
  color: #dc682a !important;
}

.table-button:disabled {
  opacity: 0.3 !important;
}

.repo-table .ant-checkbox-inner {
  width: 13px;
  height: 13px;
}

:where(.css-dev-only-do-not-override-4rqkrd).ant-progress .ant-progress-text {
  color: #fff;
}

.card-background {
  background: #28282b;
}

.black-border {
  border: 1px solid #363636;
}

.loader-line {
  width: 70px;
  height: 6px;
  position: relative;
  overflow: hidden;
  background-color: #1f242e;
  margin: 0 auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 6px;
  width: 40%;
  background-color: #438eff;
  -webkit-animation: lineAnim 6s linear infinite;
  -moz-animation: lineAnim 6s linear infinite;
  animation: lineAnim 6s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}

.head-tag {
  font-size: 70px;
  font-weight: 800;
  position: relative;
  line-height: 100px;
}

/* .head-tag:before {
  position: absolute;
  content: '';
  width: 32px;
  height: 2px;
  top: 0;
  left: 0;
  background-color: white;
} */

.head-tag-subtext {
  line-height: 1.2;
}

.highlight-area {
  /* background: rgb(178, 34, 34); */
  border-radius: 8px;
  height: 35px;
  width: 45px;
  font-size: 75%;
  color: rgb(217 74 71);
}

.circle {
  position: relative;
  margin: 20px;
  height: 120px;
  width: 120px;
  background-color: transparent;
  border-radius: 50%;
  font-size: 24px;
}

.circle:before {
  position: absolute;
  content: "";
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  border: 4px dotted orange;
  top: -10px;
  left: -11px;
  border-radius: inherit;
  /* animation: spin 10s linear infinite; */
}

.circle-critical {
  background-color: transparent;
  border: 1px solid #dc3328;
}

.circle-critical:before {
  border-color: #dc3328;
}

.circle-high {
  background-color: transparent;
  border: 1px solid #ebaa37;
}

.circle-high:before {
  border-color: #ebaa37;
}

.circle-medium {
  background-color: transparent;
  border: 1px solid #f2de12;
}

.circle-medium:before {
  border-color: #f2de12;
}

.circle-low {
  background-color: transparent;
  border: 1px solid #d5d202;
}

.circle-low:before {
  border-color: #d5d202;
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

.bg-customDark {
  background-color: #141821;
}

.card.custom-card {
  margin-bottom: 1.5rem;
  border-color: #2b313e;
  background-color: #1f242e;
  border-radius: calc(0.6rem - 1px) calc(0.6rem - 1px);
}

.custom-card .card-header {
  border-bottom: 1px solid #2b313e;
  border-radius: calc(0.6rem - 1px) calc(0.6rem - 1px) 0 0;
  padding: 0.4rem 1.2rem;
  background-color: transparent;
}

.custom-card .card-header .card-title {
  font-size: 16px;
  color: #fff;
}

.custom-card .card-header a {
  color: rgba(142, 187, 255, 1);
  text-decoration: none;
}

.custom-card .card-header a:hover {
  color: rgba(165, 201, 255, 1);
  text-decoration: none;
}

.path {
  fill: none;
  stroke-width: 4px;
  marker: url(#diamond);
}

.progress-bar-animated {
  position: relative;
}

.progress-bar-animated::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.7));
  transform: translateX(-100%);
  animation: progress-bar-shine 2s infinite;
}

@keyframes progress-bar-shine {
  to {
    transform: translateX(0);
    opacity: 0.1;
  }
}

.other-header {
  position: relative;
}

.other-header:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 4px;
  background-color: #ffffff;
}

.custom-table tbody tr td {
  background-color: transparent;
  padding: 0.5rem 1.2rem;
  /* color: #ffffff; */
  color: #adb5bd;
  vertical-align: middle;
  border-bottom: 1px solid #2b313e;
}

.custom-table tbody tr:last-child td {
  border-bottom: none !important;
}

.custom-table tbody tr td h6 {
  color: #adb5bd;
}

.fade-text {
  /* color: #575757; */
  color: rgb(97, 97, 97);
}

.custom-table tbody tr:hover td {
  background-color: rgb(37, 43, 54);
  color: #adb5bd;
}

.custom-table tbody tr:last-child td {
  border-bottom: none;
}

.blink_me {
  animation: blinker 5s ease-in-out infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.size-row-count {
  display: inline-block;
  /* width: 10px; */
  text-align: right;
}

.alert-primary-custom {
  background-color: #1b3966 !important;
  color: #8ebbff !important;
  border: 1px solid #285599 !important;
}

.bg-light-subtle {
  background-color: #2b313e;
}

.custom-table.ant-table-wrapper .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  list-style: none;
  font-family: Arimo;
  background: transparent;
  border-radius: 0;
}

.custom-table.ant-table-wrapper .ant-table .ant-table-container {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header.ant-table-sticky-holder,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell {
  background: transparent;
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header {
  border-radius: 0;
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  td,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell {
  background: transparent;
  border-bottom: 1px solid #2b313e;
  color: #adb5bd;
  /* padding: 0.2rem 0.2rem; */
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th:before {
  background-color: transparent;
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger {
  color: #adb5bd;
  opacity: 0.3;
  display: none;
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th:hover
  .ant-table-column-sorter
  .ant-table-column-sorter-down,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th:hover
  .ant-table-column-sorter
  .ant-table-column-sorter-up,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:hover
  .ant-table-column-sorter
  .ant-table-column-sorter-down,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:hover
  .ant-table-column-sorter
  .ant-table-column-sorter-up,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th:hover
  .ant-dropdown-trigger.ant-table-filter-trigger,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th:hover
  .ant-dropdown-trigger.ant-table-filter-trigger,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down.active,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up.active,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active {
  display: flex;
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down.active,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up.active,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down.active,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up.active,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active {
  color: #8ebbff;
  opacity: 1;
}

.custom-table.ant-table-wrapper .ant-table .ant-table-body tbody tr td {
  background: transparent;
  padding: 0.5rem 1.2rem;
  /* color: #ffffff; */
  color: #adb5bd;
  vertical-align: middle;
  border-bottom: 1px solid #2b313e;
  /* padding: 0.4rem 0.2rem; */
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-body
  tbody
  tr.ant-table-expanded-row
  td {
  padding: 16px !important;
}

.custom-table.ant-table-wrapper .ant-table .ant-table-body tbody tr:hover td {
  background-color: rgb(37, 43, 54);
  color: #adb5bd;
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-body
  tbody
  tr:last-child
  td {
  border-bottom: none;
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:before,
.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  td:before {
  background-color: transparent !important;
}

.custom-table.ant-table-wrapper.table-no-select-all
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th.ant-table-selection-column
  div {
  display: none;
}

.custom-table .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.custom-table .ant-pagination .ant-pagination-next .ant-pagination-item-link {
  color: #adb5bd !important;
}

:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table
  .ant-table-thead
  > tr
  > td {
  padding: 0.5rem 1.2rem !important;
}

:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table
  .ant-table-thead
  > tr
  > th.ant-table-selection-column,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table
  .ant-table-thead
  > tr
  > td.ant-table-selection-column {
  padding: 8px !important;
}

.custom-table.ant-table-wrapper
  .ant-table
  .ant-table-body
  tbody
  tr
  td.ant-table-selection-column {
  padding: 8px;
}

/* :where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table .ant-table.ant-table-small .ant-table-title,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table .ant-table.ant-table-small .ant-table-footer,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table .ant-table.ant-table-small .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table .ant-table.ant-table-small .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table .ant-table.ant-table-small .ant-table-tbody>tr>td,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table .ant-table.ant-table-small tfoot>tr>th,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper.review-vul-table .ant-table.ant-table-small tfoot>tr>td {
  padding: .5rem 1.2rem !important;
} */

.details-hover-animate .fa-angle-right {
  margin-left: 0.25rem;
  transition: all 0.35s ease-in-out;
}

.details-hover-animate:hover .fa-angle-right {
  margin-left: 0.45rem;
}

.details-hover-animate.disabled {
  opacity: 0.7;
  pointer-events: none;
  cursor: default;
  color: #858585;
}

.btn.btn-dark-warning {
  color: #f6b749;
  background-color: rgba(246, 183, 73, 0.1);
  border-color: transparent;
}

.btn.btn-dark-warning:hover {
  color: #fff;
  background-color: #dda542;
  border-color: transparent;
}

hr {
  color: #2b313e !important;
  opacity: 1 !important;
}

.highcharts-text-outline {
  stroke-width: 0;
}

.radar-panel {
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  margin: auto;
  box-shadow: inset 0 0 0 30px #141821, inset 0 0 0 31px rgba(0, 255, 0, 0.2),
    inset 0 0 0 60px #141821, inset 0 0 0 61px rgba(0, 255, 0, 0.2),
    inset 0 0 0 90px #141821, inset 0 0 0 91px rgba(0, 255, 0, 0.2),
    inset 0 0 0 120px #141821, inset 0 0 0 121px rgba(0, 255, 0, 0.2),
    inset 0 0 0 150px #141821, inset 0 0 0 151px rgba(0, 255, 0, 0.2),
    inset 0 0 0 121px rgba(0, 255, 0, 0.2), inset 0 0 0 180px #141821,
    inset 0 0 0 181px rgba(0, 255, 0, 0.2),
    inset 0 0 0 121px rgba(0, 255, 0, 0.2), inset 0 0 0 210px #141821,
    inset 0 0 0 211px rgba(0, 255, 0, 0.2);
  border-radius: 50%;
  transform: rotate(0deg);
  overflow: hidden;
  width: 250px;
  height: 250px;
  border: 1px solid #393;
}

.radar-panel.red-scanner {
  border: 1px solid #da4a47;
  box-shadow: inset 0 0 0 30px #141821, inset 0 0 0 31px #da4a47,
    inset 0 0 0 60px #141821, inset 0 0 0 61px #da4a47, inset 0 0 0 90px #141821,
    inset 0 0 0 91px #da4a47, inset 0 0 0 120px #141821,
    inset 0 0 0 121px #da4a47, inset 0 0 0 150px #141821,
    inset 0 0 0 151px #da4a47, inset 0 0 0 121px #da4a47,
    inset 0 0 0 180px #141821, inset 0 0 0 181px #da4a47,
    inset 0 0 0 121px #da4a47, inset 0 0 0 210px #141821,
    inset 0 0 0 211px #da4a47;
}

.radar-panel.red-scanner .radar-scanner {
  background-image: linear-gradient(
    to top right,
    #da4a47 0%,
    rgba(0, 0, 0, 0) 50%
  );
  border-left: 1px solid #da4a47;
}

.radar-panel .radar-scanner {
  -webkit-animation: scanning 6s infinite linear;
  animation: scanning 6s infinite linear;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjEuMCIgeDI9IjEuMCIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwZmYwMCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: linear-gradient(
    to top right,
    #00ff00 0%,
    rgba(0, 0, 0, 0) 50%
  );
  transform-origin: top left;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  border-left: 1px solid rgba(0, 255, 0, 0.1);
}

.radar-panel:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: rgba(0, 255, 0, 0.2);
}

.radar-panel:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(0, 255, 0, 0.2);
}

.radar-panel.red-scanner:before,
.radar-panel.red-scanner:after {
  background: #da4a47;
}

/* .radar-panel .something {
  list-style: none;
}
.radar-panel .something li {
  -webkit-animation: target 6s infinite linear;
  animation: target 6s infinite linear;
  border-radius: 50%;
  box-shadow: 0 0 10px #AFA;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  background: #AFA;
}
.radar-panel .something li:nth-child(1) {
  -webkit-animation-delay: 2250ms;
          animation-delay: 2250ms;
  top: 30%;
  left: 30%;
}
.radar-panel .something li:nth-child(2) {
  -webkit-animation-delay: 3200ms;
          animation-delay: 3200ms;
  top: 15%;
  left: 60%;
}
.radar-panel .something li:nth-child(3) {
  -webkit-animation-delay: 5050ms;
          animation-delay: 5050ms;
  top: 70%;
  left: 80%;
}
.radar-panel .something li:nth-child(4) {
  -webkit-animation-delay: 1100ms;
          animation-delay: 1100ms;
  top: 60%;
  left: 20%;
}
.radar-panel .something li:nth-child(5) {
  -webkit-animation-delay: 3000ms;
          animation-delay: 3000ms;
  top: 30%;
  left: 50%;
} */

.echarts-graph-container svg path[stroke="#da4a47"],
.echarts-graph-container svg path[stroke="#d67043"],
.echarts-graph-container svg path[stroke="#f1a236"],
.echarts-graph-container svg path[stroke="#e0e6b7"] {
  stroke-dasharray: 4;
  animation: dash 100s linear infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
}

.scanning-animation {
  /* animation: scanning 25s infinite linear; */
  animation: scanning 25s infinite linear;
  transition: all 0.5s ease-in-out;
}

@-webkit-keyframes scanning {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scanning {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes target {
  1% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    transform: scale(2.5);
  }

  3% {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    transform: scale(1.5);
  }

  60% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transform: scale(0.6);
  }
}

@keyframes target {
  1% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    transform: scale(2.5);
  }

  3% {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    transform: scale(1.5);
  }

  60% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transform: scale(0.6);
  }
}

.ant-alert.ant-alert-info.no-data-alert-custom {
  background-color: #1b3966;
  border: 1px solid #285599;
  color: #8ebbff;
}

.ant-alert.ant-alert-info.no-data-alert-custom .ant-alert-message {
  color: #8ebbff;
  margin-bottom: 2px;
}

.ant-alert.ant-alert-info.no-data-alert-custom .anticon {
  margin-top: 0.25rem !important;
}

.custom-tooltip-recharts {
  background-color: #2b313e !important;
  border: 1px solid rgb(43, 49, 62) !important;
  padding: 8px;
  border-radius: 5px;
}

.custom-tooltip-recharts .label small {
  font-size: 70%;
}

.custom-dragger-upload .ant-upload {
  border: none;
}

.scan-modal .ant-modal-content {
  background: #1f242e;
}

.custom-black-drawer {
  background: #1f242e !important;
  color: #fff;
}

.custom-table.white-color-header thead tr th {
  color: #fff;
}

a.anchor-header-notification {
  color: #ffffff !important;
  transition: all 0.2s ease-in-out;
}

a.anchor-header-notification:hover {
  color: #ececec !important;
}

.theme-border {
  border: 1px solid rgb(43, 49, 62);
}

/* .repo-list {
  max-height: auto;
  transition: all 6s ease-in-out;
  -webkit-transition: all 6s ease;
}

.repo-list li {
  border-bottom: 1px solid rgb(43, 49, 62);
}

.repo-list.more {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

.repo-list li a {
  background: transparent;

}

.repo-list li a.white-link {
  color: #adb5bd !important;
}

.repo-list li a:hover {
  background: rgb(43, 49, 62);
} */

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #adb5bd !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #adb5bd !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #adb5bd !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #adb5bd !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #adb5bd !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #adb5bd !important;
}

.custom-dark-input {
  background-color: transparent;
  border: 1px solid rgb(43, 49, 62);
  color: #fff;
}

.custom-dark-input .ant-input {
  background-color: transparent;
  border: 1px solid rgb(43, 49, 62);
  color: #fff;
}

.repo-branch-collapse .ant-collapse-item .ant-collapse-header {
  color: #adb5bd;
}

.repo-branch-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-header-text
  h5 {
  color: #adb5bd;
}

.repo-branch-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header {
  color: #ffffff;
}

.repo-branch-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-header-text
  h5 {
  color: #ffffff;
}

.repo-branch-collapse
  .ant-collapse-item.ant-collapse-item-disabled
  .ant-collapse-header {
  opacity: 0.3;
}

.sotcox-btn:disabled {
  opacity: 0.6;
  pointer-events: none;
}

.iscan-search.ant-input-search-with-button .ant-input-affix-wrapper .ant-input {
  padding: 0;
}

.iscan-search .ant-input {
  padding: 0 !important;
}

.other-links {
  color: #adb5bd;
  /* width: 35px;
  height: 35px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.active-other-link,
.other-links:hover {
  border: 1px solid #ab20fd !important;
  color: #ab20fd;
}

.other-links.active-other-link .icon {
  color: #ab20fd;
}

.other-links:hover .icon {
  color: #ab20fd;
}

.divider-custom .ant-divider-inner-text {
  color: #adb5bd;
}

.divider-custom:before {
  border: 1px solid rgb(43, 49, 62) !important;
}

.divider-custom:after {
  border: 1px solid rgb(43, 49, 62) !important;
}

.uploader-custom .ant-upload.ant-upload-btn {
  padding: 8px;
}

.shrink-input {
  width: 50px;
  /* transition: all 0.5s ease; */
}

.shrink-input:hover {
  width: 250px;
}

.selectedLink {
  position: relative;
}

.selectedLink:after {
  content: "";
  border-bottom: 1px solid #8dbafe;
  width: 70%;
  height: 100%;
  bottom: -6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
}

.custom-table.custom-table-override thead tr th {
  border-bottom: none;
}

.custom-table.custom-table-override tbody tr td {
  border-bottom: none;
}

.custom-switch.ant-switch.ant-switch-checked,
.custom-switch.ant-switch.ant-switch-checked:hover {
  background: #ab20fd;
}

.ant-table-wrapper .ant-table-row-expand-icon {
  background-color: transparent;
}

.component-view-table
  .ant-table-expanded-row.ant-table-expanded-row-level-1
  > .ant-table-cell {
  border-top: 0px solid #ddd;
  border-bottom: 0px solid #ddd;
  background: transparent;
}

.component-view-table .ant-table-container .ant-table-header thead tr th {
  vertical-align: bottom;
}

.component-view-table
  :where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background-color: transparent;
  color: #adb5bd;
  border-bottom: 1px solid rgb(43, 49, 62) !important;
}

.component-view-table
  :where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper
  .ant-table-thead
  th.ant-table-column-has-sorters:hover {
  background-color: transparent;
}

.component-view-table
  :where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-1y5cs9s).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent;
}

.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-head.ant-table-sticky-holder,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell {
  background: transparent;
}

.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-head {
  border-radius: 0;
}

.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  td,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell {
  background: transparent;
  border-bottom: 1px solid #2b313e;
  color: #adb5bd;
}

.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row:hover
  .ant-table-cell {
  background: #363d4c;
  color: #adb5bd;
}

.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:before {
  background-color: transparent !important;
}

.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger {
  color: #adb5bd;
  opacity: 0.3;
  display: none;
}

.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:hover
  .ant-table-column-sorter
  .ant-table-column-sorter-down,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:hover
  .ant-table-column-sorter
  .ant-table-column-sorter-up,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:hover
  .ant-dropdown-trigger.ant-table-filter-trigger,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th:hover
  .ant-dropdown-trigger.ant-table-filter-trigger,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down.active,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up.active,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active {
  display: flex;
}

.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down.active,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up.active,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active {
  color: #8ebbff;
  opacity: 1;
}

/* .custom-table.component-view-table.ant-table-wrapper .ant-table tbody.ant-table-body tr td {
  background: transparent;
  padding: .5rem 1.2rem;
  color: #adb5bd;
  vertical-align: middle;
  border-bottom: 1px solid #2b313e;
} */

.component-view-table.ant-table-wrapper
  .ant-table
  tbody.ant-table-body
  tr.ant-table-expanded-row
  td {
  padding: 16px !important;
}

.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row:last-child
  .ant-table-cell,
.component-view-table.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  tbody.ant-table-body
  tr.ant-table-row:last-child
  td {
  border-bottom: none;
}

/*Nested component table styles start*/
/* .component-view-table.nested-table .ant-table-expanded-row.ant-table-expanded-row-level-1>.ant-table-cell {
  border-top: 0px solid #ddd;
  border-bottom: 0px solid #ddd;
  background: transparent;
} */

.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-head.ant-table-sticky-holder,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell {
  background: transparent;
}

.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-head {
  border-radius: 0;
}

.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  td,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell {
  background: transparent;
  border-bottom: 1px solid #2b313e;
  color: #adb5bd;
}

.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row:hover
  .ant-table-cell {
  background: #363d4c;
  color: #adb5bd;
}

.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:before {
  background-color: transparent;
}

.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger {
  color: #adb5bd;
  opacity: 0.3;
  display: none;
}

.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:hover
  .ant-table-column-sorter
  .ant-table-column-sorter-down,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:hover
  .ant-table-column-sorter
  .ant-table-column-sorter-up,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th:hover
  .ant-dropdown-trigger.ant-table-filter-trigger,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th:hover
  .ant-dropdown-trigger.ant-table-filter-trigger,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down.active,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up.active,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active {
  display: flex;
}

.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-down.active,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-table-column-sorter
  .ant-table-column-sorter-up.active,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead
  tr
  th
  .ant-dropdown-trigger.ant-table-filter-trigger.active {
  color: #8ebbff;
  opacity: 1;
}

.table-no-select-all
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  tr
  th {
  vertical-align: top;
}

/* .custom-table.ant-table-wrapper.nested-table .ant-table tbody.ant-table-body tr td {
  background: transparent;
  padding: .5rem 1.2rem;
  color: #adb5bd;
  vertical-align: middle;
  border-bottom: 1px solid #2b313e;
} */

.ant-table-wrapper.nested-table
  .ant-table
  tbody.ant-table-body
  tr.ant-table-expanded-row
  td {
  padding: 16px !important;
}

.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row:last-child
  .ant-table-cell,
.ant-table-wrapper.nested-table
  .ant-table
  .ant-table-container
  .ant-table-content
  tbody.ant-table-body
  tr.ant-table-row:last-child
  td {
  border-bottom: none;
}

/*Nested component table styles end-------------------------------*/

.custom-table .ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: transparent;
  border-color: rgb(43, 49, 62);
  color: #ffffff;
}

.custom-table .ant-pagination .ant-pagination-item {
  /* font-weight: 600; */
  background-color: transparent;
  /* border-color: rgb(43, 49, 62); */
  color: #adb5bd;
}

.custom-table
  :where(.css-dev-only-do-not-override-1y5cs9s).ant-pagination
  .ant-pagination-prev
  button,
.custom-table
  :where(.css-dev-only-do-not-override-1y5cs9s).ant-pagination
  .ant-pagination-next
  button {
  color: #adb5bd;
}

.custom-table
  :where(.css-dev-only-do-not-override-1y5cs9s).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: transparent;
  border-color: rgb(43, 49, 62);
  color: #adb5bd;
}

.custom-table
  :where(
    .css-dev-only-do-not-override-1y5cs9s
  ).ant-select-single.ant-select-open
  .ant-select-selection-item,
.custom-table
  :where(.css-dev-only-do-not-override-1y5cs9s).ant-select
  .ant-select-arrow {
  color: #adb5bd;
}

.custom-table
  :where(.css-dev-only-do-not-override-1y5cs9s).ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.custom-table
  :where(.css-dev-only-do-not-override-1y5cs9s).ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #adb5bd;
}

.site-tree-search-value {
  color: #f50;
}

.search-text-dependency-tree .ant-input-group-addon .ant-input-search-button {
  height: 35px;
}

:where(.css-dev-only-do-not-override-1y5cs9s).ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
:where(
    .css-dev-only-do-not-override-1y5cs9s
  ).ant-pagination.searchPagePagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #adb5bd;
}

.radio-success .ant-radio-checked .ant-radio-inner {
  background-color: #198754;
  border-color: #198754;
}

.radio-danger .ant-radio-checked .ant-radio-inner {
  background-color: #ce3443;
  border-color: #ce3443;
}

.critical {
  background: linear-gradient(130deg, #ff7e00, #ffffff, #5cff00);
  background-size: 200% 200%;
  /* stroke: #ff0000; */

  /* -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite; */
}

/* .svg-circle-shadow{
  -webkit-filter: drop-shadow( 10px 10px 10px rgba(0, 0, 0, .7));
  filter: drop-shadow( 10px 10px 10px rgba(0, 0, 0, .7));
} */
/* CustomButton.css */

.custom-button {
  background-color: #ffc107 !important;
  /* Default background color */
  border-color: #ffc107 !important;
  /* Default border color */
  color: #000 !important;
  /* Default text color */
}

.custom-button:hover {
  background-color: #ffca2c !important;
  /* New background color on hover */
  border-color: #ffc720 !important;
  color: #000 !important;
}

/* Focus state */
.custom-button:focus {
  background-color: #ffca2c !important;
  /* New background color on focus */
  border-color: #ffc720 !important;
  /* New border color on focus */
}

/* Active state (when clicked) */
.custom-button:active {
  background-color: #ffcd39 !important;
  /* New background color on click */
  border-color: #ffc720 !important;
  /* New border color on click */
}

.owl-carousel {
  background-color: #1f242e;
}

.owl-carousel .item {
  border: none;
  outline: none;
}

.owl-carousel .owl-stage {
  transition: transform 1s ease;
  /* Adjust the duration and timing function as needed */
}

div.slideMe {
  transition: all 0.3s ease;
}

/* section div.container h1 {
  margin: 15px 0 0 0;
}
section div.container h3 {
  margin: 0 0 25px 0;
} */
/* @media (max-width: 992px) {
  section {
    padding: 0 20px 0 20px;
  }
} */
/* section.slide-option {
  margin: 0 0 50px 0;
} */
/* section.slide-option .no-marg {
  margin: 0 0 0 0;
} */
div.highway-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  /* height: 150px; */
}

div.highway-slider div.highway-barrier {
  overflow: hidden;
  position: relative;
}

div.highway-slider ul.highway-lane {
  display: flex;
  height: 100%;
}

div.highway-slider ul.highway-lane li.highway-car {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #343434;
}

@-webkit-keyframes translatestf {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-500%);
  }
}

@keyframes translatestf {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-500%);
  }
}

#stffull div.highway-barrier ul.highway-lane {
  width: 500%;
}

#stffull div.highway-barrier ul.highway-lane li.highway-car {
  -webkit-animation: translatestf 30s linear infinite;
  animation: translatestf 30s linear infinite;
}

/* #stffull div.highway-barrier ul.highway-lane li.highway-car h4 {
  font-size: 28px;
} */
@-webkit-keyframes translateinfinite {
  100% {
    transform: translateX(calc(58% * 12));
  }
}

@keyframes translateinfinite {
  100% {
    transform: translateX(calc(58% * 12));
  }
}

#infinite div.highway-barrier {
  background: #fff;
  box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.3);
}

#infinite div.highway-barrier::before,
#infinite div.highway-barrier::after {
  content: " ";
  position: absolute;
  z-index: 9;
  width: 58%;
  height: 100%;
}

/* #infinite div.highway-barrier::before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
#infinite div.highway-barrier::after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
} */
#infinite div.highway-barrier ul.highway-lane {
  width: calc(58% * 24);
}

#infinite div.highway-barrier ul.highway-lane li.highway-car {
  /* width: 180px; */
  -webkit-animation: translateinfinite 25s linear infinite;
  animation: translateinfinite 25s linear infinite;
}

/* #infinite div.highway-barrier ul.highway-lane li.highway-car span.fab {
  font-size: 65px;
} */

.bootstrap-textbox-spinner {
  position: absolute;
  top: 7px;
  left: 10px;
}

.text-spinner-active.form-control {
  padding-left: 45px;
}

.api-key-other-controls {
  position: absolute;
  top: 10px;
  right: 15px;
}

.api-key-input {
  padding-right: 70px !important;
}

.api-key-control-icon.active {
  color: #bebebe;
}

.api-key-control-icon:hover {
  color: #8b8b8b;
}

:where(.css-dev-only-do-not-override-1y5cs9s).ant-checkbox-disabled
  .ant-checkbox-inner {
  background-color: #ffffff !important;
  opacity: 0.6;
}

.settings-dropdown ul {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

.gradient-text {
  background: -webkit-linear-gradient(
    140.6deg,
    rgb(217, 35, 189) 35.2%,
    rgb(80, 35, 217) 65.1%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.timelineCard:hover {
  background-color: #3a3d3f !important;
}

.timelineCard.active {
  background-color: #4b5055;
}

.timelineCard.disabled {
  opacity: 0.7;
}

.timelineCard.disabled:hover {
  background-color: #212529 !important;
}

.ant-steps.ant-steps-vertical.scan-history-timeline
  .ant-steps-item
  .ant-steps-item-container
  .ant-steps-item-tail {
  top: 18px;
}

.ant-steps.ant-steps-vertical.scan-history-timeline
  .ant-steps-item
  .ant-steps-item-container
  .ant-steps-item-icon {
  margin-top: 18px;
}

.modified-bootstrap-table.custom-table thead tr th {
  background-color: transparent;
  padding: 0.5rem 0.2rem;
}

.modified-bootstrap-table.custom-table tbody tr td {
  background-color: transparent;
  padding: 0.5rem 0.2rem;
}

.source-control-card {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.source-control-card.disabled {
  cursor: default;
  opacity: 0.6;
}

.source-control-card.disabled:hover {
  cursor: default;
  background-color: #1f242e;
}

.source-control-card:hover {
  background-color: #252b36;
}

.source-control-card h5 {
  font-weight: 300;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.source-control-image-container {
  background-color: rgb(37, 43, 54);
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-notification {
  z-index: 9999999 !important;
}

.rd3t-svg .rd3t-g .rd3t-link {
  /* fill: "grey"; */
  stroke: grey !important;
}

.always-on-switch.ant-switch {
  background: #3c7cdc;
}

.custom-table.myTable tbody tr td {
  padding: 0.5rem 0.5rem;
}

.hover-card {
  transition: all 0.8s ease-in-out;
}

.card.hover-card {
  background-color: rgba(214, 112, 67, 0.35);
  border-bottom: 4px solid rgb(218, 74, 71);
  position: relative;
  transition: background-color 0.3s ease;
}

.card.hover-card:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: rgb(218, 74, 71);
  z-index: -1;
  transition: height 0.3s ease;
}

.card.hover-card:hover:before {
  height: 100%;
}

.custom-backdrop-dast {
  z-index: 1000010 !important;
}
.lower-hover-opacity:hover {
  opacity: 0.8;
}

.lds-dual-ring {
  /* change color here */
  color: #dc3545;
}
.lds-dual-ring,
.lds-dual-ring:after {
  box-sizing: border-box;
}
.lds-dual-ring {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.trace-modal-wrap .ant-modal-header,
.trace-modal-wrap .ant-modal-content {
  background: #1f242e;
}

.ant-dropdown {
  z-index: 999999;
}

.fixedScrollCustomTable.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell,
.fixedScrollCustomTable.ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell {
  background: #1f242e;
}

.component-version-expand-row > .ant-table-cell {
  padding: 0 !important;
}

.sidebar-nav li a.nav-link.link-disabled {
  opacity: 0.4;
}

.sidebar-nav li a.nav-link .feature-locking-icon {
  font-size: 14px;
}

.product-plan-text {
  color: #494c52;
  font-weight: 100;
  font-size: 12px;
}

.free-plan {
  color: #058b40;
}

.non-free-plan {
  color: #00e0e9;
}

.cursor-pointer {
  cursor: pointer;
}

/* Only style the checkbox with the class "layer-checkbox" */
.layer-checkbox .ant-checkbox-inner {
  border-color: #ffffff !important; /* White border */
  background-color: #303030 !important; /* Grey inside */
}

.layer-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #303030 !important; /* Keep the inside grey when checked */
  border-color: #ffffff !important; /* Keep the border white when checked */
}

.layer-checkbox .ant-checkbox-inner::after {
  border-color: #ffffff !important; /* White checkmark */
}

.message-popover .ant-popover-content .ant-popover-inner {
  padding: 0;
}

.message-popover
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  padding: 12px;
}

.message-popover .ant-popover-content .ant-popover-inner .ant-popover-title {
  margin: 0;
  padding: 12px;
  background: #303030;
}

.message-popover .ant-popover-arrow::before {
  background: #303030;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}
.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.fs-13 {
  font-size: 13px;
}
.fs-22 {
  font-size: 22px;
}
.fs-12 {
  font-size: 12px;
}
.fs-10 {
  font-size: 10px;
  /* border-radius: 50px; */
}
.round {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}
.flat-tag {
  margin-right: 2px;
}
.overloap {
  margin-right: 4px;
  cursor: pointer;
  /* border: 2px solid transparent; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.zInd-4 {
  z-index: 4;
}
.zInd-3 {
  z-index: 3;
}
.zInd-2 {
  z-index: 2;
}
.zInd-1 {
  z-index: 1;
}
.overloap:hover {
  position: relative;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  /* border: 2px solid #000; */
  z-index: 15;
}

/* --- Loader Scanner START--- */
.loader-moving-line {
  width: 100%;
  margin-top: -2px;
  height: 2px;
  background-color: transparent;
  position: relative;
  overflow: hidden;
}

.loader-moving-line:after {
  content: "";
  position: absolute;
  border-radius: 8px;
  background-color: #0e6ecf;
  width: 20%;
  height: 100%;
  animation: slide 4s ease-in-out infinite;
}

@keyframes slide {
  0%,
  100% {
    left: -18%;
  }
  50% {
    left: 98%;
  }
}
/* --- Loader Scanner END--- */



/* @keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.7;
  }
} */

.vul-drawer{
 z-index: 1070;
}

.sidebar-section {
  margin-bottom: 1rem;
  width: 100%;
}

.section-header {
  cursor: pointer;
  padding: 0.5rem 0;
  user-select: none;
}

/* .menu-title { */
  /* color: #6c757d; */
  /* font-size: 0.75rem; */
  /* text-transform: uppercase; */
  /* letter-spacing: 0.05em; */
  /* font-weight: 600; */
/* } */

.section-arrow {
  transition: transform 0.5s ease;
}

.section-arrow.rotated {
  transform: rotate(180deg);
}

.sidebar-section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.sidebar-section-content.open {
  max-height: 1000px; /* Adjust this value based on your content */
}

/* Optional: Add hover effect to section headers */
.section-header:hover .menu-title {
  color: #ffffff;
}

.vul-popover .ant-popover-content .ant-popover-inner .ant-popover-title {
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 13px;
}