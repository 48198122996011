@media (min-width: 992px) {
  .gradient-banner {
    height: auto;
    position: relative;
  }

  .center-component {
    padding: 30px 0 0;
  }

  .top-title {
    /* font-size: calc(1.275rem + .3vw); */
    font-size: 2.53rem;
    font-weight: 100 !important;

    line-height: 1.4;
  }

  .search-title {
    font-size: 1.5rem;
    line-height: 50px;
  }

  .DigitRoll__Out {
    display: inline-block !important;
  }

  .widget {
    /* position: absolute; */
    /* right: 20px; */
    /* bottom: 0; */
    /* transform: translateY(-100%); */
    /* width: 300px; */
    /* padding: 12px; */
    border-radius: 5px;
    /* border: 1px solid #d3e3ef; */
    margin-top: 0;
    background: none;
  }

  .tools-title {
    font-size: 1.2rem;
  }

  .tool-group-text {
    font-size: 0.95rem;
  }

  .detailsSearchPagePagination {
    text-align: left;
  }

  .detailsSearchPagePagination .ant-pagination-total-text {
    display: inline-block;
  }
}

/* @media (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
} */
