.login-logo-container {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #2b313e;
  display: inline-block;
  box-shadow: 0 0 8px #2b313e;
  background-color: #1f242e;
}

.login-logo-container img {
  width: 60px;
}

.welcome-text {
  font-size: 25px;
  font-weight: 500;
}

.welcome-subtext {
  font-size: 13px;
}

.card.custom-card.login-card {
  background-color: #1f242e;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 -30px;
  border-radius: 20px;
}

.or-text-container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #2b313e;
  margin: 0 auto;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.or-text-container {
  position: relative;
  z-index: 1;
}

.or-text {
  background-color: #1f242e;
  z-index: 2;
  display: inline-block;
  position: relative;
  padding: 0 12px;
}

.btn.btn-shadow {
  /* box-shadow: 0 0.5rem 1rem #2b313e; */
  border: 1px solid #2b313e;
}

.btn.btn-shadow.btn-custom:hover {
  background-color: #2b313e;
}

.btn.btn-primary-gradient {
  color: #fff;
  height: auto;
  background-color: #438eff;
  border-color: #438eff;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}

.btn.btn-primary-gradient:hover {
  color: #fff !important;
  background: #3979d9 !important;
  border-color: #3672cc !important;
}

.btn.btn.btn-primary-gradient:active {
  color: #fff;
  background-color: #3672cc;
  border-color: #326bbf;
}

.error-alert .anticon-close-circle {
  margin-top: 5px;
}
