#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.toggle-sidebar {
  cursor: pointer;
  background-color: rgb(52, 58, 64);
}

.toggle-sidebar:hover {
  background-color: rgb(69, 75, 80);
}

.file-explorer-tree {
  background-color: transparent !important;
}

/* .cm-icon-gutter {
  width: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cm-gutter-icon {
  cursor: pointer;
} */

.cm-custom-gutter {
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning-icon {
  cursor: pointer;
}

.ant-popover {
  z-index: 99999 !important;
}

.ant-tooltip,
.tooltip.bs-tooltip-top {
  z-index: 999999 !important;
}
:where(.css-dev-only-do-not-override-m4nmh3).ant-modal-root .ant-modal-mask {
  z-index: 99999 !important;
}
:where(.css-dev-only-do-not-override-m4nmh3).ant-modal-root .ant-modal-wrap {
  z-index: 111111 !important;
}

.ant-drawer .ant-drawer-bottom {
  z-index: 10001;
}

.code-container {
  height: calc(100vh - 140px);
  position: relative;
  overflow: hidden;
}

.modal-fullscreen .modal-body {
  overflow: hidden !important;
}

.ant-drawer-body {
  padding: 8px 24px !important;
  position: relative;
}

.error-code-div-highlight {
  border-left: 4px solid #fba797 !important;
  /* border: 1px solid #ea868f; */
  background: rgba(160, 128, 100, 0.08);
  border-radius: 5px;
}

.success-code-div-highlight {
  border-left: 4px solid #88ff88 !important;
  /* border: 1px solid #ea868f; */
  background: rgba(100, 160, 128, 0.08);
  border-radius: 5px;
}

.closeIcon-drawer {
  position: absolute;
  right: 30px;
  top: 14px;
  background: rgb(1, 1, 1, 46%);
  border-radius: 50px;
  padding: 5px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10001;
}
.closeIcon-drawer:hover {
  background: rgb(95, 95, 95, 46%);
}

.cm-gutterElement.cm-activeLineGutter {
  z-index: 100000000009;
}

.app-container {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: row;
  /* height: 100vh; */
}

.app-sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 200px;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  z-index: 2;
}

.app-frame {
  /* flex: 1; */
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 100vh; */
  max-height: 100%;
  border-radius: 0px 10px 10px 0px;
  z-index: 1;
  flex-grow: 1;
  overflow-x: auto;
}

.app-sidebar .app-sidebar-content {
  flex: 1;
}

.app-sidebar .app-sidebar-resizer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 6px;
  justify-self: flex-end;
  cursor: col-resize;
  resize: horizontal;
  background: #2b2b2b;
  border-radius: 5px;
}

.app-sidebar .app-sidebar-resizer:hover {
  width: 3px;
  background: #c1c3c5b4;
}
