/* 
.demo {
    width: 30px;
    height: 32px;
    border-radius: 100%;
  }
  
  .loader-circle {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .loader-circle .inner {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 5px solid rgba(30, 70, 77, 0.7);
    border-right: none;
    border-top: none;
    background-clip: padding;
    box-shadow: inset 0px 0px 10px rgba(0, 255, 170, 0.15);
  }
  
  @-webkit-keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .loader-circle:nth-of-type(0) {
    transform: rotate(0deg);
  }
  .loader-circle:nth-of-type(0) .inner {
    -webkit-animation: spin 2s infinite linear;
            animation: spin 2s infinite linear;
  }
  
  .loader-circle:nth-of-type(1) {
    transform: rotate(70deg);
  }
  .loader-circle:nth-of-type(1) .inner {
    -webkit-animation: spin 2s infinite linear;
            animation: spin 2s infinite linear;
  }
  
  .loader-circle:nth-of-type(2) {
    transform: rotate(140deg);
  }
  .loader-circle:nth-of-type(2) .inner {
    -webkit-animation: spin 2s infinite linear;
            animation: spin 2s infinite linear;
  }
  
  .demo {
    -webkit-animation: spin 5s infinite linear;
            animation: spin 5s infinite linear;
  } */


@keyframes scale {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2);
  }

  78%,
  100% {
    opacity: 0;
  }
}

.anim-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anim-circle:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
}

.circle-1 {
  background-color: #1e464d;
}

.circle-1:before {
  background-color: #49b8e5;
  animation-delay: 200ms;
}