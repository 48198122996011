@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Template Name: sotcox - IT solutions & services company HTML5 Template
    Template URI: https://themealpha.dev/
    Description: IT solutions & services company HTML5 Template
    Author: amdadulj
    Author URI: https://themeforest.net/user/amdadulj
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

	* Theme default CSS

-----------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }

  100% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  50% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
  }

  50% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
  }

  50% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
  }
}

@-webkit-keyframes down {
  0% {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }

  50% {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@keyframes down {
  0% {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }

  50% {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@-webkit-keyframes right {
  0% {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
  }

  50% {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
  }
}

@keyframes right {
  0% {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
  }

  50% {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
  }
}

@keyframes dotPulse {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@-webkit-keyframes dotPulse {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@-webkit-keyframes sotcox-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sotcox-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes sotcox-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes sotcox-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes sotcox-shake {
  0% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes sotcox-shake {
  0% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes shapeDownLeft {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes shapeTopLeft {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}

/* reset css start */
body {
  font-family: "Arimo", sans-serif;
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: 1.5;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

*:focus {
  outline: none;
}

button {
  border: none;
  font-family: "Arimo", sans-serif;
  background: transparent;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

button:focus {
  outline: none;
}

span {
  font-family: "Arimo", sans-serif;
}
a {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: "Arimo", sans-serif;
  text-decoration: none;
  color: #0e6ecf;
  /* transition: all 1s ease-in-out; */
}

a:hover {
  text-decoration: underline;
  text-decoration-color: #0e6ecf;
  /* color: #1B2E59; */
  /* color: #a5c9ff; */
  color: #0e6ecf;
}

table {
  width: 100%;
}

/* p,
li,
span {
  color: #727885;
  margin-bottom: 0;
} */

.muted-theme-text{
  color: #727885;
  margin-bottom: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

input,
textarea {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

/* reset css end */
/* global css start */
.nice-select {
  background-color: #ffffff;
  height: 60px;
  line-height: 60px;
  min-height: 60px;
  padding: 0 30px;
  border-radius: 0px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 8px 18px rgba(228, 228, 228, 0.21);
  border-color: #E6EEFB;
}

.nice-select::after {
  border-width: 1px;
  right: 25px;
}

.nice-select span {
  color: #acafb1;
}

.nice-select .list {
  border-radius: 0px;
  max-height: 245px;
  overflow-y: scroll;
  z-index: 8;
  width: 100%;
}

.nice-select .list li {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 0;
}

.nice-select .list li:focus,
.nice-select .list li:hover {
  background: #f4f4f4;
}

.nice-select .list .selected.focus {
  background: #f4f4f4;
}

.nice-select .list .option {
  color: #1B2E59;
}

.nice-select .list::-webkit-scrollbar {
  width: 2px;
}

/* global css end */
.fix {
  overflow: hidden;
}

.bg_img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.dark-bg {
  background-color: #171719;
}

.theme-light-bg {
  background: #E6EEFB;
}

.theme-light-bg .sotcox-section-heading__style-2 .sub-heading {
  background: #d3e2fa;
}

.theme-dark-bg {
  background: #171719;
}

.theme-dark-bg .sotcox-section-heading__style-2 .sub-heading {
  background: #162830;
}

.white {
  color: #ffffff;
}

.f-right {
  float: right;
}

.border-effect a,
.border-effect span.border-effect {
  position: relative;
  display: inline;
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
  background-image: -webkit-linear-gradient(left, currentColor 0%, currentColor 100%);
  background-image: -o-linear-gradient(left, currentColor 0%, currentColor 100%);
  background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
  background-size: 0px 2px;
  background-position: 0px 95%;
  -webkit-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  -o-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  padding: 0.7% 0px;
  background-repeat: no-repeat;
  color: inherit;
}

.border-effect a:hover,
.border-effect span.border-effect:hover {
  background-size: 100% 2px;
}

span.border-effect {
  position: relative;
  display: inline;
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
  background-image: -webkit-linear-gradient(left, currentColor 0%, currentColor 100%);
  background-image: -o-linear-gradient(left, currentColor 0%, currentColor 100%);
  background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
  background-size: 0px 2px;
  background-position: 0px 95%;
  -webkit-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  -o-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  padding: 0.7% 0px;
  background-repeat: no-repeat;
  color: inherit;
}

span.border-effect:hover {
  background-size: 100% 2px;
}

.widget .wp-block-search__label,
.widget .wp-block-group h2,
.sotcox-title-uppercase {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
}

.list-unstyled {
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}

.sotcox-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
  z-index: 10;
}


.theme-bg {
  background: #266FF2;
}

.sotcox-onhover-zoom .thumb {
  overflow: hidden;
}

.sotcox-onhover-zoom .thumb img {
  width: 100%;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.sotcox-onhover-zoom:hover .thumb img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.sotcox-container__fluid {
  max-width: 1785px;
}

@media (max-width: 1599px) {
  .sotcox-container__fluid {
    max-width: 1400px;
  }
}

@media (max-width: 1199px) {
  .sotcox-container__fluid {
    max-width: 960px;
  }
}

.sotcox-dots {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 25px;
}

.sotcox-dots span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #266FF2;
  margin: 0px 2.5px;
  display: inline-block;
}

.sotcox-section-heading {
  position: relative;
  z-index: 2;
}

.sotcox-section-heading .sub-heading {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #266FF2;
  letter-spacing: 0.02em;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  line-height: 110%;
}

.sotcox-section-heading .sub-heading::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  height: 2px;
  background: #266FF2;
  top: auto;
  bottom: -2px;
}

.sotcox-section-heading .heading {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.01em;
}

@media (max-width: 767px) {
  .sotcox-section-heading .heading {
    font-size: 32px;
    line-height: 44px;
  }

  .sotcox-section-heading .heading br {
    display: none;
  }
}

.sotcox-section-heading__light p,
.sotcox-section-heading__light .heading,
.sotcox-section-heading__light .sub-heading {
  color: #ffffff;
}

.sotcox-section-heading__style-2 .sub-heading {
  background: #E6EEFB;
  border-radius: 40px;
  padding: 8px 21px;
  margin-bottom: 15px;
}

.sotcox-section-heading__style-2 .sub-heading::after {
  display: none;
}

.sotcox-input-field input,
.sotcox-input-field textarea {
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
}

/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top Negative --*/
.mt-none-5 {
  margin-top: -5px;
}

.mt-none-10 {
  margin-top: -10px;
}

.mt-none-15 {
  margin-top: -15px;
}

.mt-none-20 {
  margin-top: -20px;
}

.mt-none-25 {
  margin-top: -25px;
}

.mt-none-30 {
  margin-top: -30px;
}

.mt-none-35 {
  margin-top: -35px;
}

.mt-none-40 {
  margin-top: -40px;
}

.mt-none-45 {
  margin-top: -45px;
}

.mt-none-50 {
  margin-top: -50px;
}

.mt-none-55 {
  margin-top: -55px;
}

.mt-none-60 {
  margin-top: -60px;
}

.mt-none-65 {
  margin-top: -65px;
}

.mt-none-70 {
  margin-top: -70px;
}

.mt-none-75 {
  margin-top: -75px;
}

.mt-none-80 {
  margin-top: -80px;
}

.mt-none-85 {
  margin-top: -85px;
}

.mt-none-90 {
  margin-top: -90px;
}

.mt-none-95 {
  margin-top: -95px;
}

.mt-none-100 {
  margin-top: -100px;
}

/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

/* typography css start */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #1B2E59;
  color: #ffffff;
  margin: 0;
  line-height: 1.4;
  /* font-family: "Archivo", sans-serif; */
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

/* typography css end */
/*--
    - Overlay
------------------------------------------*/
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}

[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*-- Overlay Color --*/
[data-overlay=light]::before {
  background-color: #ffffff;
}

[data-overlay=dark]::before {
  background-color: #1B2E59;
}

/*-- Overlay Opacity --*/
[data-opacity="1"]::before {
  opacity: 0.1;
}

[data-opacity="2"]::before {
  opacity: 0.2;
}

[data-opacity="3"]::before {
  opacity: 0.3;
}

[data-opacity="4"]::before {
  opacity: 0.4;
}

[data-opacity="5"]::before {
  opacity: 0.5;
}

[data-opacity="6"]::before {
  opacity: 0.6;
}

[data-opacity="65"]::before {
  opacity: 0.65;
}

[data-opacity="7"]::before {
  opacity: 0.7;
}

[data-opacity="8"]::before {
  opacity: 0.8;
}

[data-opacity="9"]::before {
  opacity: 0.9;
}

.sotcox-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 0%;
  position: fixed;
  top: 0;
  z-index: 9;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
  z-index: 10;
  left: auto;
  right: 0;
}


/* HEADER CSS START*/
.sotcox-logo-menu-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: nowrap;
}

.sotcox-logo-menu-wrapper .sotcox-logo {
  margin-right: 50px;
  max-width: 185px;
}

.sotcox-logo-menu-wrapper .sotcox-logo__sticky {
  display: none;
}

@media (max-width: 991px) {
  .sotcox-logo-menu-wrapper .sotcox-logo {
    margin-right: 0px;
  }
}

@media (max-width: 1199px) {
  .sotcox-header {
    padding: 20px 0px;
  }
}

.sotcox-header .right-element {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.sotcox-header .right-element li {
  cursor: pointer;
  position: relative;
}

.sotcox-header .right-element li:not(:last-child) {
  margin-right: 35px;
}

.sotcox-header .sotcox-btn {
  padding: 10px 42px;
}

.sotcox-header .sotcox-btn.has-span {
  margin-bottom: 0px;
}

.sotcox-header.uk-active {
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  background: #ffffff;
  padding: 12px 0px;
  z-index: 3;
}

@media (max-width: 1199px) {
  .sotcox-header.uk-active {
    padding: 20px 0px;
  }
}

.sotcox-header.uk-active .sotcox-menu-wrapper {
  background: transparent;
}

.sotcox-header.uk-active ul li a {
  color: #1B2E59;
}

.sotcox-header.uk-active ul li a:focus {
  color: #09C3FF;
}

.sotcox-header.uk-active .sotcox-logo__default {
  display: none;
}

.sotcox-header.uk-active .sotcox-logo__sticky {
  display: inline-block;
}

.sotcox-header.uk-active .right-element li svg path {
  fill: #1B2E59;
}

.sotcox-header__style-2 {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
}

.sotcox-header__style-2 .right-element li svg path {
  fill: #ffffff;
}

.sideinfo-trigger {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #09C3FF;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.sideinfo-trigger span {
  translate: 12px 0px;
  width: 25px;
  height: 2px;
  border-radius: 20px;
  margin: 2.5px 0px;
  background: #ffffff;
}

.sideinfo-trigger span:nth-child(2) {
  width: 20px;
}

.sideinfo-trigger span:nth-child(3) {
  width: 15px;
}

/* HEADER CSS END*/
/* MENU CSS START */
.sotcox-menu-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  /* background: #E6EEFB; */
  border-radius: 10px;
  width: 100%;
  padding: 10px;
}

/* @media (max-width: 1199px) {
  .sotcox-menu-wrapper {
    display: none;
  }
} */

.sotcox-menu-wrapper__style-2 {
  background: transparent;
}

.sotcox-main-menu {
  position: relative;
  padding-right: 175px;
}

@media (max-width: 1599px) {
  .sotcox-main-menu {
    padding-right: 35px;
  }
}

.sotcox-main-menu ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sotcox-main-menu ul li {
  position: relative;
}

.sotcox-main-menu ul li:not(:last-child) {
  margin-right: 65px;
}

@media (max-width: 1599px) {
  .sotcox-main-menu ul li:not(:last-child) {
    margin-right: 45px;
  }
}

.sotcox-main-menu ul li a {
  display: block;
  text-decoration: none;
  font-size: 14px;
  color: #1B2E59;
  padding: 10px 0px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}

.sotcox-main-menu ul li a:focus {
  color: #09C3FF;
}

.sotcox-main-menu ul li a::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  width: 9px;
  height: 9px;
  background: #09C3FF;
  bottom: 20px;
  left: -15px;
  border-radius: 50%;
  translate: 0px -50%;
  top: 50%;
  -webkit-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  opacity: 0.3;
}

.sotcox-main-menu ul li:hover>a {
  color: #09C3FF;
}

.sotcox-main-menu ul li:hover>a::before {
  opacity: 1;
}

.sotcox-main-menu ul li:hover a {
  color: #09C3FF;
}

.sotcox-main-menu ul li:hover>.sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(100%);
  -ms-transform: scaleY(100%);
  transform: scaleY(100%);
}

.sotcox-main-menu ul li .sub-menu {
  left: 0%;
  position: absolute;
  min-width: 250px;
  top: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 20px 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 3;
  text-align: left;
  transform-origin: 50% 0;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.sotcox-main-menu ul li .sub-menu li {
  width: 100%;
  margin-right: 0px;
}

.sotcox-main-menu ul li .sub-menu li::after {
  display: none;
}

.sotcox-main-menu ul li .sub-menu li a {
  color: #1B2E59;
  padding: 3px 0px;
}

.sotcox-main-menu ul li .sub-menu li a::before,
.sotcox-main-menu ul li .sub-menu li a::after {
  display: none;
}

.sotcox-main-menu ul li .sub-menu li:hover>a {
  color: #09C3FF;
  padding-left: 5px;
}

.sotcox-main-menu ul li .sub-menu .sub-menu {
  left: 100%;
  top: 0;
}

/* .sotcox-main-menu ul .menu-item-has-children > a::after {
  font-size: 12px;
  content: "";
  transform: translateY(1px);
  display: inline-block;
  padding-left: 7px;
  font-family: "Font Awesome 5 Pro";
  transition: all 0.4s linear;
} */
.sotcox-main-menu ul .sotcox-megamenu {
  position: static;
}

.sotcox-main-menu ul .sotcox-megamenu .sub-menu {
  padding: 50px;
  width: 100%;
  padding-bottom: 35px;
}

.sotcox-main-menu__style-2 ul li a {
  color: #ffffff;
}

.mean-container .mean-bar {
  padding-left: 10px;
  padding-top: 30px;
}

.mean-container .mean-bar a {
  font-weight: 700 !important;
  font-size: 14px;
}

@media (max-width: 475px) {
  .mean-container .mean-bar a {
    padding-left: 5% !important;
  }
}

.mean-container .mean-bar a.mean-expand {
  right: 40px !important;
}

@media (max-width: 475px) {
  .mean-container .mean-bar a.mean-expand {
    right: 20px !important;
  }
}

/* MENU CSS END */
/* preloader css start */
#sotcox-page-loading {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: rgba(71, 71, 71, 0.77);
  -webkit-transition: all 300ms linear 0ms;
  -o-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.sotcox-spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 70px;
  text-align: center;
}

.sotcox-spinner>div {
  width: 18px;
  height: 18px;
  background: #fff;
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sotcox-bouncedelay 1.4s infinite ease-in-out both;
  animation: sotcox-bouncedelay 1.4s infinite ease-in-out both;
}

.sotcox-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sotcox-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

/* preloader css end */
/* BUTTON CSS START */
.sotcox-btn {
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 42px;
  font-weight: 700;
  border-radius: 5px;
  display: inline-block;
  background: #266FF2;
  letter-spacing: 0.1em;
  position: relative;
  -webkit-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
  overflow: hidden;
  z-index: 1;
}

.sotcox-btn__small {
  padding: 10px 36px;
}

.sotcox-btn__round {
  border-radius: 40px;
}

.sotcox-btn__borderd {
  border: 1px solid #E6EEFB;
  border-radius: 40px;
  color: #1B2E59;
  background: #ffffff;
  text-transform: capitalize;
  font-size: 16px;
  padding: 14px 42px;
  letter-spacing: 0px;
}

.sotcox-btn span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #09C3FF;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.sotcox-btn.has-span {
  margin-bottom: -10px;
}

.sotcox-btn:hover {
  color: #ffffff;
}

.sotcox-btn:hover span {
  width: 225%;
  height: 562.5px;
}

.sotcox-inline-btn {
  position: relative;
  overflow: hidden;
  width: max-content;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 700;
  color: #266FF2;
}

.sotcox-inline-btn .icon {
  position: relative;
  margin-left: 55px;
}

.sotcox-inline-btn .nav-arrow {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.sotcox-inline-btn .nav-arrow:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-10px) translateY(0);
}

.sotcox-inline-btn .nav-arrow:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}

.sotcox-inline-btn:hover .nav-arrow:first-child {
  opacity: 1;
  transform: translateZ(0);
}

.sotcox-inline-btn:hover .nav-arrow:last-child {
  opacity: 0;
  transform: translateX(20px) translateY(0);
}

.sotcox-video-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.sotcox-video-wrapper .sotcox-video-btn {
  margin-right: 15px;
}

.sotcox-video-wrapper span {
  font-size: 14px;
  color: #1B2E59;
  font-weight: 700;
  text-transform: capitalize;
}

.sotcox-video-btn {
  width: 60px;
  height: 60px;
  color: #ffffff;
  background: #09C3FF;
  border-radius: 50%;
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.sotcox-video-btn:hover {
  background: #266FF2;
  color: #ffffff;
}

.sotcox-zoom-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -40%;
  width: 30px;
  height: 30px;
  color: #ffffff;
  background: #09C3FF;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 16px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sotcox-zoom-btn:hover {
  background: #266FF2;
  color: #ffffff;
}

/* BUTTON CSS END */
/* SEARCH POPUP CSS START */
.search-box-outer {
  margin: 0 35px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .search-box-outer {
    margin: 0px;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .search-box-outer {
    margin-right: 20px;
  }
}

.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  margin-top: -540px;
  transform: translateY(-100%);
  -webkit-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 1500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}


.search-popup .color-layer {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 1500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.search-popup .close-search {
  position: absolute;
  left: 0;
  right: 0;
  top: 75%;
  margin: 0 auto;
  margin-top: -200px;
  border-radius: 50%;
  text-align: center;
  background-color: #266FF2;
  width: 70px;
  cursor: pointer;
  border-bottom: 3px solid #ffffff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
  height: 70px;
  padding: 0px;
}

.search-popup .close-search span {
  position: relative;
  display: block;
  height: 70px;
  width: 70px;
  font-size: 24px;
  color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.search-popup form {
  position: absolute;
  max-width: 700px;
  top: 50%;
  left: 15px;
  right: 15px;
  z-index: 999;
  margin: -35px auto 0;
  transform: scaleX(0);
  transform-origin: center;
  background-color: #1B2E59;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-popup .form-group {
  position: relative;
  margin: 0px;
  overflow: hidden;
}

.search-popup .form-group input[type=text],
.search-popup .form-group input[type=search] {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 50px;
  color: #1B2E59;
  height: 70px;
  width: 100%;
  padding: 10px 30px;
  background-color: #ffffff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: 500;
  text-transform: capitalize;
  border: none;
  border-radius: 5px;
}

.search-popup .form-group input[type=text]::-webkit-input-placeholder,
.search-popup .form-group input[type=search]::-webkit-input-placeholder {
  color: #1B2E59;
  opacity: 1;
}

.search-popup .form-group input[type=text]::-moz-placeholder,
.search-popup .form-group input[type=search]::-moz-placeholder {
  color: #1B2E59;
  opacity: 1;
}

.search-popup .form-group input[type=text]:-ms-input-placeholder,
.search-popup .form-group input[type=search]:-ms-input-placeholder {
  color: #1B2E59;
  opacity: 1;
}

.search-popup .form-group input[type=text]:-moz-placeholder,
.search-popup .form-group input[type=search]:-moz-placeholder {
  color: #1B2E59;
  opacity: 1;
}

.search-popup .form-group input[type=submit],
.search-popup .form-group button {
  position: absolute;
  right: 30px;
  top: 0px;
  height: 70px;
  line-height: 70px;
  background: transparent;
  text-align: center;
  font-size: 24px;
  color: #1B2E59;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup .form-group input[type=submit]:hover,
.search-popup .form-group button:hover {
  color: #1B2E59;
}

.sidenav-bar-visible .search-popup {
  width: 80%;
}

.search-active .search-popup {
  transform: translateY(0%);
  margin-top: 0px;
}

.search-active .search-popup .close-search {
  visibility: visible;
  opacity: 1;
  top: 50%;
  -webkit-transition-delay: 1500ms;
  -moz-transition-delay: 1500ms;
  -ms-transition-delay: 1500ms;
  -o-transition-delay: 1500ms;
  transition-delay: 1500ms;
}

.search-active .search-popup form {
  transform: scaleX(1);
  -webkit-transition-delay: 1200ms;
  -moz-transition-delay: 1200ms;
  -ms-transition-delay: 1200ms;
  -o-transition-delay: 1200ms;
  transition-delay: 1200ms;
}

/* SEARCH POPUP CSS END */
/* side info css */
.hamburger-trigger {
  margin-left: 12px;
  padding: 10px 12px;
  cursor: pointer;
}

.side-info {
  padding: 40px;
}

@media (max-width: 475px) {
  .side-info {
    padding: 25px;
  }
}

.side-info .title {
  text-transform: capitalize;
  font-size: 22px;
}

.side-info .mb-5 {
  margin-bottom: 5px !important;
}

.side-info p {
  font-size: 15x;
  line-height: 28px;
}

.side-info__close {
  top: 0;
  left: 0;
  background: #09C3FF;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.side-info__close a {
  display: block;
  height: 100%;
  width: 100%;
  color: #ffffff;
}

.side-info__close:hover {
  background: #266FF2;
}

.side-info__wrapper {
  padding: 40px;
}

@media (max-width: 475px) {
  .side-info__wrapper {
    padding: 25px;
    padding-bottom: 0px;
  }
}

.side-info .social-links a:hover {
  color: #266FF2;
  border-color: #266FF2;
}

.side-info__logo img {
  max-width: 185px;
}

.side-info-wrapper,
.cart-bar-wrapper {
  position: fixed;
  overflow-y: auto;
  top: 0;
  width: 450px;
  -webkit-transform: translateX(450px);
  -ms-transform: translateX(450px);
  transform: translateX(450px);
  height: 100%;
  display: block;
  background-color: #ffffff;
  z-index: 100;
  padding-top: 0px;
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  right: 0;
}

@media (max-width: 475px) {

  .side-info-wrapper,
  .cart-bar-wrapper {
    width: 310px;
    -webkit-transform: translateX(310px);
    -ms-transform: translateX(310px);
    transform: translateX(310px);
  }
}

.side-info-wrapper .nav,
.cart-bar-wrapper .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.side-info-wrapper .nav a,
.cart-bar-wrapper .nav a {
  flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  max-width: 50%;
  font-size: 18px;
  color: #171719;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
}

.side-info-wrapper .nav a.active,
.cart-bar-wrapper .nav a.active {
  background: #266FF2;
  color: #ffffff;
}

.side-info-wrapper.is-open,
.cart-bar-wrapper.is-open {
  transform: translateX(0px);
}

.side-info-wrapper.mm-only.is-open {
  display: none;
}

@media (max-width: 1199px) {
  .side-info-wrapper.mm-only.is-open {
    display: inline-block;
  }
}

@media (max-width: 1199px) {
  .side-info-wrapper.show-all.is-open {
    display: none;
  }
}

.side-info-wrapper .contact__info li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 15px;
  align-items: center;
  flex-wrap: nowrap;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.side-info-wrapper .contact__info li:not(:last-child) {
  margin-bottom: 15px;
}

.side-info-wrapper .contact__info li span {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  margin-right: 15px;
  color: #266FF2;
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #E6EEFB;
  border-radius: 5px;
}

.side-info-wrapper .contact__info li:hover span {
  background-color: #266FF2;
  border-color: #266FF2;
  color: #ffffff;
}

.side-info-wrapper .search-box {
  padding: 40px;
  padding-bottom: 0px;
}

@media (max-width: 475px) {
  .side-info-wrapper .search-box {
    padding: 25px;
    padding-top: 40px;
    padding-bottom: 0px;
  }
}

.side-info-wrapper .search-box input {
  border: 1px solid #E6EEFB;
  padding: 15px 20px;
  width: 100%;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.side-info-wrapper .search-box input:focus {
  border-color: #266FF2;
}

.side-info-wrapper .search-box div {
  position: relative;
}

.side-info-wrapper .search-box button {
  font-size: 20px;
  color: #1B2E59;
  position: absolute;
  right: 10px;
  top: 50%;
  translate: 0px -50%;
}

/* SOCIAL LINKS CSS START */
.sotcox-social-links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.sotcox-social-links a {
  color: #1B2E59;
}

.sotcox-social-links a:not(:last-child) {
  margin-right: 20px;
}

.sotcox-social-links a:hover {
  color: #09C3FF;
}

.sotcox-social-links span {
  font-size: 14px;
  color: #1B2E59;
  font-weight: 700;
  margin-left: 10px;
  text-transform: uppercase;
  display: inline-block;
}

/* SOCIAL LINKS CSS END */
/* OWL NAV CSS START */
@media (max-width: 1199px) {
  .sotcox-slide-nav .owl-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 60px;
  }
}

.sotcox-slide-nav .owl-nav div {
  font-size: 16px;
  color: #1B2E59;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  left: -25px;
  top: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #E6EEFB;
}

.sotcox-slide-nav .owl-nav div:hover {
  color: #ffffff;
  background: #266FF2;
  border-color: #266FF2;
}

@media (max-width: 1199px) {
  .sotcox-slide-nav .owl-nav div {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    position: static;
    background: #09C3FF;
    color: #ffffff;
  }
}

.sotcox-slide-nav .owl-nav .owl-next {
  left: auto;
  right: -25px;
}

.sotcox-slide-nav__style-2 .owl-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0;
  margin-top: 0px;
  justify-content: center;
  margin-top: 60px;
}

.sotcox-slide-nav__style-2 .owl-nav div {
  color: #1B2E59;
  position: static;
  background: #ffffff;
  margin-right: 20px;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.sotcox-slide-nav__style-2 .owl-nav div:hover {
  color: #ffffff;
}

.sotcox-slide-nav__style-3 .owl-nav div {
  width: 60px;
  height: 60px;
  font-size: 24px;
  left: -110px;
}

@media (max-width: 1599px) {
  .sotcox-slide-nav__style-3 .owl-nav div {
    left: -30px;
  }
}

.sotcox-slide-nav__style-3 .owl-nav div.owl-next {
  right: -110px;
}

@media (max-width: 1599px) {
  .sotcox-slide-nav__style-3 .owl-nav div.owl-next {
    right: -30px;
  }
}

/* OWL NAV CSS END */
/* HERO CSS START */
.sotcox-hero-section {
  position: relative;
  overflow: hidden;
}

.sotcox-hero-section .shape {
  right: 0;
  bottom: 0px;
  z-index: -1;
}

@media (max-width: 1599px) {
  .sotcox-hero-section .shape {
    right: -5%;
    max-width: 50%;
  }
}

@media (max-width: 1599px) {
  .sotcox-hero-section .shape__2 {
    max-width: 55%;
  }
}

@media (max-width: 991px) {
  .sotcox-hero-section .shape {
    display: none;
  }
}

.sotcox-hero-section .title {
  font-size: 70px;
  letter-spacing: -0.01em;
  line-height: 115%;
}

@media (max-width: 1199px) {
  .sotcox-hero-section .title {
    font-size: 48px;
  }
}

@media (max-width: 475px) {
  .sotcox-hero-section .title {
    font-size: 36px;
  }
}

.sotcox-hero-section .inner-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.sotcox-hero-section .inner-btns .sotcox-btn {
  margin-bottom: 0px;
  margin-right: 20px;
}

@media (max-width: 1199px) {
  .sotcox-hero-section .sotcox-social-links {
    margin-top: 45px;
    flex-wrap: wrap;
  }
}

@media (max-width: 1199px) {
  .sotcox-hero-section .sotcox-social-links a {
    margin-top: 10px;
  }
}

.sotcox-hero-section .sotcox-social-links a:not(:last-child) {
  margin-right: 40px;
}

@media (max-width: 1199px) {
  .sotcox-hero-section .sotcox-social-links a:not(:last-child) {
    margin-right: 15px;
  }
}

.sotcox-hero-section .sotcox-social-links a .fa-facebook-f {
  color: #4064ac;
}

.sotcox-hero-section .sotcox-social-links a .fa-youtube {
  color: #ff0000;
}

.sotcox-hero-section .sotcox-social-links a .fa-twitter {
  color: #1da1f2;
}

.sotcox-hero-section .sotcox-social-links a .fa-linkedin {
  color: #0077b5;
}

.sotcox-hero-section .inner-right {
  background: #e3ebf8;
  position: relative;
  border-radius: 30px;
  width: 520px;
  min-height: 605px;
  margin: 0 0 0 auto;
  translate: 35px 0px;
}

@media (max-width: 1199px) {
  .sotcox-hero-section .inner-right {
    width: 350px;
    min-height: 420px;
    translate: 0px 0px;
  }
}

@media (max-width: 991px) {
  .sotcox-hero-section .inner-right {
    margin: 0;
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .sotcox-hero-section .inner-right {
    display: none;
  }
}

.sotcox-hero-section .img-wrapper {
  position: absolute;
  bottom: 0;
  left: -90px;
  max-width: inherit;
}

@media (max-width: 991px) {
  .sotcox-hero-section .img-wrapper {
    left: -60px;
  }
}

@media (max-width: 1199px) {
  .sotcox-hero-section .sotcox-video-wrapper span {
    display: none;
  }
}
/* 
.sotcox-hero-section__style-2::before {
  
  background-image: url('../img/hero-back.png');
  background-repeat: no-repeat;
  display: block;
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
} */

@media (max-width: 991px) {
  .sotcox-hero-section__style-2 {
    padding-top: 150px;
  }
}

.sotcox-hero-section__style-2 .inner-content {
  position: relative;
  z-index: 2;
}

.sotcox-hero-section__style-2 .inner-content .title {
  color: #ffffff;
}

.sotcox-hero-section__style-2 .inner-content p {
  color: #ffffff;
}

.sotcox-hero-section__style-2 .inner-content li {
  color: #ffffff;
}

.sotcox-hero-section__style-2 .middle-img {
  position: absolute;
  left: 50%;
  top: 0;
  translate: -50% 0px;
  height: 100%;
}

@media (max-width: 1599px) {
  .sotcox-hero-section__style-2 .middle-img {
    width: 980px;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .sotcox-hero-section__style-2 .middle-img {
    display: none;
  }
}

.sotcox-hero-section__style-2 .middle-img img {
  height: 100%;
}

.sotcox-hero-section__style-2 .sotcox-video-btn {
  width: 138px;
  height: 138px;
  font-size: 30px;
  position: absolute;
  right: 95px;
  translate: 0px -50%;
  z-index: 2;
}

@media (max-width: 991px) {
  .sotcox-hero-section__style-2 .sotcox-video-btn {
    position: relative;
    right: 0;
    translate: 0px 0px;
    margin-bottom: 50px;
    margin-right: 0px;
    width: 100px;
    height: 100px;
    font-size: 18px;
  }
}

.sotcox-hero-section__style-2 .right-wrapper {
  position: relative;
}

.sotcox-hero-section__style-2 .sotcox-social-links {
  position: absolute;
  right: 80px;
  top: 50%;
  translate: 0px -50%;
  flex-direction: column;
}

@media (max-width: 1199px) {
  .sotcox-hero-section__style-2 .sotcox-social-links {
    margin-top: 0px;
    right: 45px;
  }
}

@media (max-width: 991px) {
  .sotcox-hero-section__style-2 .sotcox-social-links {
    position: relative;
    right: 0;
    translate: 40px 0px;
    margin-top: 50px;
    flex-direction: row;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sotcox-hero-section__style-2 .sotcox-social-links {
    translate: 30px 0px;
  }
}

@media (max-width: 767px) {
  .sotcox-hero-section__style-2 .sotcox-social-links {
    translate: 15px 0px;
  }
}

@media (max-width: 475px) {
  .sotcox-hero-section__style-2 .sotcox-social-links {
    flex-wrap: wrap;
  }
}

.sotcox-hero-section__style-2 .sotcox-social-links a {
  writing-mode: vertical-lr;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

@media (max-width: 991px) {
  .sotcox-hero-section__style-2 .sotcox-social-links a {
    writing-mode: horizontal-tb;
    flex-direction: row;
  }
}

.sotcox-hero-section__style-2 .sotcox-social-links a:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 80px;
}

@media (max-width: 991px) {
  .sotcox-hero-section__style-2 .sotcox-social-links a:not(:last-child) {
    margin-bottom: 0px;
    margin-right: 40px;
  }
}

.sotcox-hero-section__style-2 .sotcox-social-links a span {
  margin-left: 0px;
  color: #ffffff;
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .sotcox-hero-section__style-2 .sotcox-social-links a span {
    margin-bottom: 0px;
    margin-left: 15px;
  }
}

.sotcox-hero-section .small-shape {
  position: absolute;
}

.sotcox-hero-section .small-shape__1 {
  left: 49%;
  top: 15%;
  z-index: 1;
  animation: shapeTopLeft 2s linear infinite alternate;
}

@media (max-width: 991px) {
  .sotcox-hero-section .small-shape__1 {
    left: 10%;
    top: 10%;
  }
}

.sotcox-hero-section .small-shape__2 {
  left: 5%;
  bottom: 10%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

@media (max-width: 991px) {
  .sotcox-hero-section .small-shape__2 {
    left: 10%;
    bottom: 10%;
  }
}

.sotcox-hero-section .small-shape__3 {
  left: 30%;
  bottom: 15%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

@media (max-width: 991px) {
  .sotcox-hero-section .small-shape__3 {
    left: 80%;
    top: 20%;
  }
}

.sotcox-hero-section .small-shape__4 {
  left: 90%;
  bottom: 10%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.review-wrapper .item {
  z-index: 2;
  position: absolute;
  left: -160px;
  top: 60px;
  width: auto;
  padding: 15px;
  padding-right: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  background: #ffffff;
  animation: shapeDownLeft 2s linear infinite alternate;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}

@media (max-width: 1199px) {
  .review-wrapper .item {
    left: -230px;
    top: -40px;
  }
}

@media (max-width: 991px) {
  .review-wrapper .item {
    left: auto;
    right: -225px;
  }
}

.review-wrapper .item:nth-child(2) {
  animation: shapeTopLeft 2s linear infinite alternate;
  top: auto;
  bottom: -10px;
  left: -120px;
}

@media (max-width: 1199px) {
  .review-wrapper .item:nth-child(2) {
    left: -190px;
  }
}

@media (max-width: 991px) {
  .review-wrapper .item:nth-child(2) {
    left: auto;
    right: -180px;
  }
}

.review-wrapper .item .icon {
  margin-right: 18px;
}

.review-wrapper .item .content h6 {
  font-size: 14px;
  font-weight: 600;
}

.review-wrapper .item .content p {
  color: #818181;
}

.review-wrapper .item .content p .star {
  color: #febb43;
}

.review-wrapper .item .content p span {
  color: #1B2E59;
  margin: 0px 5px;
  font-weight: 700;
  font-size: 12px;
}

/* HERO CSS END */
/* CARD CSS START */
.sotcox-card-wrapper {
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 50px;
}

@media (max-width: 991px) {
  .sotcox-card-wrapper {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .sotcox-card-wrapper {
    padding: 30px 40px;
  }
}

@media (max-width: 475px) {
  .sotcox-card-wrapper {
    padding: 30px 20px;
  }
}

@media (max-width: 991px) {
  .sotcox-card-wrapper>div {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .sotcox-card-wrapper>div {
    width: 100%;
  }
}

.sotcox-card-wrapper>div:nth-child(2) {
  border-left: 1px solid #E6EEFB;
  border-right: 1px solid #E6EEFB;
  padding: 0px 60px;
}

@media (max-width: 1199px) {
  .sotcox-card-wrapper>div:nth-child(2) {
    border: none;
    padding: 0px;
  }
}

.sotcox-card-wrapper>div:nth-child(2) .sotcox-dots {
  left: 60px;
}

@media (max-width: 1199px) {
  .sotcox-card-wrapper>div:nth-child(2) .sotcox-dots {
    left: 0px;
  }
}

@media (max-width: 991px) {
  .sotcox-card-wrapper>div:not(:last-child) {
    margin-bottom: 40px;
  }
}

.sotcox-card-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
}

.sotcox-card-box .icon {
  margin-right: 18px;
  width: 70px;
}

.sotcox-card-box .title {
  font-weight: 600;
}

.sotcox-card-box .sotcox-dots {
  background: #E6EEFB;
  border-radius: 40px;
  position: absolute;
  left: 0;
  bottom: -50px;
}

@media (max-width: 991px) {
  .sotcox-card-box .sotcox-dots {
    display: none;
  }
}

.sotcox-card-box .sotcox-dots span {
  opacity: 0.3;
}

.sotcox-card-box__2 .sotcox-dots span {
  background: #09C3FF;
}

.sotcox-card-box__3 .sotcox-dots span {
  background: #FEBB43;
}

/* CARD CSS END */
/* SERVICE CSS START */
.sotcox-service-section {
  position: relative;
}

.sotcox-service-section .small-shape {
  position: absolute;
  left: 5%;
  bottom: 30%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-service-section .small-shape__2 {
  left: auto;
  right: 10%;
  bottom: auto;
  top: 30%;
  animation: shapeTopLeft 2s linear infinite alternate;
}

.sotcox-service-wrapper .owl-stage-outer {
  border: 1px solid #E6EEFB;
}

.sotcox-service-item {
  border-right: 1px solid #E6EEFB;
  position: relative;
  padding: 50px;
}

.sotcox-service-item::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background: #266FF2;
  height: 2px;
  width: 0;
  -webkit-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
}

.sotcox-service-item .icon {
  margin-bottom: 40px;
  width: max-content;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sotcox-service-item .icon img {
  width: auto;
}

.sotcox-service-item .title a {
  /* font-family: "Archivo", sans-serif; */
  color: #1B2E59;
}

.sotcox-service-item .title a:hover {
  color: #266FF2;
}

.sotcox-service-item .sotcox-inline-btn {
  margin-top: 35px;
  color: #1B2E59;
  font-size: 18px;
  display: inline-block;
  color: #1B2E59;
}

.sotcox-service-item .sotcox-inline-btn:hover {
  color: #266FF2;
}

.sotcox-service-item:hover::after {
  width: 100%;
}

.sotcox-service-item:hover .icon {
  transform: rotateY(180deg);
}

.sotcox-service-item__style-2 {
  padding: 0px;
  padding-bottom: 25px;
  padding-left: 40px;
  border: none;
  border-left: 2px solid #E6EEFB;
}

.sotcox-service-item__style-2::after {
  display: none;
}

.sotcox-service-item__style-2 .icon {
  background: #266FF2;
  width: 85px;
  height: 85px;
  border-radius: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.sotcox-service-item__style-2 .icon__2 {
  background: #3dddc6;
}

.sotcox-service-item__style-2 .icon__3 {
  background: #09C3FF;
}

.sotcox-service-item__style-2 .icon__4 {
  background: #fedb35;
}

.sotcox-service-item__style-2 .icon__5 {
  background: #ff5151;
}

.sotcox-service-item__style-2 .title {
  margin-top: 20px;
}

.sotcox-service-item__style-2 .title a {
  color: #1B2E59;
  font-size: 30px;
}

.sotcox-service-section-page .sotcox-service-item {
  border: 1px solid #E6EEFB;
}

/* SERVICE CSS END */
/* WORKFLOW CSS START */
.sotcox-workflow-box {
  position: relative;
  border-radius: 10px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 35px 30px;
}

@media (max-width: 767px) {
  .sotcox-workflow-box {
    padding: 35px 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sotcox-workflow-box {
    padding: 30px 20px;
  }
}

.sotcox-workflow-box .icon {
  width: 80px;
  height: 80px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 25px;
  background: #ffffff;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sotcox-workflow-box .icon .count {
  position: absolute;
  width: 32px;
  height: 32px;
  color: #1B2E59;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  /* font-family: "Archivo", sans-serif; */
  left: -15px;
  top: 0;
  background: #ffffff;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sotcox-workflow-box .title {
  margin-bottom: 15px;
}

.sotcox-workflow-box .sotcox-dots {
  position: absolute;
  right: -35px;
  top: 40px;
  z-index: 2;
}

@media (max-width: 1199px) {
  .sotcox-workflow-box .sotcox-dots {
    right: -25px;
  }
}

@media (max-width: 767px) {
  .sotcox-workflow-box .sotcox-dots {
    right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sotcox-workflow-box .sotcox-dots {
    right: -35px;
  }
}

.sotcox-workflow-box .sotcox-dots span:first-child {
  background: #09C3FF;
}

.sotcox-workflow-box__1:hover .count {
  background: #09c3ff;
}

.sotcox-workflow-box__2:hover .count {
  background: #266FF2;
}

.sotcox-workflow-box__3:hover .count {
  background: #fdc330;
}

.sotcox-workflow-box__4:hover .count {
  background: #ff5151;
}

.sotcox-workflow-box.active {
  background: #ffffff;
}

.sotcox-workflow-box.active .icon {
  background: #E6EEFB;
}

.sotcox-workflow-box.active .icon .count {
  color: #ffffff;
}

.product-box:hover .icon .count{
  color: #ffffff;
}

.product-box:hover .icon{
  background: #E6EEFB;
}

.product-box:hover{
  background: #ffffff;
}
/* WORKFLOW CSS END */
/* PROJECT CSS START */
.sotcox-project-section {
  position: relative;
}

.sotcox-project-section .small-shape {
  position: absolute;
}

.sotcox-project-section .small-shape__1 {
  left: 4%;
  top: 10%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-project-section .small-shape__2 {
  left: auto;
  right: 8%;
  top: 10%;
  animation: shapeTopLeft 2s linear infinite alternate;
}

.sotcox-project-section .small-shape__3 {
  left: 6%;
  top: auto;
  bottom: 10%;
  animation: shapeTopLeft 2s linear infinite alternate;
}

.sotcox-project-section .small-shape__4 {
  left: auto;
  right: 8%;
  top: auto;
  bottom: 12%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-project-section__style-2 .content-left-wrapper {
  border: 1px solid #E6EEFB;
  padding: 7px;
  text-align: center;
  border-radius: 40px;
}

@media (max-width: 1199px) {
  .sotcox-project-wrapper {
    margin-top: 60px;
  }
}

.sotcox-project-filter {
  gap: 10px;
  flex-wrap: wrap;
}

.sotcox-project-filter li {
  /* font-family: "Archivo", sans-serif; */
  font-size: 14px;
  border-radius: 5px;
  padding: 1px 13px;
  border: 1px solid #E6EEFB;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  color: #bbc1ce;
}

.sotcox-project-filter li.active,
.sotcox-project-filter li:hover {
  background: #09C3FF;
  color: #ffffff;
  border-color: #09C3FF;
}

.sotcox-project-filter__style-2 {
  justify-content: center;
  gap: 0px;
}

.sotcox-project-filter__style-2 li {
  border-radius: 40px;
  color: #1B2E59;
  padding: 7px 40px;
  text-transform: uppercase;
  font-weight: 700;
  border-color: transparent;
}

.sotcox-project-filter__style-2 li:not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 475px) {
  .sotcox-project-filter__style-2 li:not(:last-child) {
    margin-right: 0px;
  }
}

.sotcox-project-filter__style-2 li:hover,
.sotcox-project-filter__style-2 li.active {
  background: #266FF2;
  border-color: #266FF2;
}

.sotcox-project-item {
  position: relative;
}

.sotcox-project-item .thumb img {
  border-radius: 10px;
  width: 100%;
}

.sotcox-project-item .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  padding: 20px 0px;
  padding-bottom: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sotcox-project-item .content .title {
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.sotcox-project-item .content .title a {
  color: #1B2E59;
  /* font-family: "Archivo", sans-serif; */
}

.sotcox-project-item .content .cat {
  color: #266FF2;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.sotcox-project-item .inline-btn {
  width: 41px;
  height: 41px;
  border: 1px solid #E6EEFB;
  border-radius: 50%;
  color: #1B2E59;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  translate: 0px -50%;
  transform: rotate(-45deg);
}

.sotcox-project-item .inline-btn:hover {
  background: #266FF2;
  color: #ffffff;
  border-color: #266FF2;
}

.sotcox-project-item:hover .content {
  opacity: 1;
  visibility: visible;
}

.sotcox-project-item__style-2 .thumb img {
  border-radius: 0px 0px 10px 10px;
}

.sotcox-project-item__style-2 .content {
  border-radius: 10px 10px 0px 0px;
  position: relative;
  padding: 35px 40px;
  padding-bottom: 38px;
  opacity: 1;
  visibility: visible;
  border: 1px solid #E6EEFB;
  border-bottom: 0px;
}

@media (max-width: 475px) {
  .sotcox-project-item__style-2 .content {
    padding: 25px 20px;
    padding-bottom: 28px;
  }
}

.sotcox-project-item__style-2 .content .title {
  font-size: 24px;
  padding-right: 80px;
}

.sotcox-project-item__style-2 .inline-btn {
  font-size: 24px;
  height: 61px;
  width: 61px;
  right: 32px;
}

@media (max-width: 475px) {
  .sotcox-project-item__style-2 .inline-btn {
    right: 20px;
  }
}

/* PROJECT CSS END */
/* WCO CSS START */
@media (max-width: 1199px) {
  .sotcox-wcu-section .sotcox-section-heading {
    padding-top: 60px;
  }
}

.wcu-img-wrapper {
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-wcu-box .icon {
  margin-bottom: 35px;
}

.sotcox-wcu-box .content {
  padding-right: 60px;
}

@media (max-width: 991px) {
  .sotcox-wcu-box .content {
    padding-right: 0px;
  }
}

.sotcox-wcu-box .title {
  margin-bottom: 15px;
}

/* WCO CSS END */
/* BRAND CSS START */
.sotcox-brand-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid #E6EEFB;
  padding: 10px;
  border-radius: 40px;
  width: auto;
  background: #ffffff;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sotcox-brand-box .icon {
  background: #266FF2;
  width: 60px;
  height: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sotcox-brand-box .title {
  font-size: 18px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.sotcox-brand-box__1 .icon {
  background: #266FF2;
}

.sotcox-brand-box__2 .icon {
  background: #fff3ed;
}

.sotcox-brand-box__3 .icon {
  background: #fff9eb;
}

.sotcox-brand-box__4 .icon {
  background: #e7f3ed;
}

.sotcox-brand-box__5 .icon {
  background: #e7f9ff;
}

.sotcox-brand-box__6 .icon {
  background: #f9f3fb;
}

.sotcox-brand-box__7 .icon {
  background: #ffeeee;
}

.sotcox-brand-box:hover .icon {
  transform: rotateY(180deg);
}

/* BRAND CSS END */
/* TESTIMONIAL CSS START */
.sotcox-testimonial-section {
  position: relative;
}

.sotcox-testimonial-section .small-shape {
  position: absolute;
}

.sotcox-testimonial-section .small-shape__1 {
  left: 5%;
  top: 12%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-testimonial-section .small-shape__2 {
  left: auto;
  right: 20%;
  top: 10%;
  animation: shapeTopLeft 2s linear infinite alternate;
}

.sotcox-testimonial-section .small-shape__3 {
  left: 0%;
  top: auto;
  bottom: 5%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-testimonial-wrapper__style-2 .owl-stage-outer {
  overflow: visible;
}

.sotcox-testimonial-item {
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  padding-bottom: 30px;
}

@media (max-width: 475px) {
  .sotcox-testimonial-item {
    padding: 30px;
    padding-bottom: 20px;
  }
}

.sotcox-testimonial-item .thumb {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 25px;
  position: relative;
}

.sotcox-testimonial-item .thumb img {
  border-radius: 50%;
}

.sotcox-testimonial-item .quote {
  font-size: 14px;
  color: #266FF2;
  border: 1px solid #E6EEFB;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -15px;
  top: 0;
  width: 28px;
  height: 28px;
  line-height: 100%;
  z-index: 2;
  background: #ffffff;
}

.sotcox-testimonial-item p {
  position: relative;
  padding-left: 20px;
}

.sotcox-testimonial-item p::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background: #09C3FF;
  width: 1px;
  top: 50%;
  translate: 0 -50%;
  height: calc(100% - 15px);
}

.sotcox-testimonial-item .title {
  font-size: 18px;
  text-transform: capitalize;
}

.sotcox-testimonial-item .sub-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.sotcox-testimonial-item__style-2 {
  background: #171719;
  border: 1px solid #252529;
  padding: 25px 30px;
}

.sotcox-testimonial-item__style-2 .top {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: nowrap;
}

.sotcox-testimonial-item__style-2 .top i {
  position: absolute;
  right: 0px;
  top: 5px;
}

.sotcox-testimonial-item__style-2 .top i.fa-twitter {
  color: #03a9f4;
}

.sotcox-testimonial-item__style-2 .top i.fa-instagram {
  color: #ffffff;
  border-radius: 2px;
  background-image: linear-gradient(49.12deg, #FFDD55 6.62%, #FF543E 50.06%, #C837AB 93.5%);
}

.sotcox-testimonial-item__style-2 .thumb {
  width: 45px;
  margin-right: 20px;
  height: 45px;
  margin-bottom: 0px;
}

.sotcox-testimonial-item__style-2 .thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sotcox-testimonial-item__style-2 .title {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.sotcox-testimonial-item__style-2 .sub-title {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.sotcox-testimonial-item__style-2 p {
  padding-left: 0px;
  color: #9797AA;
}

.sotcox-testimonial-item__style-2 p::after {
  display: none;
}

/* TESTIMONIAL CSS END */
/* BLOG POST CSS START */
.sotcox-article-section {
  position: relative;
}

.sotcox-article-section .small-shape {
  position: absolute;
}

.sotcox-article-section .small-shape__1 {
  left: 10%;
  top: 15%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-article-section .small-shape__2 {
  right: 20%;
  top: 10%;
  animation: shapeTopLeft 2s linear infinite alternate;
}

.sotcox-article-section .small-shape__3 {
  left: 0%;
  bottom: 15%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-article-section .small-shape__4 {
  right: 0%;
  bottom: 10%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-article-item .thumb img {
  border-radius: 10px 10px 0 0;
  width: 100%;
}

.sotcox-article-item .meta-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.sotcox-article-item .meta-info span {
  position: relative;
}

.sotcox-article-item .meta-info span:not(:last-child) {
  margin-right: 50px;
}

.sotcox-article-item .meta-info span:not(:last-child)::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  width: 1px;
  left: auto;
  right: -25px;
  height: calc(100% - 15px);
  top: 50%;
  translate: 0 -50%;
  background: #E6EEFB;
}

.sotcox-article-item .meta-info strong {
  color: #1B2E59;
}

.sotcox-article-item .title {
  margin-top: 5px;
}

.sotcox-article-item .title a {
  /* font-family: "Archivo", sans-serif; */
  font-size: 24px;
  color: #1B2E59;
}

.sotcox-article-item .article-cats {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.sotcox-article-item .article-cats a {
  font-size: 14px;
  border-radius: 5px;
  padding: 1px 13px;
  border: 1px solid #e6eefb;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  color: #bbc1ce;
  margin-top: 10px;
  /* font-family: "Archivo", sans-serif; */
}

.sotcox-article-item .article-cats a:not(:last-child) {
  margin-right: 20px;
}

.sotcox-article-item .article-cats a:hover {
  background: #266FF2;
  color: #ffffff;
  border-color: #266FF2;
}

.sotcox-article-item__style-2 .content {
  border: 1px solid #E6EEFB;
  border-radius: 10px 10px 0px 0px;
  padding: 35px 40px;
  padding-bottom: 30px;
}

@media (max-width: 475px) {
  .sotcox-article-item__style-2 .content {
    padding: 25px 25px;
    padding-bottom: 25px;
  }
}

.sotcox-article-item__style-2 .thumb img {
  border-radius: 0px 0px 10px 10px;
}

.sotcox-article-item__style-2 .title {
  margin: 0px;
}

.sotcox-article-item__style-2 .bottom {
  margin-top: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.sotcox-article-item__style-2 .bottom .fvrt {
  color: #1B2E59;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.sotcox-article-item__style-2 .bottom .fvrt span {
  color: #1B2E59;
  margin-left: 8px;
}

.sotcox-article-item__style-2 .sotcox-inline-btn .icon {
  margin-left: 15px;
  color: #266FF2;
}

.sotcox-article-item__style-2 .sotcox-inline-btn:hover {
  color: #266FF2;
}

/* BLOG POST CSS END */
/* NEWSLETTER CSS START */
.sotcox-newsletter-section {
  position: relative;
}

.sotcox-newsletter-section::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  height: 50%;
  top: auto;
  bottom: 0;
  background: #E6EEFB;
  z-index: -1;
}

.sotcox-newsletter-section.sotcox-service-page::after {
  height: 100%;
}

.sotcox-newsletter-wrapper {
  position: relative;
  background: #266FF2;
  color: #ffffff;
  padding: 70px 100px;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .sotcox-newsletter-wrapper {
    padding: 60px 35px;
  }
}

.sotcox-newsletter-wrapper .sub-heading::after {
  display: none;
}

.sotcox-newsletter-wrapper .heading {
  font-size: 24px;
  line-height: 32px;
  position: relative;
  z-index: 2;
}

.sotcox-newsletter-wrapper .shape__1 {
  left: 0;
  bottom: 0;
}

.sotcox-newsletter-wrapper .shape__2 {
  right: 0;
  top: 0;
}

.sotcox-newsletter-form {
  position: relative;
}

@media (max-width: 1199px) {
  .sotcox-newsletter-form {
    margin-top: 40px;
  }
}

.sotcox-newsletter-form.sotcox-input-field input {
  padding: 20px 30px;
}

.sotcox-newsletter-form button {
  padding: 10px 42px;
  position: absolute;
  right: 10px;
  top: 10px;
  position: absolute;
}

@media (max-width: 767px) {
  .sotcox-newsletter-form button {
    position: static;
    top: 0;
    right: 0;
    margin-top: 20px;
    background: #09C3FF;
    padding: 15px 42px;
  }
}

.sotcox-newsletter-form__style-2.sotcox-input-field input {
  padding: 5px 0px;
  padding-right: 30px;
  padding-bottom: 20px;
  background: transparent;
  border-bottom: 1px solid #454547;
  color: #ffffff;
  border-radius: 0px;
}

.sotcox-newsletter-form__style-2.sotcox-input-field input::placeholder {
  color: #ffffff;
}

.sotcox-newsletter-form__style-2 button {
  padding: 0px;
  font-size: 20px;
  color: #ffffff;
  background: transparent;
  top: 5px;
}

@media (max-width: 767px) {
  .sotcox-newsletter-form__style-2 button {
    position: absolute;
    padding: 0px;
    font-size: 20px;
    color: #ffffff;
    background: transparent;
    top: 5px;
    margin-top: 0px;
  }
}

/* NEWSLETTER CSS END */
/* CONTACT INFO CSS START */
.sotcox-contact-info-box {
  background: #ffffff;
  padding: 50px;
  border-radius: 10px;
}

@media (max-width: 991px) {
  .sotcox-contact-info-box {
    padding: 40px;
  }
}

@media (max-width: 475px) {
  .sotcox-contact-info-box {
    padding: 30px;
  }
}

.sotcox-contact-info-box .icon {
  margin-bottom: 20px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  width: max-content;
}

.sotcox-contact-info-box span {
  text-transform: uppercase;
  color: #266FF2;
  font-weight: 700;
  font-size: 12px;
}

.sotcox-contact-info-box p {
  margin-top: 15px;
  /* font-family: "Archivo", sans-serif; */
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-transform: capitalize;
  color: #1B2E59;
}

@media (max-width: 475px) {
  .sotcox-contact-info-box p br {
    display: none;
  }
}

.sotcox-contact-info-box .sotcox-btn {
  padding: 7.5px 40px;
  margin-top: 45px;
}

.sotcox-contact-info-box__2 span {
  color: #fdc330;
}

.sotcox-contact-info-box__3 span {
  color: #09C3FF;
}

.sotcox-contact-info-box .info-call {
  margin-top: 45px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: nowrap;
}

.sotcox-contact-info-box .info-call .icon {
  width: 45px;
  height: 45px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background: #09C3FF;
  border-radius: 50%;
}

@media (max-width: 475px) {
  .sotcox-contact-info-box .info-call .icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
}

.sotcox-contact-info-box .info-call span {
  font-size: 28px;
  color: #1B2E59;
  font-weight: 700;
  margin-bottom: 0px;
}

.sotcox-contact-info-box:hover>.icon {
  transform: rotateY(180deg);
}

/* CONTACT INFO CSS END */
/* CONTACT INFO CSS START */
.sotcox-contact-info-box {
  background: #ffffff;
  padding: 50px;
  border-radius: 10px;
}

@media (max-width: 991px) {
  .sotcox-contact-info-box {
    padding: 40px;
  }
}

@media (max-width: 475px) {
  .sotcox-contact-info-box {
    padding: 30px;
  }
}

.sotcox-contact-info-box .icon {
  margin-bottom: 20px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  width: max-content;
}

.sotcox-contact-info-box span {
  text-transform: uppercase;
  color: #266FF2;
  font-weight: 700;
  font-size: 12px;
}

.sotcox-contact-info-box p {
  margin-top: 15px;
  /* font-family: "Archivo", sans-serif; */
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-transform: capitalize;
  color: #1B2E59;
}

@media (max-width: 475px) {
  .sotcox-contact-info-box p br {
    display: none;
  }
}

.sotcox-contact-info-box .sotcox-btn {
  padding: 7.5px 40px;
  margin-top: 45px;
}

.sotcox-contact-info-box__2 span {
  color: #fdc330;
}

.sotcox-contact-info-box__3 span {
  color: #09C3FF;
}

.sotcox-contact-info-box .info-call {
  margin-top: 45px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: nowrap;
}

.sotcox-contact-info-box .info-call .icon {
  width: 45px;
  height: 45px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background: #09C3FF;
  border-radius: 50%;
}

@media (max-width: 475px) {
  .sotcox-contact-info-box .info-call .icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
}

.sotcox-contact-info-box .info-call span {
  font-size: 28px;
  color: #1B2E59;
  font-weight: 700;
  margin-bottom: 0px;
}

.sotcox-contact-info-box:hover>.icon {
  transform: rotateY(180deg);
}

/* CONTACT INFO CSS END */
/* ACHIVEMENT CSS START */
.sotcox-achivement-section {
  position: relative;
}

.sotcox-achivement-section .small-shape {
  position: absolute;
  left: 5%;
  bottom: 25%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-achivement-section .small-shape__2 {
  left: auto;
  right: 5%;
  bottom: auto;
  top: 35%;
  animation: shapeTopLeft 2s linear infinite alternate;
}

@media (max-width: 1199px) {
  .sotcox-achivement-section .sotcox-section-heading br {
    display: none;
  }
}

.sotcox-achivement-boxs {
  position: relative;
  min-height: 570px;
}

@media (max-width: 1199px) {
  .sotcox-achivement-boxs {
    margin-top: 60px;
  }
}

@media (max-width: 991px) {
  .sotcox-achivement-boxs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    margin-left: 0px;
  }
}

.sotcox-achivement-boxs .box-item {
  position: absolute;
  width: 270px;
  height: 270px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  left: 0;
}

@media (max-width: 991px) {
  .sotcox-achivement-boxs .box-item {
    position: relative;
    top: 0 !important;
    left: 0 !important;
  }
}

.sotcox-achivement-boxs .box-item .icon {
  position: relative;
  z-index: 2;
}

.sotcox-achivement-boxs .box-item .title {
  font-size: 40px;
  margin-top: 20px;
}

.sotcox-achivement-boxs .box-item .title span {
  color: #1B2E59;
}

.sotcox-achivement-boxs .box-item .content {
  position: relative;
  z-index: 2;
}

.sotcox-achivement-boxs .box-item .shape {
  position: absolute;
  left: -70px;
  top: 50%;
  translate: 0 -50%;
}

@media (max-width: 991px) {
  .sotcox-achivement-boxs .box-item .shape {
    display: none;
  }
}

.sotcox-achivement-boxs .box-item.active::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background: #266FF2;
  border-radius: 50%;
}

.sotcox-achivement-boxs .box-item.active .title span {
  color: #ffffff;
}

.sotcox-achivement-boxs .box-item.active p {
  color: #ffffff;
}

.sotcox-achivement-boxs .box-item:nth-child(1) {
  top: 150px;
}

.sotcox-achivement-boxs .box-item:nth-child(2) {
  left: 250px;
  top: 0;
}

.sotcox-achivement-boxs .box-item:nth-child(3) {
  top: 300px;
  left: 250px;
}

.sotcox-achivement-boxs .box-item:nth-child(4) {
  top: 150px;
  left: 500px;
}

.sotcox-achivement-boxs .box-item__style-2 {
  width: 255px;
  height: 255px;
}

/* ACHIVEMENT CSS END */
/* TEAM CSS START */
.sotcox-team-section {
  position: relative;
}

.sotcox-team-section .owl-stage-outer {
  overflow: visible;
  padding-bottom: 10px;
}

.sotcox-team-section .small-shape {
  position: absolute;
}

.sotcox-team-section .small-shape__1 {
  left: 15%;
  top: 10%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-team-section .small-shape__2 {
  left: auto;
  right: 20%;
  top: 8%;
  animation: shapeTopLeft 2s linear infinite alternate;
}

.sotcox-team-item {
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.04);
  padding: 40px;
  border-radius: 10px;
}

.sotcox-team-item .thumb {
  margin: 0 auto;
  width: 120px;
  height: 120px;
}

.sotcox-team-item .thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sotcox-team-item .title {
  font-size: 20px;
  font-weight: 600;
}

.sotcox-team-item .sub-title {
  font-size: 14px;
  display: block;
}

.sotcox-team-item .sotcox-social-links {
  justify-content: center;
}

.sotcox-team-item .sotcox-social-links a {
  width: 40px;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #E6EEFB;
  border-radius: 50%;
}

.sotcox-team-item .sotcox-social-links a:not(:last-child) {
  margin-right: 10px;
}

.sotcox-team-item .sotcox-social-links a i {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sotcox-team-item .sotcox-social-links a .fa-facebook {
  color: #3b5998;
}

.sotcox-team-item .sotcox-social-links a .fa-twitter {
  color: #1da1f2;
}

.sotcox-team-item .sotcox-social-links a .fa-linkedin {
  color: #0077b5;
}

.sotcox-team-item .sotcox-social-links a .fa-instagram {
  color: #e4405f;
}

.sotcox-team-item .sotcox-social-links a:hover i {
  color: #ffffff;
}

.sotcox-team-item .sotcox-social-links a:nth-child(1):hover {
  background: #3b5998;
  border-color: #3b5998;
}

.sotcox-team-item .sotcox-social-links a:nth-child(2):hover {
  background: #1da1f2;
  border-color: #1da1f2;
}

.sotcox-team-item .sotcox-social-links a:nth-child(3):hover {
  background: #0077b5;
  border-color: #0077b5;
}

.sotcox-team-item .sotcox-social-links a:nth-child(4):hover {
  background: #e4405f;
  border-color: #e4405f;
}

.sotcox-team-item .sotcox-btn {
  font-weight: 400;
  padding: 4px 30px;
}

/* TEAM CSS END */
/* GET-QUOTE CSS START */
.sotcox-getquote-section {
  position: relative;
}

.sotcox-getquote-section .img-wrapper {
  height: 100%;
}

.sotcox-getquote-section .img-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
}

@media (max-width: 1199px) {
  .sotcox-getquote-section .img-wrapper img {
    border-radius: 10px 10px 0px 0px;
  }
}

.sotcox-getquote-section .small-shape {
  position: absolute;
}

.sotcox-getquote-section .small-shape__1 {
  left: 10%;
  top: 15%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-getquote-section .small-shape__2 {
  right: 20%;
  top: 10%;
  animation: shapeTopLeft 2s linear infinite alternate;
}

.sotcox-getquote-section .small-shape__3 {
  left: 12%;
  bottom: 30%;
  animation: shapeTopLeft 2s linear infinite alternate;
}

.sotcox-getquote-section .small-shape__4 {
  right: 8%;
  bottom: 15%;
  animation: shapeDownLeft 2s linear infinite alternate;
}

.sotcox-getquote-form-wrapper {
  background: #266FF2;
  padding: 60px;
  padding-top: 50px;
  border-radius: 0px 10px 10px 0px;
}

@media (max-width: 1199px) {
  .sotcox-getquote-form-wrapper {
    border-radius: 0px 0px 10px 10px;
  }
}

@media (max-width: 475px) {
  .sotcox-getquote-form-wrapper {
    padding: 40px 35px;
  }
}

.sotcox-getquote-form-wrapper form {
  margin-top: -30px;
}

.sotcox-getquote-form-wrapper .sotcox-input-field label {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: #ffffff;
  /* font-family: "Archivo", sans-serif; */
  letter-spacing: 0.02em;
}

.sotcox-getquote-form-wrapper .sotcox-input-field input,
.sotcox-getquote-form-wrapper .sotcox-input-field textarea {
  background: #2377f3;
  padding: 10px 20px;
  color: #baeeff;
}

.sotcox-getquote-form-wrapper .sotcox-input-field input::placeholder,
.sotcox-getquote-form-wrapper .sotcox-input-field textarea::placeholder {
  color: #baeeff;
}

.sotcox-getquote-form-wrapper .sotcox-input-field textarea {
  min-height: 100px;
}

.sotcox-getquote-form-wrapper .sotcox-btn {
  background: #09C3FF;
}

.sotcox-getquote-form-wrapper .sotcox-btn span {
  background: #1B2E59;
}

/* GET-QUOTE CSS END */
/* BREADCRUMB CSS START */
@media (max-width: 767px) {
  .sotcox-breadcrumb-wrapper {
    padding: 120px 0;
  }
}

.breadcrumb-area {
  position: relative;
}

.breadcrumb-area .small-shape {
  position: absolute;
}

.breadcrumb-area .small-shape__1 {
  left: 10%;
  top: 10%;
}

.breadcrumb-area .small-shape__2 {
  right: 10%;
  top: 10%;
}

.breadcrumb-area .small-shape__3 {
  left: 15%;
  bottom: 10%;
}

.breadcrumb-area .small-shape__4 {
  left: 45%;
  bottom: 25%;
}

.breadcrumb-area .page-title {
  color: #ffffff;
  font-size: 55px;
  font-weight: 700;
  letter-spacing: -0.01em;
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .breadcrumb-area .page-title {
    font-size: 39px;
  }
}

.breadcrumb-area .right-img {
  position: absolute;
  right: 0px;
  top: 0px;
}

@media (max-width: 767px) {
  .breadcrumb-area .right-img {
    max-width: 435px;
  }
}

@media (max-width: 475px) {
  .breadcrumb-area .right-img {
    display: none;
  }
}

.sotcox-breadcrumb ul {
  flex-wrap: wrap;
}

.sotcox-breadcrumb ul li {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.sotcox-breadcrumb ul li:not(:last-child) {
  margin-right: 20px;
}

.sotcox-breadcrumb ul li:not(:last-child)::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  content: ">";
  width: auto;
  height: auto;
  left: auto;
  right: -15px;
  top: 0;
  color: #ffffff;
}

.sotcox-breadcrumb ul li a {
  text-decoration: none;
}

.sotcox-breadcrumb ul li a span {
  color: #ffffff;
}

.sotcox-breadcrumb ul li span {
  color: #ffffff;
}

.sotcox-breadcrumb ul li.duxinbcrumb-end {
  position: relative;
}

.sotcox-breadcrumb ul li.duxinbcrumb-end span {
  background-size: 100% 1px;
}

/* BREADCRUMB CSS END */
/* FAQ CSS START */
.sotcox-faq-section .left-wrapper {
  padding-right: 50px;
}

@media (max-width: 1199px) {
  .sotcox-faq-section .left-wrapper {
    padding-right: 0px;
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .faq__wrapper {
    margin-bottom: 50px;
  }
}

.faq__item {
  border-radius: 0px !important;
  border: none;
  background: transparent;
}

.faq__item .title {
  border: none;
  border-bottom: 0px;
}

.faq__item .accordion-button {
  font-size: 18px;
  /* font-family: "Archivo", sans-serif; */
  font-weight: 700;
  padding: 12px 0px;
  border-radius: 0px !important;
  color: #1B2E59;
  background: transparent;
  box-shadow: none !important;
}

.faq__item .accordion-button:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}

.faq__item .accordion-button::after {
  right: 0px;
  content: "";
  font-family: "Font Awesome 5 Pro";
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  position: absolute;
  font-size: 16px;
  width: 25px;
  height: 25px;
  background: none;
  color: #1B2E59;
  font-weight: 400;
  line-height: 24px;
}

.faq__item .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none !important;
}

.faq__item .accordion-button:not(.collapsed)::after {
  content: "";
}

.faq__item:not(:last-child) {
  margin-bottom: 20px;
}

.faq__item .accordion-body {
  border-top: 0px;
  padding: 0px;
  border-bottom: 0px;
  border: none;
  padding-bottom: 15px;
}

.faq__item:first-of-type {
  border-bottom: 1px solid #d2dceb;
}

.faq__item:last-of-type {
  border-bottom: 1px solid #d2dceb;
}

.faq__item:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid #d2dceb;
}

.faq__item--style-2 .accordion-button {
  font-size: 22px;
  padding: 35px 30px;
  padding-bottom: 30px;
}

@media (max-width: 475px) {
  .faq__item--style-2 .accordion-button {
    padding: 25px 20px;
    padding-bottom: 20px;
    padding-right: 40px;
  }
}

.faq__item--style-2 .accordion-button::after {
  font-size: 22px;
  color: #266FF2;
  right: 30px;
}

@media (max-width: 475px) {
  .faq__item--style-2 .accordion-button::after {
    right: 20px;
  }
}

.faq__item--style-2 .accordion-button:not(.collapsed) {
  background: #E6EEFB;
}

.faq__item--style-2 .accordion-body {
  padding: 0px 30px;
  padding-bottom: 35px;
  background: #E6EEFB;
}

@media (max-width: 475px) {
  .faq__item--style-2 .accordion-body {
    padding: 0px 20px;
    padding-bottom: 25px;
  }
}

.sotcox-faq-tab a {
  background: #E6EEFB;
  font-size: 18px;
  font-weight: 700;
  /* font-family: "Archivo", sans-serif; */
  padding: 15px 30px;
  border-radius: 5px;
  display: block;
  color: #1B2E59;
  position: relative;
}

.sotcox-faq-tab a::after {
  right: 30px;
  content: "";
  font-family: "Font Awesome 5 Pro";
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  position: absolute;
  font-size: 18px;
  width: 25px;
  height: 25px;
  background: none;
  color: #1B2E59;
  font-weight: 400;
  line-height: 24px;
}

.sotcox-faq-tab a:not(:last-child) {
  margin-bottom: 10px;
}

.sotcox-faq-tab a:hover,
.sotcox-faq-tab a.active {
  background: #266FF2;
  color: #ffffff;
}

.sotcox-faq-tab a:hover::after,
.sotcox-faq-tab a.active::after {
  color: #ffffff;
}

.sotcox-faq-tab a.active::after {
  content: "";
}

/* FAQ CSS END */
/* MISSION CSS START */
.sotcox-mission-box {
  border-radius: 10px;
  border: 1px solid #E6EEFB;
  padding: 50px;
  padding-bottom: 45px;
}

@media (max-width: 767px) {
  .sotcox-mission-box {
    padding: 30px;
    padding-bottom: 25px;
  }
}

.sotcox-mission-box .icon {
  width: 80px;
  height: 80px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #eaf1fe;
  border-radius: 50%;
}

.sotcox-mission-box .icon__2 {
  background: #e7f9ff;
}

.sotcox-mission-box .icon__3 {
  background: #fff9eb;
}

.sotcox-mission-box .title {
  font-size: 36px;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .sotcox-mission-box .title {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

/* MISSION CSS END */
/* PRICING CSS START */
.sotcox-pricing-tab {
  text-align: center;
}

.sotcox-pricing-tab nav {
  display: inline-block;
  background: #d3e1fa;
  padding: 7px;
  border-radius: 40px;
}

.sotcox-pricing-tab nav a {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 7px 40px;
  letter-spacing: 0.075em;
  border-radius: 40px;
  color: #1B2E59;
}

.sotcox-pricing-tab nav a:not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 475px) {
  .sotcox-pricing-tab nav a:not(:last-child) {
    margin-right: 0px;
  }
}

.sotcox-pricing-tab nav a:hover,
.sotcox-pricing-tab nav a.active {
  background: #266FF2;
  color: #ffffff;
}

.sotcox-pricing-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px 50px;
}

@media (max-width: 1199px) {
  .sotcox-pricing-box {
    padding: 30px 35px;
  }
}

.sotcox-pricing-box .title {
  font-size: 35px;
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .sotcox-pricing-box .title {
    margin-bottom: 20px;
  }
}

.sotcox-pricing-box .shape {
  position: absolute;
  right: 0;
  top: 50%;
  translate: 0 -50%;
}

@media (max-width: 1199px) {
  .sotcox-pricing-box .shape {
    right: 20px;
  }
}

@media (max-width: 991px) {
  .sotcox-pricing-box .shape {
    display: none;
  }
}

.sotcox-pricing-box .shape::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background: #D9E0EB;
  width: 1px;
  border-radius: 40px;
  left: auto;
  right: 0;
  min-height: 180px;
  top: 50%;
  translate: 0 -50%;
}

.sotcox-pricing-box .shape::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background: #09C3FF;
  height: 40px;
  border-radius: 40px;
  width: 3px;
  right: -1.5px;
  top: 50%;
  translate: 0 -50%;
  left: auto;
}

.sotcox-pricing-box ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.sotcox-pricing-box ul li {
  width: 50%;
  color: #1B2E59;
  text-transform: capitalize;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: nowrap;
}

@media (max-width: 475px) {
  .sotcox-pricing-box ul li {
    flex-wrap: wrap;
    width: 100%;
  }
}

.sotcox-pricing-box ul li i {
  margin-right: 10px;
}

.sotcox-pricing-box ul li:not(:last-child) {
  margin-bottom: 10px;
}

.sotcox-pricing-box ul li.hide {
  color: #727885;
}

.sotcox-pricing-box.active {
  background: #266FF2;
}

.sotcox-pricing-box.active .title {
  color: #ffffff;
}

.sotcox-pricing-box.active .shape::before {
  background: #d9e0eb;
}

.sotcox-pricing-box.active ul li {
  color: #ffffff;
}

.sotcox-pricing-box.active ul li i {
  color: #ffffff;
}

.sotcox-pricing-box.active .sotcox-btn {
  border: 1px solid #ffffff;
}

@media (max-width: 1199px) {
  .sotcox-pricing-box .pl-100 {
    padding-left: 10px;
  }
}

@media (max-width: 991px) {
  .sotcox-pricing-box .pl-100 {
    padding-left: 0px;
  }
}

@media (max-width: 991px) {
  .sotcox-pricing-box .text-end {
    text-align: left !important;
    margin-top: 20px;
  }
}

@media (max-width: 475px) {
  .sotcox-pricing-box .text-end {
    margin-top: 30px;
  }
}

.sotcox-pricing-wrapper .sotcox-pricing-box:not(:last-child) {
  margin-bottom: 20px;
}

/* PRICING CSS END */
/* SUPPORT CSS START */
.stocox-support-box {
  text-align: center;
  padding: 45px 35px;
  border: 1px solid #E6EEFB;
  border-radius: 10px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.stocox-support-box .icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #E6EEFB;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.stocox-support-box .title {
  font-size: 22px;
  margin-bottom: 15px;
}

.stocox-support-box a {
  color: #727885;
  display: block;
}

.stocox-support-box:hover .icon {
  transform: rotateY(180deg);
}

/* SUPPORT CSS END */
/* BLOG CSS START */
.sotcox-post-box {
  position: relative;
}

.sotcox-post-box .blog-cat {
  background: #266FF2;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  line-height: 100%;
  padding: 6px 12px;
  border-radius: 5px;
  position: absolute;
  top: 30px;
  left: -20px;
  z-index: 2;
}

.sotcox-post-box .thumb {
  position: relative;
  border-radius: 10px;
}

.sotcox-post-box .thumb img {
  border-radius: 10px;
}

.sotcox-post-box .meta-info {
  margin-left: 0px;
  margin-bottom: 0px;
  margin-top: -15px;
  flex-wrap: wrap;
}

.sotcox-post-box .meta-info li {
  margin-top: 15px;
  position: relative;
  line-height: 100%;
  font-size: 15px;
  color: #727885;
  padding-top: 0px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1B2E59;
}

.sotcox-post-box .meta-info li:not(:last-child) {
  margin-right: 40px;
}

@media (max-width: 475px) {
  .sotcox-post-box .meta-info li:not(:last-child) {
    margin-right: 20px;
  }
}

.sotcox-post-box .meta-info a {
  color: #1B2E59;
  font-size: 15px;
}

@media (max-width: 767px) {
  .sotcox-post-box .meta-info a {
    font-size: 14px;
  }
}

.sotcox-post-box .meta-info a i {
  margin-right: 5px;
  color: #266FF2;
}

.sotcox-post-box .meta-info a:hover {
  color: #266FF2;
}

.sotcox-post-box .title {
  margin-top: 20px;
  font-size: 35px;
  font-weight: 700;
  text-transform: lowercase;
}

@media (max-width: 767px) {
  .sotcox-post-box .title {
    font-size: 28px;
  }
}

.sotcox-post-box .title::first-letter {
  text-transform: uppercase;
}

.sotcox-post-box .title a {
  /* font-family: "Archivo", sans-serif; */
  padding-bottom: 0.2%;
}

.sotcox-post-box p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 26px;
}

.sotcox-post-box .sotcox-inline-btn {
  margin: 0;
  margin-top: 27px;
  font-weight: 700;
  color: #266FF2;
  text-transform: capitalize;
  font-size: 14px;
}

.sotcox-post-box .sotcox-inline-btn .icon {
  font-size: 14px;
  color: #266FF2;
  margin-left: 15px;
}

.format-video .sotcox-video-btn {
  font-size: 24px;
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 2;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  background: #266FF2;
}

@media (max-width: 475px) {
  .format-video .sotcox-video-btn {
    width: 60px;
    height: 60px;
    font-size: 14px;
  }
}

.format-video .sotcox-video-btn:hover {
  background: #09C3FF;
}

.format-gallery .owl-nav div {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: transparent;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

@media (max-width: 475px) {
  .format-gallery .owl-nav div {
    left: 15px;
    width: 40px;
    height: 40px;
  }
}

.format-gallery .owl-nav div:hover {
  border-color: #266FF2;
  background: #266FF2;
}

.format-gallery .owl-nav .owl-next {
  left: auto;
  right: 30px;
}

@media (max-width: 475px) {
  .format-gallery .owl-nav .owl-next {
    right: 15px;
  }
}

.format-audio .postbox__audio iframe {
  width: 100%;
  min-height: 350px;
}

@media (max-width: 475px) {
  .format-audio .postbox__audio iframe {
    min-height: 250px;
  }
}

.post-details-content .post-excerpt,
.format-quote .post-excerpt {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  background: #266FF2;
  padding: 50px 40px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding-bottom: 40px;
}

.post-details-content .post-excerpt p,
.format-quote .post-excerpt p {
  color: #dad3b8;
  font-size: 24px;
  line-height: 36px;
  position: relative;
  z-index: 2;
}

.post-details-content .post-excerpt blockquote,
.format-quote .post-excerpt blockquote {
  position: relative;
  z-index: 2;
  margin-bottom: 0px;
}

.post-details-content .post-excerpt blockquote br,
.format-quote .post-excerpt blockquote br {
  display: none;
}

.post-details-content .post-excerpt blockquote cite,
.format-quote .post-excerpt blockquote cite {
  display: block;
  margin-top: 10px;
}

.post-details-content .post-excerpt blockquote cite a,
.format-quote .post-excerpt blockquote cite a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.post-details-content .post-excerpt .quote-small,
.format-quote .post-excerpt .quote-small {
  width: 50px;
  height: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  padding: 10px;
  z-index: 1;
}

.post-details-content .post-excerpt .quote-big,
.format-quote .post-excerpt .quote-big {
  z-index: 1;
  width: 40%;
  top: 15px;
  max-width: 230px;
}

.format-quote .post-excerpt p {
  display: block;
  overflow: visible;
  font-size: 16px;
  line-height: 26px;
}

@media (max-width: 1199px) {
  .blog__wrapper {
    padding-right: 0px;
  }
}

/* BLOG CSS END */
/* blog sidebar css */
@media (max-width: 1199px) {
  .blog_sidebar.mt-none-60 {
    margin-top: 0;
  }
}

.blog_widget .wp-block-search__label,
.blog_widget .wp-block-group h2,
.blog-widget-title {
  position: relative;
  margin-bottom: 35px;
  font-size: 24px;
  text-transform: capitalize;
  position: relative;
  padding-left: 28px;
}

.blog_widget .wp-block-search__label::before,
.blog_widget .wp-block-search__label::after,
.blog_widget .wp-block-group h2::before,
.blog_widget .wp-block-group h2::after,
.blog-widget-title::before,
.blog-widget-title::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  width: 17px;
  height: 17px;
  background: #E6EEFB;
  border-radius: 50%;
  left: 0;
  top: 50%;
  translate: 0px -50%;
}

.blog_widget .wp-block-search__label::after,
.blog_widget .wp-block-group h2::after,
.blog-widget-title::after {
  width: 11px;
  height: 11px;
  background: #266FF2;
  left: 3px;
}

.blog_widget .wp-block-search__label a,
.blog_widget .wp-block-group h2 a,
.blog-widget-title a {
  color: #1B2E59;
}

.blog_widget {
  border: 1px solid #E6EEFB;
  padding: 40px 35px;
  padding-top: 35px;
  border-radius: 10px;
}

@media (max-width: 475px) {
  .blog_widget {
    padding: 35px 25px;
    padding-top: 30px;
  }
}

.blog_widget .abount-info {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
}

.blog_widget .abount-info img {
  max-width: 140px;
  min-height: 140px;
  object-fit: cover;
}

.blog_widget .abount-info .name {
  font-size: 18px;
  margin-bottom: 10px;
}

.blog_widget .abount-info p {
  line-height: 26px;
}

.blog_widget .sotcox-social-links {
  margin-top: 10px;
}

.blog_widget .sotcox-social-links a {
  font-size: 14px;
}

.blog_widget .sotcox-social-links a:not(:last-child) {
  margin-right: 20px;
}

.blog_widget.widget_categories ul li {
  overflow: hidden;
  border: 1px solid #E6EEFB;
  border-radius: 5px;
  padding: 9px 15px;
  font-size: 14px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.blog_widget.widget_categories ul li:not(:last-child) {
  margin-bottom: 15px;
}

.blog_widget.widget_categories ul li a {
  font-size: 18px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  font-weight: 700;
  color: #1B2E59;
}

.blog_widget.widget_categories ul li span {
  font-size: 18px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  float: right;
  font-weight: 700;
  color: #1B2E59;
}

.blog_widget.widget_categories ul li:hover a {
  color: #1B2E59;
}

.blog_widget.widget_categories ul li:hover span {
  color: #1B2E59;
}

.blog_widget.widget_categories ul li:hover {
  color: #266FF2;
}

.blog_widget.widget_categories ul li:hover a,
.blog_widget.widget_categories ul li:hover span {
  color: #266FF2;
}

.blog_widget .sotcox-input-field {
  margin-top: -20px;
}

.blog_widget .sotcox-input-field input,
.blog_widget .sotcox-input-field textarea {
  padding: 10px 0px;
  padding-bottom: 15px;
  border: none;
  border-bottom: 1px solid #E6EEFB;
  margin-top: 20px;
  box-shadow: none;
  border-radius: 0px;
}

.blog_widget .sotcox-input-field input:focus,
.blog_widget .sotcox-input-field textarea:focus {
  border-color: #1B2E59;
}

.blog_widget .sotcox-input-field textarea {
  min-height: auto;
}

.blog_widget .contact-info li {
  font-size: 15px;
}

.blog_widget .contact-info li:not(:last-child) {
  margin-bottom: 15px;
}

.blog_widget .contact-info li span {
  font-weight: 600;
  color: #1B2E59;
}

.blog_widget .sotcox-social-links a {
  width: 40px;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #09C3FF;
  color: #ffffff;
}

.blog_widget .sotcox-social-links a:not(:last-child) {
  margin-right: 20px;
}

.blog_widget .sotcox-social-links a:hover {
  background: #266FF2;
}

.sotcox-popular-feeds .item {
  flex-wrap: wrap;
  align-items: center;
}

.sotcox-popular-feeds .item:not(:last-child) {
  margin-bottom: 20px;
}

.sotcox-popular-feeds .item .thumb {
  margin-right: 20px;
  width: 80px;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 475px) {
  .sotcox-popular-feeds .item .thumb {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.sotcox-popular-feeds .item .thumb img {
  border-radius: 10px;
  margin-bottom: 0px;
}

.sotcox-popular-feeds .item .content {
  width: calc(100% - 160px);
}

@media (max-width: 475px) {
  .sotcox-popular-feeds .item .content {
    width: 100%;
  }
}

.sotcox-popular-feeds .item .content .title {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 30px;
}

.sotcox-popular-feeds .item .content .title a {
  background-size: 0% 1px;
}

.sotcox-popular-feeds .item .content .title a:hover {
  background-size: 100% 1px;
}

.sotcox-popular-feeds .item .content .date {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.14em;
  color: #1B2E59;
}

/* shop sidebar css  */
.blog__sidebar--shop {
  padding-right: 25px;
}

.blog__sidebar--shop .price_slider_amount .button {
  font-size: 12px !important;
  padding: 12px 20px !important;
  padding-bottom: 10px !important;
}

.blog__sidebar--shop .ui-slider-horizontal {
  height: 3px !important;
  margin-bottom: 25px !important;
}

.blog__sidebar--shop .ui-widget-content {
  background-color: #E6EEFB !important;
  border-radius: 0px;
}

.blog__sidebar--shop .ui-slider-range {
  background-color: #1B2E59 !important;
}

.blog__sidebar--shop .ui-slider-handle {
  width: 12px !important;
  height: 12px !important;
  border: 3px solid #1B2E59;
  background-color: #ffffff !important;
}

.search-widget div {
  position: relative;
}

.search-widget input {
  width: 100%;
  padding: 12px 20px;
  padding-right: 70px;
  font-size: 16px;
  border: 1px solid #E6EEFB;
  border-radius: 5px;
}

.search-widget input::-webkit-input-placeholder {
  color: #727885;
  opacity: 1;
}

.search-widget input::-moz-placeholder {
  color: #727885;
  opacity: 1;
}

.search-widget input:-ms-input-placeholder {
  color: #727885;
  opacity: 1;
}

.search-widget input:-moz-placeholder {
  color: #727885;
  opacity: 1;
}

.search-widget input:focus {
  border-color: #1B2E59;
}

.search-widget button {
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #727885;
  position: absolute;
  right: 0;
  top: 50%;
  translate: -15px -50%;
}

.sotcox-insta-posts {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -5px;
  margin-top: -5px;
}

.sotcox-insta-posts .item {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  width: calc(33.3333%);
  position: relative;
  padding: 5px;
}

.sotcox-insta-posts .item img {
  border-radius: 10px;
  width: 100%;
}

.sotcox-insta-posts .item:hover .sotcox-zoom-btn {
  opacity: 1;
  visibility: visible;
  translate: -50% -50%;
}

.sotcox-devider {
  border-bottom: 1px solid #E6EEFB;
}

.service-sidebar .blog_widget {
  border: none;
  background: #E6EEFB;
}

.service-sidebar .blog_widget.widget_categories ul li {
  position: relative;
  padding: 0px;
  border: none;
}

.service-sidebar .blog_widget.widget_categories ul li:not(:last-child) {
  margin-bottom: 20px;
}

.service-sidebar .blog_widget.widget_categories ul li .count {
  float: left;
  margin-right: 20px;
}

.service-sidebar .blog_widget.widget_categories ul li::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  font-family: "Font Awesome 5 Pro";
  width: auto;
  height: auto;
  left: auto;
  right: 0px;
  top: 50%;
  translate: 0% -50%;
  font-weight: 700;
  color: #1B2E59;
}

.service-sidebar .wp-block-search__label::before,
.service-sidebar .wp-block-search__label::after,
.service-sidebar .wp-block-group h2::before,
.service-sidebar .wp-block-group h2::after,
.service-sidebar .blog-widget-title::before,
.service-sidebar .blog-widget-title::after {
  background: #679af6;
}

.service-sidebar .wp-block-search__label::after,
.service-sidebar .wp-block-group h2::after,
.service-sidebar .blog-widget-title::after {
  background: #266FF2;
}

.service-sidebar .sotcox-input-field textarea,
.service-sidebar .sotcox-input-field input {
  background: transparent;
  border-color: #d4e5ff;
}

.service-sidebar .sotcox-input-field textarea::placeholder,
.service-sidebar .sotcox-input-field input::placeholder {
  color: #c3cad5;
}

.service-sidebar .sotcox-input-field textarea:focus,
.service-sidebar .sotcox-input-field input:focus {
  border-color: #A1C0EE;
}

.service-sidebar .sotcox-input-field textarea:focus::placeholder,
.service-sidebar .sotcox-input-field input:focus::placeholder {
  color: #73747D;
}

.service-sidebar .widget-btns a {
  display: block;
  border-radius: 5px;
  margin-bottom: 0px;
}

@media (max-width: 475px) {
  .service-sidebar .widget-btns a {
    padding: 15px 25px;
  }
}

.service-sidebar .widget-btns a i {
  position: absolute;
  top: 50%;
  right: 40px;
  translate: 0% -50%;
}

@media (max-width: 475px) {
  .service-sidebar .widget-btns a i {
    display: none;
  }
}

.service-sidebar .widget-btns a:nth-child(2) {
  background: #09C3FF;
}

.service-sidebar .widget-btns a:nth-child(2) span {
  background: #266FF2;
}

.service-sidebar .widget-btns a:not(:last-child) {
  margin-bottom: 20px;
}

/* blog details css */
.tag-social-wrapper {
  clear: both;
}

.tag-social-wrapper .sotcox-social-links {
  justify-content: end;
}

@media (max-width: 767px) {
  .tag-social-wrapper .sotcox-social-links {
    justify-content: flex-start !important;
  }
}

.tag-social-wrapper .sotcox-social-links a {
  width: 40px;
  height: 40px;
  border: 1px solid #E6EEFB;
  border-radius: 50px;
  font-size: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tag-social-wrapper .sotcox-social-links a:not(:last-child) {
  margin-right: 20px;
}

.tag-social-wrapper .sotcox-social-links a:hover,
.tag-social-wrapper .sotcox-social-links a.active {
  background: #266FF2;
  color: #ffffff;
  border-color: #266FF2;
}

.post-author__wrapper {
  background: #E6EEFB;
  border-radius: 10px;
  padding: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: center;
}

@media (max-width: 475px) {
  .post-author__wrapper {
    flex-wrap: wrap;
    padding: 25px;
  }
}

.post-author__thumb {
  width: 150px;
  height: 150px;
  border-radius: 50px;
  margin-right: 30px;
}

.post-author__thumb img {
  border-radius: 50%;
}

@media (max-width: 475px) {
  .post-author__thumb {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

.post-author__content {
  width: calc(100% - 180px);
}

@media (max-width: 475px) {
  .post-author__content {
    width: 100%;
  }
}

.post-author .name {
  margin-bottom: 10px;
  font-size: 24px;
}

.post-author .sotcox-social-links {
  margin-top: 5px;
}

.post-author .sotcox-social-links a {
  font-size: 18px;
}

.post-author .sotcox-social-links a .fa-facebook-f {
  color: #266ff2;
}

.post-author .sotcox-social-links a .fa-twitter {
  color: #1da1f2;
}

.post-author .sotcox-social-links a .fa-linkedin-in {
  color: #0077b5;
}

.sotcox-title {
  font-size: 32px;
}

.tagcloud,
.wp-block-tag-cloud {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -10px;
}

.tagcloud a,
.wp-block-tag-cloud a {
  font-size: 12px !important;
  border: 1px solid #E6EEFB;
  font-weight: 600;
  color: #727885;
  display: block;
  margin-top: 10px;
  padding: 10px 25px;
  padding-bottom: 11px;
  text-transform: uppercase;
  line-height: 100%;
  padding-bottom: 8px;
  background: transparent;
  border-radius: 5px;
}

.tagcloud a:not(:last-child),
.wp-block-tag-cloud a:not(:last-child) {
  margin-right: 10px;
}

.tagcloud a:hover,
.wp-block-tag-cloud a:hover {
  background: #266FF2;
  border-color: #266FF2;
  color: #ffffff;
}

.footer_widget .tagcloud a {
  background: #1B2E59;
  color: #ffffff;
  border-color: #1B2E59;
}

.footer_widget .tagcloud a:hover {
  background: #266FF2;
  border-color: #266FF2;
  color: #ffffff;
}

.sotcox-details-navigation {
  align-items: center;
}

@media (max-width: 767px) {
  .sotcox-details-navigation {
    flex-direction: row !important;
    text-align: left !important;
  }

  .sotcox-details-navigation .ml-20 {
    margin-right: 20px;
    margin-left: 0px;
  }
}

@media (max-width: 475px) {
  .sotcox-details-navigation {
    flex-wrap: wrap;
  }
}

.sotcox-details-navigation .rp-thumb img {
  max-width: 80px;
  min-height: 60px;
  object-fit: cover;
}

@media (max-width: 475px) {
  .sotcox-details-navigation .rp-thumb {
    margin-bottom: 15px;
  }
}

.sotcox-details-navigation .rp-content {
  width: 100%;
}

.sotcox-details-navigation .rp-content .rp-nav {
  font-size: 14px;
  color: #999;
}

.sotcox-details-navigation .rp-content .rp-title {
  margin-top: 2px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.sotcox-details-navigation .rp-content .rp-title a {
  color: #1B2E59;
}

.sotcox-dn-wrapper .col-xl-6 {
  width: 47.5%;
}

@media (max-width: 767px) {
  .sotcox-dn-wrapper .col-xl-6 {
    width: 100%;
  }

  .sotcox-dn-wrapper .col-xl-6:not(:last-child) {
    margin-bottom: 20px;
  }
}

.sotcox-dn-wrapper .col-xl-1 {
  width: 5%;
}

@media (max-width: 767px) {
  .sotcox-dn-wrapper .col-xl-1 {
    display: none;
  }
}

.navigation-border {
  overflow: hidden;
  border-top: 1px solid #E6EEFB;
}

.blog__wrapper--single .post-details-content {
  overflow: hidden;
}

.blog__wrapper--single .post-details-content p {
  overflow: visible;
  display: block;
  font-size: 16px;
  line-height: 30px;
}

.blog__wrapper--single .post-details-content p:not(:last-child) {
  margin-bottom: 25px;
}

.blog__wrapper--single .post-details-content blockquote {
  padding: 0px 70px;
  padding-top: 155px;
  padding-bottom: 50px;
  position: relative;
}

.blog__wrapper--single .post-details-content blockquote::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  /* background-image: url(../img/icons/quto-icon-small.png); */
  width: 70px;
  height: 70px;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  border-radius: 50%;
  top: 60px;
}

.blog__wrapper--single .post-details-content blockquote::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  /* background-image: url(../img/icons/quto-icon-big.png); */
  top: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
  max-width: 256px;
  max-height: 196px;
}

.blog__wrapper--single .post-details-content blockquote p {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;
}

.blog__wrapper--single .post-details-content blockquote p a {
  color: #dad3b8;
}

.blog__wrapper--single .post-details-content blockquote br {
  display: none;
}

.blog__wrapper--single .post-details-content blockquote cite {
  margin-top: 10px;
  display: block;
  color: #266FF2;
  position: relative;
  z-index: 2;
  font-size: 14px;
  font-weight: 700;
}

.blog__wrapper--single .post-details-content blockquote cite a {
  color: #266FF2;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.blog__wrapper--single iframe {
  height: auto;
  min-height: 400px;
}

.blog__wrapper--single .post-video-btn {
  width: 100px;
  height: 100px;
  font-size: 18px;
}

.blog__wrapper--single .owl-nav div {
  width: 60px;
  height: 60px;
}

.blog__wrapper--single .blog-list-item {
  padding-left: 0px;
  margin-bottom: 0px;
}

.blog__wrapper--prev img {
  width: 100%;
}

.blog__wrapper--prev .mt-30 img {
  border-radius: 10px;
}

.blog__wrapper--prev .post-details-content blockquote {
  margin-bottom: 40px;
  margin-top: 40px;
}

.blog__wrapper--prev .post-details-content blockquote p {
  color: #1B2E59;
  overflow: visible;
  text-overflow: unset;
  display: block;
}

@media (max-width: 767px) {
  .blog__wrapper--prev .post-details-content blockquote p {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog__wrapper--prev .post-details-content blockquote p {
    font-size: 24px;
    line-height: 36px;
  }
}

@media (max-width: 767px) {
  .blog__wrapper--prev .post-details-content blockquote {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 145px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog__wrapper--prev .post-details-content blockquote {
    padding: 0px 70px;
    padding-top: 155px;
    padding-bottom: 50px;
  }
}

.blog-list-item {
  padding: 0;
  list-style: none;
  margin-top: -15px;
  padding-left: 15px;
}

.blog-list-item li {
  position: relative;
  margin-top: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: nowrap;
}

.blog-list-item li img {
  width: auto;
  margin-right: 10px;
}

.post-details-content blockquote {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #E6EEFB;
  flex-direction: column;
}

.post-details-content blockquote p {
  color: #727885;
  position: relative;
  z-index: 2;
  margin-bottom: 0px !important;
}

.post-details-content .wp-block-cover p {
  font-size: 2em;
  line-height: 1.25;
}

@media (max-width: 767px) {
  .social-share-wrapper {
    margin-top: 30px;
  }
}

blockquote code {
  color: #ffffff;
}

.post-details-content>h5 {
  margin-bottom: 20px;
}

.comment-desc {
  overflow: hidden;
}

.sotcox-next-prev-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sotcox-next-prev-wrapper div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.sotcox-next-prev-wrapper a {
  font-weight: 700;
  color: #266FF2;
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.sotcox-next-prev-wrapper a:hover .icon {
  background: #266FF2;
  border-color: #266FF2;
  color: #ffffff;
}

.sotcox-next-prev .icon {
  translate: 0px -1px;
  width: 60px;
  height: 60px;
  border: 1px solid #E6EEFB;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

@media (max-width: 475px) {
  .sotcox-next-prev .icon {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

.sotcox-next-prev .text {
  color: #266FF2;
}

.sotcox-next-prev__prev .icon {
  margin-right: 20px;
}

@media (max-width: 475px) {
  .sotcox-next-prev__prev .icon {
    margin-right: 15px;
  }
}

.sotcox-next-prev__next .icon {
  margin-left: 20px;
}

@media (max-width: 475px) {
  .sotcox-next-prev__next .icon {
    margin-left: 15px;
  }
}

/* comment css */
.comment-respond .sotcox-title-uppercase,
.post-comments .sotcox-title-uppercase {
  margin-bottom: 15px;
}

.sotcox-comment-box {
  padding-top: 40px;
}

.sotcox-comment-box .comment-avatar {
  float: left;
}

.sotcox-comment-box .comment-avatar img {
  margin-right: 20px;
  width: auto;
  border-radius: 50%;
}

@media (max-width: 475px) {
  .sotcox-comment-box .comment-avatar img {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

@media (max-width: 475px) {
  .sotcox-comment-box .comment-avatar {
    float: none;
  }
}

.sotcox-comment-box .avatar-name a {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 18px;
  color: #1B2E59;
  /* font-family: "Archivo", sans-serif; */
}

.sotcox-comment-box .date {
  color: #09C3FF;
}

.sotcox-comment-box .comment-desc p {
  line-height: 26px;
}

.sotcox-comment-box .comment-desc a {
  color: inherit;
}

.sotcox-comment-box .bottom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
}

.sotcox-comment-box .bottom a,
.sotcox-comment-box .bottom span {
  font-size: 12px;
  color: #1B2E59;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 100%;
}

.sotcox-comment-box .bottom a {
  color: #266FF2;
  position: relative;
  padding-left: 12px;
}

.sotcox-comment-box .bottom a::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  width: 2px;
  height: 2px;
  background: #1B2E59;
  left: 4px;
  top: 1px;
}

.sotcox-commentform-wrapper {
  background: #E6EEFB;
  padding: 40px 50px;
  padding-bottom: 50px;
  border-radius: 10px;
}

@media (max-width: 475px) {
  .sotcox-commentform-wrapper {
    padding: 35px 25px;
    padding-bottom: 40px;
  }
}

.sotcox-commentform-wrapper p {
  font-size: 14px;
}

.sotcox-commentform-wrapper .sotcox-btn {
  margin-bottom: 0px;
}

@media (max-width: 475px) {
  .sotcox-commentform-wrapper .sotcox-btn {
    padding: 15px 24px;
  }
}

.sotcox-input-field label {
  font-size: 20px;
  /* font-family: "Archivo", sans-serif; */
  color: #1B2E59;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 12px;
}

.sotcox-input-field textarea,
.sotcox-input-field input {
  /* background: #ffffff; */
  background: #141414;
  border: 1px solid #424242;
  padding: 15px 20px;
}

.sotcox-input-field textarea:focus,
.sotcox-input-field input:focus {
  border-color: #1B2E59;
}

.sotcox-input-field textarea {
  min-height: 150px;
}

.logged-in-as {
  margin-bottom: 20px;
}

.logged-in-as a {
  color: #727885;
}

.logged-in-as a:hover {
  color: #266FF2;
}

.logged-in .sotcox-btn__full {
  width: auto;
}

.logged-in .sotcox-btn__full i {
  margin-left: 10px;
}

.sotcox-has-comment {
  margin-top: 10px;
}

.latest__comments .comment-respond {
  margin-top: 10px;
}

.latest__comments ul ul {
  padding-left: 110px;
  list-style: none;
}

@media (max-width: 475px) {
  .latest__comments ul ul {
    padding-left: 15px;
  }
}

.post-comments .sotcox-title-uppercase small a {
  color: inherit;
  font-size: 16px;
}

/* PAGINATION CSS START */
.sotcox-pagination ul {
  list-style: none;
  padding: 0px !important;
  margin-bottom: 0px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0px !important;
}

.sotcox-pagination ul li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #E6EEFB;
  border-radius: 5px;
  margin-top: 10px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.sotcox-pagination ul li:hover {
  border-color: transparent;
}

.sotcox-pagination ul li:not(:last-child) {
  margin-right: 10px;
}

.sotcox-pagination ul li span,
.sotcox-pagination ul li a {
  width: 50px;
  height: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #727885;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 475px) {

  .sotcox-pagination ul li span,
  .sotcox-pagination ul li a {
    height: 38px;
    width: 38px;
    font-size: 14px;
  }
}

.sotcox-pagination ul li span:hover,
.sotcox-pagination ul li a:hover {
  color: #ffffff;
  background: #266FF2;
}

.sotcox-pagination ul li .current {
  color: #ffffff;
  background: #266FF2;
}

/* PAGINATION CSS END */
/* ABOUT CSS START */
.sotcox-about-section .abt-desc {
  padding-right: 50px;
}

@media (max-width: 1199px) {
  .sotcox-about-section .abt-desc {
    padding-right: 0px;
    padding-top: 35px;
  }
}

.about-img-wrapper {
  animation: shapeDownLeft 2s linear infinite alternate;
}

@media (max-width: 1199px) {
  .about-content-wrapper {
    padding-left: 0px;
    padding-top: 80px;
  }
}

.featured-lists li {
  font-weight: 500;
  color: #1B2E59;
  /* font-family: "Archivo", sans-serif; */
  letter-spacing: -0.01em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.featured-lists li .feature-icon {
  color: #266FF2;
  margin-right: 10px;
  translate: 0px 7px;
  font-size: 8px;
}

.featured-lists li:not(:last-child) {
  margin-bottom: 10px;
}

.sotcox-video-img {
  position: relative;
}

.sotcox-video-img img {
  border-radius: 10px;
  min-height: 250px;
  object-fit: cover;
}

.sotcox-video-img .sotcox-video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  z-index: 2;
  font-size: 20px;
  height: 80px;
  width: 80px;
}

/* ABOUT CSS END */
@media (max-width: 1199px) {
  .sotcox-details-page .details-wrapper {
    padding-right: 0px;
  }
}

.sotcox-details-page .details-thumb img {
  border-radius: 10px;
}

.sotcox-details-page p {
  font-size: 18px;
  line-height: 30px;
}

.sotcox-details-page p:not(:last-child) {
  margin-bottom: 30px;
}

.sotcox-details-page .portfolio-meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -15px;
}

.sotcox-details-page .portfolio-meta li {
  font-size: 18px;
  width: 25%;
  margin-top: 15px;
}

@media (max-width: 991px) {
  .sotcox-details-page .portfolio-meta li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .sotcox-details-page .portfolio-meta li {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sotcox-details-page .portfolio-meta li {
    width: 50%;
  }
}

.sotcox-details-page .portfolio-meta li .label {
  font-weight: 700;
  color: #1B2E59;
  margin-right: 15px;
}

.portfolio-details-carousel img {
  border-radius: 10px;
}

.portfolio-details-carousel .owl-dots {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.portfolio-details-carousel .owl-dots div {
  width: 7px;
  height: 7px;
  background: #09C3FF;
  border-radius: 50%;
  margin: 0px 10px;
  position: relative;
}

.portfolio-details-carousel .owl-dots div::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  width: 14px;
  height: 14px;
  border: 1px solid #09C3FF;
  border-radius: 50%;
  left: -3.5px;
  top: -3.5px;
}

.portfolio-details-carousel .owl-dots div.active {
  width: 13px;
  height: 13px;
}

.portfolio-details-carousel .owl-dots div.active::after {
  display: none;
}

/* TEAM DETAILS PAGE CSS START */
.sotcox-team-details-page .details-thumb img {
  width: 100%;
}

@media (max-width: 1199px) {
  .sotcox-team-details-page .team-info {
    padding-left: 30px;
  }
}

@media (max-width: 991px) {
  .sotcox-team-details-page .team-info {
    padding-left: 0px;
    margin-top: 50px;
  }
}

.sotcox-team-details-page .team-info .name {
  margin-bottom: 5px;
  font-size: 35px;
}

.sotcox-team-details-page .team-info .designation {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
}

.sotcox-team-details-page .team-info ul {
  margin-top: 35px !important;
}

.sotcox-team-details-page .team-info ul li {
  font-size: 18px;
}

@media (max-width: 475px) {
  .sotcox-team-details-page .team-info ul li {
    font-size: 16px;
  }
}

.sotcox-team-details-page .team-info ul li:not(:last-child) {
  margin-bottom: 15px;
}

.sotcox-team-details-page .team-info ul li span {
  margin-right: 15px;
  display: inline-block;
  color: #1B2E59;
  font-weight: 700;
}

.sotcox-team-details-page .details-content p {
  font-size: 16px;
}

.sotcox-team-details-page .sotcox-social-links a {
  width: 40px;
  height: 40px;
  border: 1px solid #E6EEFB;
  border-radius: 50px;
  font-size: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.sotcox-team-details-page .sotcox-social-links a:not(:last-child) {
  margin-right: 20px;
}

.sotcox-team-details-page .sotcox-social-links a:hover,
.sotcox-team-details-page .sotcox-social-links a.active {
  background: #266FF2;
  color: #ffffff;
  border-color: #266FF2;
}

.sotcox-skill-item .knob {
  font-size: 42px !important;
  /* font-family: "Archivo", sans-serif !important; */
  color: #1B2E59 !important;
  margin-top: 65px !important;
}

.sotcox-skill-item .skill-title {
  font-size: 20px;
  margin-top: 25px;
  color: #727885;
}

/* TEAM DETAILS PAGE CSS END */
/* SERVICE DETAILS PAGE CSS SATART */
.sotcox-service-details-page P {
  font-size: 16px;
}

.sotcox-service-details-page .stocox-support-box .icon {
  border: none;
  width: auto;
  height: auto;
}

/* SERVICE DETAILS PAGE CSS END */
/* CONTACT PAGE CSS START */
.mb-none-120 {
  margin-bottom: -123px;
}

.sotcox-map-section iframe {
  min-height: 650px;
  width: 100%;
  filter: grayscale(100%) invert(100%);
  border: none;
}

@media (max-width: 767px) {
  .sotcox-map-section iframe {
    min-height: 450px;
  }
}

.ajax-response {
  margin-top: 10px;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
}

.ajax-response.error {
  background: red;
}

.ajax-response.success {
  background: green;
}

/* CONTACT PAGE CSS END */
/* FOOTER CSS START */
.sotcox-copyright {
  padding: 20px 0px;
}

@media (max-width: 767px) {
  .sotcox-copyright {
    text-align: center;
  }
}

.sotcox-copyright__style-2 {
  padding: 42px 0px;
}

.sotcox-copyright__style-2 span,
.sotcox-copyright__style-2 p {
  color: #ffffff;
}

.sotcox-copyright__style-2 .sotcox-backtotop button {
  color: #ffffff;
}

.sotcox-copyright__style-2 .sotcox-backtotop button:hover {
  color: #09C3FF;
}

.sotcox-copyright__style-2 .sotcox-backtotop button:hover i {
  color: #09C3FF;
}

.sotcox-backtotop {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .sotcox-backtotop {
    justify-content: center;
    margin-top: 15px;
  }
}

.sotcox-backtotop button {
  text-transform: capitalize;
  color: #727885;
}

.sotcox-backtotop button i {
  margin-right: 5px;
}

.sotcox-backtotop button:hover {
  color: #266FF2;
}

.sotcox-backtotop button:hover i {
  color: #266FF2;
}

.sotcox-footer-logo {
  display: inline-block;
}

.sotcox-footer-logo img {
  max-width: 185px;
}

.sotcox-footer-widgets .row {
  position: relative;
}

@media (max-width: 991px) {
  .sotcox-footer-widgets .row {
    padding-bottom: 40px;
  }
}

.sotcox-footer-widgets .row::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  height: 1px;
  background: #d6e1f3;
  top: auto;
  left: 50%;
  bottom: 0;
  width: calc(100% - 26px);
  translate: -50% 0px;
}

.sotcox-footer-widgets .row .sotcox-custom-col {
  padding-bottom: 40px;
}

@media (max-width: 991px) {
  .sotcox-footer-widgets .row .sotcox-custom-col {
    padding-bottom: 0px;
  }
}

.sotcox-footer-widgets .row .sotcox-custom-col:not(:first-child) {
  padding-left: 120px;
}

@media (max-width: 1199px) {
  .sotcox-footer-widgets .row .sotcox-custom-col:not(:first-child) {
    padding-left: 60px;
  }
}

@media (max-width: 991px) {
  .sotcox-footer-widgets .row .sotcox-custom-col:not(:first-child) {
    padding-left: 80px;
  }
}

@media (max-width: 767px) {
  .sotcox-footer-widgets .row .sotcox-custom-col:not(:first-child) {
    padding-left: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sotcox-footer-widgets .row .sotcox-custom-col:not(:first-child) {
    padding-left: 60px;
  }
}

.sotcox-footer-widgets .row .sotcox-custom-col:not(:last-child) {
  border-right: 1px solid #d6e1f3;
}

@media (max-width: 991px) {
  .sotcox-footer-widgets .row .sotcox-custom-col:last-child {
    padding-left: 12px;
    border: none;
  }
}

.sotcox-footer-widgets .widget-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.sotcox-footer-widgets ul {
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
}

.sotcox-footer-widgets ul li:not(:last-child) {
  margin-bottom: 5px;
}

.sotcox-footer-widgets ul li a {
  position: relative;
  padding-left: 20px;
  color: #727885;
}

.sotcox-footer-widgets ul li a::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  /* background: #09C3FF; */
  background: #ffffff;
  top: 50%;
  translate: 0 -50%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sotcox-footer-widgets ul li a:hover::after {
  background: #266FF2;
}

.sotcox-footer-widgets .about-widget p i {
  color: #266FF2;
  margin-right: 5px;
}

.sotcox-footer-widgets .about-widget p:not(:last-child) {
  margin-bottom: 15px;
}

.sotcox-footer-widgets__style-2 .row {
  padding-bottom: 80px;
}

.sotcox-footer-widgets__style-2 .row::after {
  background: #252527;
}

.sotcox-footer-widgets__style-2 .row .sotcox-custom-col {
  border: none !important;
  padding-bottom: 0px !important;
}

@media (max-width: 1199px) {
  .sotcox-footer-widgets__style-2 .row .sotcox-custom-col {
    padding-left: 12px !important;
  }
}

.sotcox-footer-widgets__style-2 .sotcox-social-links a {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #2e2e30;
  color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.sotcox-footer-widgets__style-2 .sotcox-social-links a:not(:last-child) {
  margin-right: 15px;
}

.sotcox-footer-widgets__style-2 .sotcox-social-links a:hover {
  border-color: #09C3FF;
  background: #09C3FF;
  color: #ffffff;
}

.sotcox-footer-widgets__style-2 .contact-info-widget a,
.sotcox-footer-widgets__style-2 .contact-info-widget p {
  color: #ffffff;
}

.sotcox-footer-widgets__style-2 .contact-info-widget .tel {
  font-size: 22px;
  font-weight: 600;
  /* font-family: "Archivo", sans-serif; */
  margin-bottom: 10px;
  display: inline-block;
}

.sotcox-footer-widgets__style-2 .contact-info-widget .get-in-touch {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1em;
  position: relative;
  margin-top: 25px;
  text-transform: uppercase;
  display: inline-block;
}

.sotcox-footer-widgets__style-2 .contact-info-widget .get-in-touch::after {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  height: 1px;
  background: #ffffff;
  top: auto;
  bottom: 5px;
}

.footer-menu ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: nowrap;
}

@media (max-width: 475px) {
  .footer-menu ul {
    flex-wrap: wrap;
  }
}

.footer-menu ul li:not(:last-child) {
  margin-right: 50px;
}

.footer-menu ul li a {
  padding-left: 0px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.085em;
}

.footer-menu ul li a::after {
  display: none;
}

.footer-menu ul li a:hover {
  color: #09C3FF;
}

/* FOOTER CSS END */

.ant-picker-dropdown{
  z-index: 2000 !important;
}

/*# sourceMappingURL=main.css.map */