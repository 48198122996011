.sidebar {
  display: none;
}
.content-area {
  width: 100%;
}
.custom-border-right {}
@media (min-width: 1200px) {
  .sidebar {
    display: block;
    width: 190px;
    z-index: 1000;
    /* background: #1c2932; */
    background: #1f242e;
    /* border-right: 1px solid #1c2932; */
    border-right: 1px solid #1f242e;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    padding: 0 0 95px;
    transition: all 0.1s ease-out;
  }
  .content-area {
    margin-left: 190px;
    width: calc(100% - 190px);
  }
  .custom-border-right,
  .custom-border-right-second {
    position: relative;
  }
  .custom-border-right:after {
    content: "";
    top: 20px;
    right: 0;
    height: calc(100% - 40px);
    width: 1px;
    background-color: #2b313e;
    position: absolute;
  }
  .custom-border-right-second:after {
    content: "";
    top: 20px;
    right: 0;
    height: calc(100% - 35px);
    width: 1px;
    background-color: #2b313e;
    position: absolute;
  }
}
